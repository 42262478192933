import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ErrorIcon } from 'assets/Message_open_error.svg';
import i18nContext from '../i18n-context';

export const ErrorNoAccountScheme = ({ closePopUp })=> {
  const i18n = useContext(i18nContext);
  
  return (
    <div className="scheme-representatives-wrapper pop-up-success">
      <ErrorIcon />
      <h2 className="scheme-success-header">{ i18n.getMessage('representatives.header.noAccount') }</h2>
      <button className="scheme-error-button"
        onClick={ () => closePopUp() }
      >{ i18n.getMessage('popUp.success.button') }</button>
    </div>
  );
};

ErrorNoAccountScheme.propTypes = {
  closePopUp: PropTypes.func.isRequired
};
