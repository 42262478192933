import { makeAutoObservable, runInAction } from 'mobx';
import { isPhoneConfirmationType } from 'services/utils';
import {
  loginUser,
  loginPin,
  generateOAuthSecurityCode,
  passwordRecoveryRequest,
  changePasswordUser,
  generatePasswordRecoverySecurityCode, resendAuthorizationSecurityCode
} from 'services/requestAgent';

class AuthStore {
  isLoading = false;
  isLoginSuccess = false;
  isPinSuccess = false;
  isPasswordChanged = false;
  error = null;
  confirmationActionType = null;
  phone = null;

  values = {
    username: '',
    password: '',
    code: ''
  };

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  setIsLoginSuccess = (state) => this.isLoginSuccess = state;

  setUsername(username) {
    this.values.username = username;
  }
  
  setPassword(password) {
    this.values.password = password;
  }

  setCode(code) {
    this.values.code = code;
  }

  setConfirmationActionType(confirmationType) {
    this.confirmationActionType = confirmationType;
  }
  
  reset() {
    this.isLoading = false;
    this.isLoginSuccess = false;
    this.isPinSuccess = false;
    this.isError = false;
    this.values.username = '';
    this.values.password = '';
    this.values.code = '';
  }

  async resendSecurityCode() {
    try {
      await resendAuthorizationSecurityCode();
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    }
  }

  async sendAuthorizationData() {
    this.setIsLoading(true);
    try {
      const {
        confirmationActionType,
        phone
      } = await loginUser({
        username: this.values.username,
        password: this.values.password
      });
  
      if(isPhoneConfirmationType(confirmationActionType)) {
        await generateOAuthSecurityCode();
      }
  
      runInAction(() => {
        this.isLoginSuccess = true;
        this.isLoading = false;
        this.confirmationActionType = confirmationActionType;
        this.phone = phone;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  async sendCode() {
    this.setIsLoading(true);
    try {
      await loginPin(this.values.code);
      runInAction(() => {
        this.isPinSuccess = true;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  async sendPasswordRecovery() {
    this.setIsLoading(true);
    try {
      await passwordRecoveryRequest({ email: this.values.username });
      runInAction(() => {
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  async sendPasswordChange() {
    this.setIsLoading(true);
    try {
      await changePasswordUser(this.values.password);
      runInAction(() => {
        this.isPasswordChanged = true;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  async sendPasswordCode() {
    this.setIsLoading(true);
    try {
      this.isPasswordChanged = true;
      await generatePasswordRecoverySecurityCode(this.values.code);
      runInAction(() => {
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }
}

export default new AuthStore();
