import { useContext } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';
import i18nContext from 'components/i18n-context';
import { BackButton } from 'components/common/BackButton/BackButton';
import Alert from 'uikit/Alert/Alert';
import Button from 'uikit/Button/Button';
import { ReactComponent as PhoneIcon } from 'assets/Message_open2.svg';
import { getErrorMessageForAlert, prepareFieldErrors } from 'services/utils';

import '../RegistrationStyles.scss';

const Phone = ({ registrationStore }) => {
  const i18n = useContext(i18nContext);

  const handleSubmit = () => {
    registrationStore.sendPhoneNumber();
  };

  const serverFieldsErrors = prepareFieldErrors(i18n, registrationStore?.error);

  return (
    <div className="registration-form" id="form1">
      <PhoneIcon className="registration-icon" />
      <h1 className="registration-title">{ i18n.getMessage('register.title.phone') }</h1>
      <Alert
        type={ registrationStore?.error && 'warning' }
        className={ 'register-alert' }
        message={
          registrationStore?.error && getErrorMessageForAlert(i18n, registrationStore.error) || serverFieldsErrors.phone
        }
      />
      <div><p className="registration-description">{ i18n.getMessage('register.text.phone.description') }</p></div>
      <PhoneInput
        country={ 'gb' }
        specialLabel={ i18n.getMessage('register.label.phone') }
        inputProps={ {
          name: 'inputPhoneVerification',
          required: true,
          autoFocus: true
        } }
        value={ registrationStore.generalFields.phoneNumber }
        onChange={ (phone, data, event, formattedValue) => registrationStore.setPhoneNumber(formattedValue) }
      />
      <Button
        type={ 'primary' }
        size={ 'large' }
        fullWidth={ true }
        onClick={ handleSubmit }
      >
        { i18n.getMessage('login.btn.submit') }
      </Button>
      <BackButton type={ 'login' } />
    </div>
  );
};

Phone.propTypes = {
  registrationStore: MobXPropTypes.observableObject
};

export default inject('registrationStore')(observer(Phone));
