import { useContext } from 'react';
import PropTypes from 'prop-types';
import AmountInput from '../../AmountInput';
import Input from 'uikit/Input/Input';
import i18nContext from '../../../i18n-context';
import { AttachDoc } from 'uikit/AttachDoc/AttachDoc';
import { getErrorMessageForAlert } from '../../../../services/utils';
import Loader from '../../Loader';
import Button from 'uikit/Button/Button';

const BottomPartForm = ({
  values,
  errors,
  error,
  handleChange,
  handleSubmit,
  serverFieldsErrors,
  onAmountIBANChange,
  handleGetCommission,
  showMethodCommission,
  getCommissionValue,
  paymentMethod,
  isSubmitDisabled,
  isLoading,
  uploadedFiles,
  uploadDocuments,
  removePaymentFile
}) => {
  const i18n = useContext(i18nContext);
  return (
    <>
      <div className="inputs-wrapper-evenly">
        <AmountInput
          isRequired={ true }
          label={ i18n.getMessage('transfer.form.amount.label') }
          name={ 'amount' }
          value={ values.amount }
          error={ errors.amount || serverFieldsErrors.amount }
          onChange={ onAmountIBANChange }
          onBlur={ handleGetCommission }
        />
        <Input
          label={ i18n.getMessage('transfer.form.commission.label') }
          isDisabled={ true }
          value={ values.amount && showMethodCommission ? getCommissionValue() : '' }
          onChange={ handleChange }
        />
      </div>
      <div className="inputs-wrapper">
        <Input
          isRequired={ true }
          className="transfer-details"
          label={ i18n.getMessage('transfer.form.transferDetails.label') }
          name={ 'comment' }
          value={ values.comment }
          error={ errors.comment || serverFieldsErrors.comment }
          onChange={ handleChange }
          onBlur={ handleGetCommission }
        />
        <Input
          label={ i18n.getMessage('transfer.form.paymentMethod.label') }
          isDisabled={ true }
          value={ showMethodCommission && paymentMethod ?
            i18n.getMessage(`transfer.paymentMethod.${paymentMethod}`) : '' }
          onChange={ handleChange }
        />
      </div>
      <div className="inputs-wrapper-evenly">
        <Button
          type={ 'primary' }
          roleType={ 'submit' }
          size={ 'large' }
          onClick={ handleSubmit }
          isDisabled={ isSubmitDisabled }
        >
          { isLoading ? <Loader/> : i18n.getMessage('sendMoney.button.send') }
        </Button>
        <AttachDoc
          label={ i18n.getMessage('transfer.form.attachDoc.label') }
          className="hidden"
          type="file"
          files={ uploadedFiles }
          onChange={ uploadDocuments }
          onRemoveFiles={ removePaymentFile }
          error={ error?.type === 'attachDoc' && getErrorMessageForAlert(i18n, error) }
          hintText={ i18n.getMessage('attachDoc.hint.text') }
        />
      </div>
    </>
  );
};

BottomPartForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  error: PropTypes.any,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  serverFieldsErrors: PropTypes.object,
  onAmountIBANChange: PropTypes.func,
  handleGetCommission: PropTypes.func,
  showMethodCommission: PropTypes.bool,
  getCommissionValue: PropTypes.func,
  paymentMethod: PropTypes.string,
  isSubmitDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  uploadedFiles:  PropTypes.array,
  uploadDocuments: PropTypes.func.isRequired,
  removePaymentFile: PropTypes.func
};

export default BottomPartForm;
