import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';

import authStore from 'stores/authStore';
import registrationStore from 'stores/registrationStore';
import userStore from 'stores/userStore';
import passwordRecoveryStore from 'stores/passwordRecoveryStore';
import paymentStore from 'stores/paymentStore';
import transactionsStore from 'stores/transactionsStore';
import currencyExchangeStore from 'stores/currencyExchangeStore';
import activityLogStore from 'stores/activityLogStore';
import representativeStore from 'stores/representativeStore';
import App from 'components/App';

import 'assets/fonts/Manrope-Regular.ttf';
import 'assets/fonts/Manrope-SemiBold.ttf';
import 'bootstrap/dist/css/bootstrap.css';
import 'styles.scss';


const stores = {
  authStore,
  registrationStore,
  userStore,
  passwordRecoveryStore,
  paymentStore,
  transactionsStore,
  currencyExchangeStore,
  activityLogStore,
  representativeStore
};

ReactDOM.render(
  <StrictMode>
    <Provider { ...stores }>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);
