/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';

import { INITIAL_PATH_AFTER_LOGIN } from 'routes/constants';
import Login from './Login';
import Pin from './Pin';
import Sms from './Sms';

const LoginStages = ({ authStore }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!authStore.isLoading && authStore.isLoginSuccess && authStore.isPinSuccess) {
      navigate(INITIAL_PATH_AFTER_LOGIN, { replace: true });
      authStore.reset();
    }
  }, [authStore.isLoading, authStore.isLoginSuccess, authStore.isPinSuccess]);

  const handleSetUsername = (username) => authStore.setUsername(username);

  const handleSetPassword = (password) => authStore.setPassword(password);

  const resendSecurityCode = () => authStore.resendSecurityCode();

  const handleCode = (code, disableSend) => {
    authStore.setCode(code);
    if(!disableSend) {
      authStore.sendCode();
    }
  };

  const handleSendAuthorizationData = () => authStore.sendAuthorizationData();

  const showSecondStep = () => {
    if(authStore.confirmationActionType !== 'PHONE') {
      return <Pin
        pin={ authStore.values.code }
        sendPin={ handleCode }
      />;
    }
    return <Sms
      phone={ authStore.phone }
      sms={ authStore.values.code }
      error={ authStore.error }
      handleSms={ handleCode }
      resendCode={ resendSecurityCode }
    />;
  };

  return (
    <div>
      {
        authStore.isLoginSuccess ?
          showSecondStep()
          :
          <Login
            username={ authStore.values.username }
            password={ authStore.values.password }
            setUsername={ handleSetUsername }
            setPassword={ handleSetPassword }
            sendAuthorizationData={ handleSendAuthorizationData }
            error={ authStore.error }
          />
      }
    </div>
  );
};

LoginStages.propTypes = {
  authStore: MobXPropTypes.observableObject
};

export default inject('authStore')(observer(LoginStages));
