import PropTypes from 'prop-types';
import { ReactComponent as CheckBoxIcon } from './checkbox.svg';
import { classNames } from '../utils';

export const CheckBox = ({ isActive, value, content, onChange, showCheckBox, className, disabled = false }) => {
  const checkBoxClasses = classNames({
    'dropdown-choice': true,
    'active': isActive,
    'disabled': disabled,
    [className]: className
  });

  const handleCheckBox = () => {
    !disabled && onChange();
  };

  return (
    <div className={ checkBoxClasses }
      role="button"
      tabIndex={ 0 }
      onClick={ handleCheckBox }
      onKeyDown={ handleCheckBox }
    >
      { value && <p className="dropdown-choice-value">{ value }</p> }
      { content }
      { showCheckBox && <CheckBoxIcon className="checkbox" /> }
    </div>
  );
};

CheckBox.propTypes = {
  isActive: PropTypes.bool.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  showCheckBox: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool
};
