import { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import Alert from 'uikit/Alert/Alert';
import i18nContext from 'components/i18n-context';
import { BackButton } from 'components/common/BackButton/BackButton';
import { getErrorMessageForAlert, replaceSpaces } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';

import './Login.scss';
import { PopUp } from 'uikit/PopUp/PopUp';
import { SESSION_STORAGE_CONSTANTS, SESSION_STORAGE_OPTIONS } from 'components/common/constants';
import { PopUpSessionEndedScheme } from 'components/common/PopUpScheme/PopUpSessionEndedScheme';
import PopUpSuccessScheme from '../common/PopUpScheme/PopUpSuccessScheme';

const Login = ({
  setUsername,
  setPassword,
  sendAuthorizationData,
  error: storeAPIError
}) => {
  const i18n = useContext(i18nContext);
  const [isSessionEnded, setIsSessionEnded] = useState(
    window.sessionStorage.getItem(SESSION_STORAGE_CONSTANTS.SESSION_ENDED
    ));

  const closeSessionEndedPopUp = () => {
    window.sessionStorage.removeItem(SESSION_STORAGE_CONSTANTS.SESSION_ENDED);
    setIsSessionEnded(false);
  };

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      username: null,
      password: null
    },
    initialStatus: { password: true, username: true },
    validationSchema: Yup.object({
      username: Yup.string()
        .nullable(true)
        .trim()
        .required(i18n.getMessage('login.error.emptyFiled', { field: 'email' })),
      password: Yup.string()
        .nullable(true)
        .min(7, i18n.getMessage('error.PASSWORD_REQUIREMENTS'))
        .max(30, i18n.getMessage('error.passwordSize', { amount: '30' }))
        .required(i18n.getMessage('login.error.emptyFiled', { field: 'password' }))
        // COMMENTED OUT IN ORDER TO KEEP OLDER ACCOUNTS AVAILABLE, NEED TO MAKE SURE THIS REGEX MATCHES THE ONE ON BE
        // .required(i18n.getMessage('register.error.passwordSecurityRequirementsViolation'))
        // .matches(
        //   passwordRegExp,
        //   { message: i18n.getMessage('register.error.password', { amount: '7-30' }) }
        // )
    }),
    onSubmit: (values) => {
      setUsername(replaceSpaces(values.username));
      setPassword(replaceSpaces(values.password));
      sendAuthorizationData();
    }
  });

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    submitCount
  } = form;

  return (
    <section className="authorization-form login">
      <h1 className="authorization-title">{ i18n.getMessage('login.title') }</h1>
      <Alert type={ 'warning' } message={ storeAPIError && getErrorMessageForAlert(i18n, storeAPIError) } />
      <form
        action="#"
        id="send"
        onSubmit={ handleSubmit }
        className="form">
        <div className="form-inputs-wrapper">
          <Input
            name={ 'username' }
            label={ i18n.getMessage('login.label.login') }
            placeholder={ i18n.getMessage('login.placeholder.login') }
            value={ replaceSpaces(values.username) }
            error={ errors?.username || (storeAPIError?.fields && storeAPIError.fields.username) }
            initialStatus={ submitCount }
            onChange={ handleChange }
            autoFocus={ true }
          />
          <Input
            type={ 'password' }
            name={ 'password' }
            label={ i18n.getMessage('login.label.password') }
            placeholder={ i18n.getMessage('login.placeholder.password') }
            value={ values.password }
            error={ errors?.password || (storeAPIError?.fields && storeAPIError.fields.password) }
            initialStatus={ submitCount }
            onChange={ handleChange }
          />
        </div>
        <Button
          type={ 'primary' }
          roleType={ 'submit' }
          size={ 'large' }
          fullWidth={ true }
          onClick={ () => {} }
        >
          { i18n.getMessage('login.btn.login') }
        </Button>
        <div className="form-forgot-password">
          <a href={ ROUTE_PATHS.PASSWORD_RECOVERY } className="link-dark">{ i18n.getMessage('login.btn.forgot') }</a>
        </div>
      </form>
      <BackButton type={ 'register' } />
      <PopUp show={ isSessionEnded } onClose={ () => closeSessionEndedPopUp() }>
        {
          isSessionEnded === SESSION_STORAGE_OPTIONS.SESSION_ENDED ?
            <PopUpSessionEndedScheme  onClose={ () => closeSessionEndedPopUp() } />
            :
            <PopUpSuccessScheme onClose={ () => closeSessionEndedPopUp() } 
              message={ i18n.getMessage('popUp.success.passwordRecovery') } />
        }
      </PopUp>
    </section>
  );
};

Login.propTypes = {
  setUsername: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  sendAuthorizationData: PropTypes.func.isRequired,
  error: PropTypes.any
};

export default observer(Login);
