import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from './i18n-context';

const ComplexTranslate = ({ text, interpolation }) => {
  const i18n = useContext(i18nContext);
  const translatedText = i18n.getMessage(text);

  const renderComplexTranslate = () => {
    const splittedText = translatedText.split(/{(.*?)}/);
    return splittedText.map((text, index) => {
      if (text in interpolation) {
        return <span key={ index }>{ interpolation[text] }</span>;
      }
      return <span key={ index }>{ text }</span>;
    });


  };

  return (
    renderComplexTranslate() 
  );
};

ComplexTranslate.propTypes = {
  text: PropTypes.string,
  interpolation: PropTypes.object
};

export default ComplexTranslate;
