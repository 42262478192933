import { useState } from 'react';
import PropTypes from 'prop-types';
import Sumsub from 'components/Verification/Sumsub';
import { ACCOUNT_TYPE, KYC_STATUSES } from 'components/common/constants';

import './Verification.scss';
import { Individual } from './Individual';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Corporate } from './Corporate';

const Verification = ({ kycStatus, userData }) => {
  const [isBeginVerification, setIsBeginVerification] = useState(false);
  const [applicantLevel, setApplicantLevel] = useState('');

  const handleOpenVerification = (level) => {
    setApplicantLevel(level);
    setIsBeginVerification(true);
  };

  const isUnderReview = kycStatus === KYC_STATUSES.UNDER_REVIEW;
  const isIndividual = userData.account.type === ACCOUNT_TYPE.INDIVIDUAL;
  const accountNumber = userData.account?.account_number;

  return (
    <>
      { !isBeginVerification && isIndividual &&
        <Individual
          handleOpenVerification={ handleOpenVerification }
          isUnderReview={ isUnderReview }
        />
      }
      { !isBeginVerification && !isIndividual &&
        <Corporate
          handleOpenVerification={ handleOpenVerification }
        />
      }
      { isBeginVerification && 
        <Sumsub 
          email={ userData.email } 
          accountNumber={ accountNumber } 
          levelName={ applicantLevel } 
        /> 
      }
    </>
  );
};

Verification.propTypes = {
  userData: MobXPropTypes.observableObject,
  kycStatus: PropTypes.string
};

export default inject(stores => ({
  userData: stores.userStore?.userData
})) (observer(Verification));
