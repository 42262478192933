import React from 'react';
import PropTypes from 'prop-types';

import { ActionButton } from 'uikit/ActionButton/ActionButton';
import { amountFormattedValue } from 'services/utils';

import './Card.scss';

export const Card = ({
  type,
  account,
  balance,
  currency,
  reserved,
  onExclaimPointClick,
  onTransactionClick,
  onPaymentClick,
  mobileText,
  tooltipText = []
}) => {

  if (type === 'list') {
    return (
      <div className="account-card list">
        <div className="account-card-item">
          <p className="card-mobile-text">{ mobileText?.account }</p>
          <p className="account">{ account }</p>
        </div>
        <div className="account-card-item">
          <p className="card-mobile-text">{ mobileText?.currency }</p>
          <p className="currency">{ currency }</p>
        </div>
        <div className="account-card-item">
          <p className="card-mobile-text">{ mobileText?.reserved }</p>
          <p className="reserved">{ amountFormattedValue(reserved) + ' ' + currency }</p>
        </div>
        <div className="account-card-item">
          <p className="card-mobile-text">{ mobileText?.balance }</p>
          <p className="balance">{ amountFormattedValue(balance) + ' ' + currency }</p>
        </div>
        <div className="account-card-item">
          <p className="card-mobile-text">{ mobileText?.actions }</p>
          <div className="wrapper-icon">
            <ActionButton tooltipText={ tooltipText && tooltipText[2] } onClick={ onExclaimPointClick } />
            <ActionButton tooltipText={ tooltipText && tooltipText[0] }
              iconType="transaction" 
              onClick={ onTransactionClick } />
            <ActionButton tooltipText={ tooltipText && tooltipText[1] }
              iconType="new-payment" 
              onClick={ onPaymentClick } />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="account-card">
      <div className="left-side">
        <p className="account">{ account }</p>
        <div className="wrapper-balance">
          <p className="balance">{ amountFormattedValue(balance) }</p>
          <p className="currency">{ currency }</p>
        </div>
      </div>
      <div className="buttons-wrapper">
        <ActionButton tooltipText={ tooltipText[0] } iconType="transaction" onClick={ onTransactionClick } />
        <ActionButton tooltipText={ tooltipText[1] } iconType="new-payment" onClick={ onPaymentClick } />
        <ActionButton tooltipText={ tooltipText[2] } onClick={ onExclaimPointClick } />
      </div>
    </div>
  );
};

Card.propTypes = {

};
