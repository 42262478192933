import { makeAutoObservable, runInAction } from 'mobx';
import { isPhoneConfirmationType } from 'services/utils';
import { PASSWORD_RECOVERY_STATUSES, SESSION_STORAGE_CONSTANTS } from 'components/common/constants';
import {
  passwordRecoveryRequest,
  changePasswordUser,
  generatePasswordRecoverySecurityCode,
  fetchRecoveryLink, passwordReset
} from 'services/requestAgent';
import { logoutActiveSessionWithoutToken } from 'services/authUtils';

class PasswordRecoveryStore {
  isLoading = false;
  isLoginSuccess = false;
  isResetPasswordSuccess = null;
  recoveryLinkError = null;
  error = null;
  confirmationActionType = null;
  phone = null;
  recoveryStatus = null;
  isSecurityCodeGenerated = false;
  responseId = null;

  values = {
    username: '',
    password: '',
    code: ''
  };

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  setUsername(username) {
    this.values.username = username;
  }

  setPassword(password) {
    this.values.password = password;
  }

  setCode(code) {
    this.values.code = code;
  }

  setRecoveryStatus(status) {
    this.recoveryStatus = status;
  }

  setConfirmationActionType(confirmationType) {
    this.confirmationActionType = confirmationType;
  }
  
  reset() {
    this.isLoading = false;
    this.isLoginSuccess = false;
    this.isPinSuccess = false;
    this.isResetPasswordSuccess = false;
    this.isError = false;
    this.values.username = '';
    this.values.password = '';
    this.values.code = '';
    this.recoveryLinkError = '';
  }

  async getPasswordRecoveryLinkData(tokenId) {
    this.setIsLoading(true);
    try {
      const { 
        confirmation_type,
        password_recovery_status,
        phone,
        response_id 
      } = await fetchRecoveryLink(tokenId);
      runInAction(() => {
        this.isLoading = false;
        this.confirmationActionType = confirmation_type;
        this.phone = phone;
        this.responseId = response_id;
        this.recoveryStatus = password_recovery_status;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.recoveryLinkError = err;
      });
    }
  }

  async sendPasswordRecoveryRequest() {
    this.setIsLoading(true);
    try {
      await passwordRecoveryRequest({ email: this.values.username });
      runInAction(() => {
        this.isLoading = false;
        this.recoveryLinkError = null;
        this.recoveryStatus = PASSWORD_RECOVERY_STATUSES.EMAIL_CONFIRMATION;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  async generateSecurityCode() {
    this.setIsLoading(true);
    try {
      const { response_id } = await generatePasswordRecoverySecurityCode(this.responseId);
      runInAction(() => {
        this.isSecurityCodeGenerated = true;
        this.responseId = response_id;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  async sendPasswordChange() {
    this.setIsLoading(true);
    try {
      if (isPhoneConfirmationType(this.confirmationActionType) && !this.isSecurityCodeGenerated) {
        await this.generateSecurityCode(this.responseId);
        return null;
      }
      await changePasswordUser({
        password: this.values.password,
        response_id: this.responseId,
        code: this.values.code
      });
      runInAction(() => {
        this.isLoading = false;
      });
      window.sessionStorage.setItem(SESSION_STORAGE_CONSTANTS.SESSION_ENDED, 'passwordRecovery');
      logoutActiveSessionWithoutToken();
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  async sendPasswordResetRequest( data ) {
    this.setIsLoading(true);
    try {
      await passwordReset( data );
      runInAction(() => {
        this.isResetPasswordSuccess = true;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }
}

export default new PasswordRecoveryStore();
