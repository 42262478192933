import React from 'react';
import PropTypes from 'prop-types';
import './Status.scss';

export const Status = ({ status, color }) => {
  return (
    <p className={ color }>
      { status }
    </p>
  );
};

Status.propTypes = {
  key: PropTypes.string,
  status: PropTypes.string 
};
