import { useState } from 'react';
import PropTypes from 'prop-types';
import './Input.scss';
import { ReactComponent as IconPassword } from './eye.svg';
import { ReactComponent as IconPasswordOff } from './eye-off.svg';
import { ReactComponent as IconError } from './warning.svg';
import { classNames } from '../utils';

const Input = ({
  type = 'text',
  name,
  error,
  value,
  onChange,
  onBlur = () => {},
  onFocus = () => {},
  label,
  placeholder,
  subText,
  className,
  Icon,
  initialStatus,
  isDisabled,
  autoComplete = 'on',
  isRequired,
  autoFocus = false,
  max
}) => {
  const [inputType, setInputType] = useState(type);

  const toggleShowingPassword = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  const inputChange = e => {
    onChange(e);
  };

  const handleBlur = e => onBlur(e);

  const handleFocus = () => onFocus();

  const inputClasses = classNames({
    'input-wrapper': true,
    'error': !!error,
    'hit': !error && !!initialStatus,
    'disabled': isDisabled,
    'icon': Icon || type === 'password' || error,
    [className]: true
  });

  const renderIcons = () => {
    if(Icon) {
      return <Icon className="input-icon" />;
    }

    if (type === 'password') {
      return <div
        role="button"
        tabIndex={ 0 }
        onKeyDown={ toggleShowingPassword }
        onClick={ toggleShowingPassword }
        className={ `input-icon eye ${error ? 'error' : ''}` }>
        { inputType === 'text' ? <IconPassword /> :  <IconPasswordOff  /> }
        { error && <IconError  /> }
      </div>;
    }

    return error && <IconError className="input-icon" />;
  };

  return (
    <div className={ inputClasses }>
      { label && <label htmlFor={ name }>{ label + (isRequired ? '*' : '') }</label> }
      <input
        name={ name }
        type={ inputType }
        value={ value || '' }
        onChange={ inputChange }
        onBlur={ handleBlur }
        onFocus={ handleFocus }
        placeholder={ placeholder }
        disabled={ isDisabled }
        autoComplete={ autoComplete }
        autoFocus={ autoFocus }
        maxLength={ max }
        className="input" >
      </input>
      { renderIcons() }
      { ( error || subText ) && <p className={ `input-sub-text ${error ? 'input-error' : ''}` }>
        { error || subText }</p> }
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  subText: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  isRequired: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isDisabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  initialStatus: PropTypes.number,
  Icon: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  max: PropTypes.number
};

export default Input;
