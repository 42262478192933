import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ReactComponent as HeaderIcon } from 'assets/logo.svg';
import { ReactComponent as HeaderIconCompact } from 'assets/logoCompact.svg';
import UserPanel from './UserPanel';
import { IconButton } from 'uikit/IconButton/IconButton';
import './Header.scss';
import { getAppRouteButtons } from 'components/common/MenuButtons';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { INITIAL_PATH_AFTER_LOGIN } from 'routes/constants';
import NavigationBar from '../NavigationBar';


const Header = ({ compact, collapseMenu }) => {
  const navigate = useNavigate();

  const isDesktop = useMediaQuery(MEDIA_QUERY.DESKTOP);
  const MenuButtons = getAppRouteButtons();

  const goToInitialPage = () => navigate(INITIAL_PATH_AFTER_LOGIN);

  return (
    <header className="header">
      <div className={ `header-content-wrapper ${compact ? 'active' : ''}` }>
        { compact ? 
          <HeaderIconCompact className="header-logo-compact" onClick={ goToInitialPage } /> :
          <HeaderIcon className="header-logo" onClick={ goToInitialPage } />
        }
        <div className="header-menu-links">
          <IconButton
            className={ 'header-menu-button' }
            active={ compact }
            Icon={ compact? MenuButtons[0].mobileIcon : MenuButtons[0].icon }
            text={ MenuButtons[0].text }
            onClick={ () => collapseMenu(!compact) } />
          { isDesktop && <UserPanel /> }
        </div>
      </div>
      { /*{ !isDesktop && compact && <div className="mobile-navigation"><NavigationBar /> </div> }*/ }
    </header>
  );
};

Header.propTypes = {
  compact: PropTypes.bool,
  collapseMenu: PropTypes.func
};

export default Header;
