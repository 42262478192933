export const prepareDataForValidate = (data) =>
  Object.keys(data).reduce((res, item) => {
    if (typeof data[item] === 'object') {
      return { ...res, ...data[item] };
    }
    res[item] = data[item];
    return res;
  }, {});

export const isOnlyLatinLetters = (str) => str.length && /^([A-Za-z\s]*)$/g.test(str);
export const isOnlyLatinLettersDigitsSymbols = ({ value, availableSymbols }) => {
  const regexp = new RegExp(`^([A-Za-z0-9\\s${availableSymbols}]*)$`, 'g');
  return value.length && !!value.match(regexp);
};
export const isOnlyLatinLettersAndDigits = (str) => str.length && /^([A-Za-z0-9\s]*)$/g.test(str);

export const isEmptyField = (value) => !value || !value.length;

export const setError = (errorsObject, field, errorMessage) => {
  if (errorsObject[field]) {
    errorsObject[field] = [...errorsObject[field], errorMessage];
    return errorsObject;
  }
  errorsObject[field] = [errorMessage];
  return errorsObject;
};


export const LATIN_LETTERS_REG_EXP = /^([A-Za-z\s]*)$/g;

export const LATIN_LETTERS_AND_DIGITS_REG_EXP = /^([A-Za-z0-9\s]*)$/g;

export const getLatinLettersDigitsSymbolsRegExp = (symbols) => 
  new RegExp(`^([A-Za-z0-9\\s${symbols}]*)$`, 'g');

export const START_WITH_GB_REG_EXP = /^GB+.*$/g;

export const ONLY_DIGITS_REG_EXP = /^\d+$/g;

export const GBP_SORT_CODE_REG_EXP = /(\d{2}-?){2}\d{2}/g;

export const BIC_REG_EXP = /^[a-z]{6}[0-9a-z]{2}([0-9a-z]{3})?z/i;
