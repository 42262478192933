import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from '../i18n-context';
import { ReactComponent as SimpleIcon } from 'assets/simple-icon.svg';
import { ReactComponent as StandardIcon } from 'assets/standard-icon.svg';
import Button from 'uikit/Button/Button';

export const Corporate = ({ handleOpenVerification }) => {
  const i18n = useContext(i18nContext);
  return (
    <div className="corporate-wrapper">
      <div className="verification">
        <SimpleIcon className="corporate-icon" />
        <div className="bank-wrapper">
          <h1 className="bank-header">
            { i18n.getMessage('verification.corporate.title.simple') }
          </h1>
          <p className="bank-description">
            { i18n.getMessage('verification.corporate.description.simple') }
          </p>
          <Button
            type={ 'primary' }
            roleType={ 'submit' }
            size={ 'large' }
            onClick={ () => handleOpenVerification('kyb-single-ownership') }
          >
            { i18n.getMessage('button.beginVerification') }
          </Button>
        </div>
      </div>

      <div className="verification">
        <StandardIcon className="corporate-icon" />
        <div className="bank-wrapper">
          <h1 className="bank-header">
            { i18n.getMessage('verification.corporate.title.standard') }
          </h1>
          <p className="bank-description">
            { i18n.getMessage('verification.corporate.description.standard') }
          </p>
          <Button
            type={ 'primary' }
            roleType={ 'submit' }
            size={ 'large' }
            onClick={ () => handleOpenVerification('KYB') }
          >
            { i18n.getMessage('button.beginVerification') }
          </Button>
        </div>
      </div>
    </div>
  );
};

Corporate.propTypes = {
  handleOpenVerification: PropTypes.func.isRequired
};
