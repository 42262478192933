import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Link } from 'react-router-dom';

import TransactionsList from './TransactionsList';
import { EmptyTransactionsList } from './EmptyTransactionsList';

import './LatestTransactions.scss';
import { Container } from 'uikit/Container/Container';
import i18nContext from 'components/i18n-context';
import Loader from 'components/common/Loader';
import { ROUTE_PATHS } from 'routes/constants';

const LatestTransactionsCompact = ({ accountNumber, transactionsStore, transferSuccessful }) => {
  const i18n = useContext(i18nContext);
  useEffect(() => {
    if (accountNumber || transferSuccessful) {
      transactionsStore.getLatestTransactions(accountNumber);
    }
  }, [accountNumber, transferSuccessful]);
  
  return (
    <section className="right-section latest-transactions">
      <Container className="transactions-container" header={ i18n.getMessage('container.latestTransactions') }>
        { 
          transactionsStore.isLoading ?
            <Loader/> :
            transactionsStore.latestTransactionList.length ?
              <>
                <Link
                  className="link-normal transaction-link"
                  to={ ROUTE_PATHS.TRANSACTIONS }
                >
                  { i18n.getMessage('latestTransactions.link.transactions') }
                </Link >
                <TransactionsList
                  compact
                  transactions={ transactionsStore.latestTransactionList }
                />
              </> :
              <EmptyTransactionsList />
        }
      </Container>
    </section>
  );
};

LatestTransactionsCompact.propTypes = {
  accountNumber: PropTypes.string,
  compact: PropTypes.bool,
  transferSuccessful: PropTypes.bool,
  transactionsStore: MobXPropTypes.observableObject
};

export default inject(stores => ({
  accountNumber: stores.userStore.userData.account?.account_number,
  transactionsStore: stores.transactionsStore,
  transferSuccessful: stores.paymentStore.isSuccess
}))(observer(LatestTransactionsCompact));
