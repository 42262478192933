import PropTypes from 'prop-types';
import './PopUp.scss';
import { classNames } from '../utils';

export const PopUp = ({ onClose, show ,children, className, alignOnCenter, isHighPriority }) => {

  if(!show) return null;

  const onBackgroundClick = e => {
    if(e.target.className === 'pop-up'){
      onClose();
    }
  };

  const popUpCardClasses = classNames({
    'pop-up-card': true,
    [className]: true
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={ `pop-up ${ isHighPriority ? 'high-priority-index': ''} ${ alignOnCenter ? 'align-on-center' : ''}` }
      onClick={ e => onBackgroundClick(e) }
    >
      <div className={ popUpCardClasses }>
        { children }
      </div>
    </div>
  );
};

PopUp.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
  children: PropTypes.object,
  className: PropTypes.string,
  alignOnCenter: PropTypes.bool,
  isHighPriority: PropTypes.bool
};
