/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Phone from '../User/Phone';
import { REGISTRATION_STATUSES } from 'components/common/constants';
import Sms from 'components/Authorization/Sms';
import i18nContext from 'components/i18n-context';
import Alert from 'uikit/Alert/Alert';
import { resendPhoneRegistration } from 'services/requestAgent';
import { getErrorMessageForAlert } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';

import '../RegistrationStyles.scss';
import Credentials from './Credentials';

const Registration = ({ registrationStore }) => {
  const navigate = useNavigate();
  const i18n = useContext(i18nContext);

  useEffect(() => {
    registrationStore.clearError();
    if(registrationStore.registrationStatus === REGISTRATION_STATUSES.REGISTRATION_STATUS_CONFIRMED) {
      registrationStore.reset();
      registrationStore.setIsAfterRegistrationUser(true);
      navigate(`${ROUTE_PATHS.ACCOUNT_REGISTRATION}`);
    }
  }, [registrationStore.registrationStatus]);

  switch(registrationStore.registrationStatus) {
  case REGISTRATION_STATUSES.REGISTRATION_STATUS_SECOND_FACTOR_CONFIRMATION_PHONE:
    return <Phone/>;
  case REGISTRATION_STATUSES.REGISTRATION_STATUS_PHONE_CONFIRMATION: {
    const handleCode = (code, disableSend) => {
      registrationStore.setCode(code);
      if (!disableSend) {
        registrationStore.sendCode();
      }
    };
    return <Sms
      phone={ registrationStore.generalFields.phoneNumber }
      sms={ registrationStore.generalFields.code }
      error={ registrationStore.error }
      resendCode={ resendPhoneRegistration }
      handleSms={ handleCode }
      isRegistration
    />;
  }
  default:
    return <div className="registration-form" id="form1">
      <h1 className="registration-title user"> { i18n.getMessage('register.title.createUser') } </h1>
      <Alert
        type={ 'warning' }
        message={
          registrationStore?.error && getErrorMessageForAlert(i18n, registrationStore.error)
        }
      />
      <div className="form-data">
        <Credentials />
      </div>
    </div>;
  }
};

Registration.propTypes = {
  registrationStore: MobXPropTypes.observableObject
};

export default inject('registrationStore')(observer(Registration));
