import { useContext } from 'react';
import PropTypes from 'prop-types';

import { ROUTE_PATHS } from 'routes/constants';
import i18nContext from 'components/i18n-context';

export const BackButton = ({ type }) => {
  const i18n = useContext(i18nContext);

  return (
    <div className="back-button unset">
      {
        type === 'register' ?
          <p>
            { i18n.getMessage('login.label.noAccount') }
            <a href={ ROUTE_PATHS.REGISTRATION } className="link-normal">{ i18n.getMessage('login.link.register') }</a>
          </p>
          :
          <p>
            { i18n.getMessage('pin.returnToLogIn') }
            <a href={ ROUTE_PATHS.LOGIN } className="link-normal">{ i18n.getMessage('login.title') }</a>
          </p>
      }
    </div>
  );
};

BackButton.propTypes = {
  type: PropTypes.string
};
