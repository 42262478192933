/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Container } from 'uikit/Container/Container';
import i18nContext from 'components/i18n-context';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import Input from 'uikit/Input/Input';
import Alert from 'uikit/Alert/Alert';
import {
  ClearJunctionTable,
  OpenPaydTable
} from './ProviderForms';
import { debounce, getErrorMessageForAlert, amountFormattedValue } from 'services/utils';
import TransferConfirmationScheme from 'components/common/PopUpScheme/TransferConfirmationScheme';
import { PopUp } from 'uikit/PopUp/PopUp';
import './PaymentForm.scss';
import PopUpSuccessScheme from '../PopUpScheme/PopUpSuccessScheme';
import { PAYMENT_PROVIDERS, FORM_TYPES, WALLET_PROPERTIES } from '../constants';

const SIMPLE_FORM = FORM_TYPES.SIMPLE;
const ADVANCED_FORM = FORM_TYPES.ADVANCED;

const PaymentForm = ({ accountNumber, userStore, paymentStore }) => {
  const i18n = useContext(i18nContext);
  const providerType = paymentStore.currentWallet?.transfer_provider;

  useEffect(() => {
    return () => {
      paymentStore.resetPaymentStore();
    };
  }, []);

  useEffect(() => {
    if(paymentStore.isSuccess) {
      userStore.getUserWallets();
    }
  }, [paymentStore.isSuccess]);

  const activeTab = paymentStore.currentWallet?.[WALLET_PROPERTIES.MULTI_WALLET_NUMBER] &&
    providerType === PAYMENT_PROVIDERS.OPENPAYD &&
    !paymentStore.isInternalIban
    ? ADVANCED_FORM : SIMPLE_FORM;

  // Preselecting wallet with Clear Junction provider
  useEffect(() => {
    const hasWallets = userStore.userWallets.length > 0;
    const clearJunctionWallet = userStore.userWallets.find(({ transfer_provider }) =>
      transfer_provider === PAYMENT_PROVIDERS.CLEARJUNCTION);
    clearJunctionWallet && !paymentStore.currentWallet && paymentStore.setSelectedWallet(clearJunctionWallet);
    if(paymentStore.currentWallet) {
      handleWalletChange('', paymentStore.currentWallet.wallet_number);
    }
    if(!paymentStore.currentWallet && !clearJunctionWallet && hasWallets) {
      handleWalletChange('', walletsOptions[0].key);
    }

    return () => {
      paymentStore.setPreviousTransactionInfo(null);
    };
  }, [userStore.userWallets]);

  const walletsOptions = userStore.userWallets?.map(wallet => {
    return {
      key: wallet.wallet_number,
      value: `${wallet.iban?.iban || wallet.multi_iban?.iban} ${wallet.currency}`
    };
  });

  const setProviderData = (data) => paymentStore.setProviderData(data);
  const getCommission = (accountNumber, data) => paymentStore.getCommission(accountNumber, data, activeTab);
  const validateTransfer = (...args) => paymentStore.validateTransfer( ...args, activeTab );
  const removePaymentFile = (fileId) => paymentStore.removePaymentFile(fileId);
  const validateIban = (iban) => paymentStore.validateIban(iban, providerType, accountNumber );
  const validationProps = {
    i18n,
    accountNumber,
    onChange: setProviderData,
    onSubmit: validateTransfer,
    previousTransaction: paymentStore.previousTransactionInfo,
    currency: paymentStore?.currentWallet?.currency
  };
  const formProps = {
    wallet:  { ...paymentStore.currentWallet },
    commission: paymentStore.commission,
    isCommissionLoading:  paymentStore.isCommissionLoading,
    paymentMethod:  paymentStore.paymentMethod,
    uploadedFiles:  paymentStore.uploadedFiles,
    removePaymentFile:  removePaymentFile,
    accountNumber:  accountNumber,
    getCommission:  debounce(getCommission, 400),
    isInternalIban: paymentStore.isInternalIban,
    checkIban: validateIban,
    isIbanCheckLoading: paymentStore.isIbanCheckLoading,
    uploadDocuments:  paymentStore.uploadDocuments(accountNumber),
    error:  paymentStore.error,
    isSuccess:  paymentStore.isSuccess,
    isLoading:  paymentStore.isLoading || paymentStore.isFileUploading,
    currency: paymentStore?.currentWallet?.currency,
    formType: activeTab,
    validationProps
  };

  const getProviderTable = (providerType) => {
    switch (providerType) {
    case PAYMENT_PROVIDERS.CLEARJUNCTION: {
      return <ClearJunctionTable
        { ...formProps }
      />;
    }
    case PAYMENT_PROVIDERS.OPENPAYD: {
      return <OpenPaydTable
        { ...formProps }
      />;
    }
    default:
      return <div>{ i18n.getMessage('transfer.provider.notFound') }</div>;
    }
  };

  const handleWalletChange = (name, data) => {
    const selectedWallet = userStore.userWallets.find(({ wallet_number }) =>
      wallet_number === data);
    paymentStore.setSelectedWallet(selectedWallet);
  };

  const getAvailableBalance = () =>
    paymentStore?.currentWallet &&
    i18n.getMessage('sendMoney.topLabel',
      { available: amountFormattedValue(paymentStore?.currentWallet.available),
        currency: paymentStore?.currentWallet.currency });

  return (
    <Container className="send-money" header={ i18n.getMessage('container.sendMoney') }>
      <Alert
        className="send-money-alert"
        type={ 'warning' }
        message={
          paymentStore.error &&
          !paymentStore.error?.type ? getErrorMessageForAlert(i18n, paymentStore.error) : ''
        }
      />
      <div className="send-money-wrapper">
        <div className="inputs-wrapper">
          <InputDropDown
            isRequired={ true }
            topLabel={ getAvailableBalance() }
            label={ i18n.getMessage('transfer.form.accountNumber.label') }
            value={
              paymentStore.currentWallet?.wallet_number
            }
            onChange={ handleWalletChange }
            options={ walletsOptions }
            isMulti={ false }
          />
          <Input
            isRequired={ true }
            isDisabled={ true }
            label={ i18n.getMessage('transfer.form.currency.label') }
            value={ paymentStore.currentWallet?.currency }
            onChange={ () => {} }
          />
        </div>
        { getProviderTable(providerType) }
      </div>
      <PopUp
        className={ paymentStore.isSuccess? 'transaction-success' : 'transaction-info' }
        show={ paymentStore.isTransactionConfirmation || paymentStore.isSuccess }
        alignOnCenter={ paymentStore.isSuccess }
        onClose={ paymentStore.isSuccess ? () => paymentStore.resetSuccess()
          : () => paymentStore.setIsTransactionConfirmation(false) }
      >
        {
          paymentStore.isSuccess ?
            <PopUpSuccessScheme
              onClose={ () => paymentStore.resetSuccess() }
              message={ i18n.getMessage('popUp.message.moneyTransfer') }
            />
            :
            <TransferConfirmationScheme
              transferData={ {
                ...paymentStore.serverTransactionData,
                uploadedFiles: paymentStore.uploadedFiles
              } }
              error={ paymentStore.confirmationPopupError }
              generateSecurityCode={ () => paymentStore.generatePaymentSecurityCode() }
              resendSecurityCode={ () => paymentStore.resendPaymentSecurityCode() }
              clearError={ () => paymentStore.clearConfirmationPopupError() }
              onConfirm={ paymentStore.createTransfer(accountNumber, activeTab) }
              onClose={ () => paymentStore.setIsTransactionConfirmation(false) }
            />
        }
      </PopUp>
    </Container>
  );
};

PaymentForm.propTypes = {
  paymentStore: MobXPropTypes.observableObject,
  userStore: MobXPropTypes.observableObject,
  accountNumber: PropTypes.string
};

export default inject(stores => ({
  accountNumber: stores.userStore.userData.account?.account_number,
  userStore: stores.userStore,
  paymentStore: stores.paymentStore
}))(observer(PaymentForm));
