import { getConfirmationActionType } from './authUtils';
import { FieldError } from 'services/Error';

// eslint-disable-next-line max-len
export const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// eslint-disable-next-line max-len
export const passwordRegExp = /(?=.*[A-Za-z])(?=.*\d)(?=.*[-!+"#$%&'()* ,./:;<=>?@[\\\]_`{|}~])[A-Za-z\d-!+"#$%&'()* ,./:;<=>?@[\\\]_`{|}~]{7,30}$/;

export const whiteSpacesRegExp = /\s/g;

export const replaceSpaces = s => s?.replace(/\s/g, '');

export const deleteNonLatin = text => text.replace(/[^A-Za-z0-9_ ]/ig, '');

export const strToFloat = str => {
  const trimmed = str.trim().replace(/\.$/, '');
  const float = parseFloat(trimmed);

  return float === Number(trimmed) ?
    float :
    Number.NaN;
};

export const isPhoneConfirmationType = (confirmationActionType) => {
  if (confirmationActionType) {
    return confirmationActionType === 'PHONE';
  }
  return getConfirmationActionType() === 'PHONE';
};

export const getErrorMessageForAlert = (i18n, error, isIgnored = true) => {
  if(error instanceof FieldError && isIgnored) return '';
  const translationId = `error.${error.code}`;
  const translation = i18n.getMessage(translationId);
  if (translationId === translation) {
    return error.message || error.code;
  } else {
    return translation;
  }
};

export const prepareFieldErrors = (i18n, error, variable) => {
  let fieldsError = {};
  error?.fields && Object.keys(error.fields).map(field => {
    fieldsError[field.split('.').pop()] = i18n.getMessage(`error.fieldError.${field}.${error.fields[field]}`, { variable });
  });
  return fieldsError;
};

export const debounce = (func, delay = 400) => {
  let timeout;
  return function(...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      return func.apply(context, args);
    }, delay);
  };
};

export const MEDIA_QUERY = {
  DESKTOP: '(min-width: 769px)',
  NON_DESKTOP: '(min-width: 320px)',
  MOBILE: '(max-width: 499px)'
};

export const convertDateToServerFilter = (date) => {
  if (date !== null) {
    return `${date.getFullYear()}${date.getMonth() < 9 ?
      '0' + (date.getMonth() + 1) :
      date.getMonth() + 1}${date.getDate() < 10 ?
      '0' + date.getDate() :
      date.getDate()}`;
  } else {
    return '';
  }
};

export const convertDate = (date, isEndOfDay = false) => {
  if (date !== null) {
    return `${date.getFullYear()}-${date.getMonth() < 9 ?
      '0' + (date.getMonth() + 1) :
      date.getMonth() + 1}-${date.getDate() < 10 ?
      '0' + date.getDate() :
      date.getDate()}T${isEndOfDay ? '23:59:59' : '00:00:00'}`;
  }
  return '';
};

export const amountFormattedValue = (value, precision = 2) => {
  if (value === undefined || value === '' || value === null) {
    return '';
  }

  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision
  }).format(value);
};

export const findWalletByCurrency = ({ wallets, currency, currencyForExclude }) => {
  if(!currency) {
    return wallets.find(wallet => wallet.currency !== currencyForExclude);
  }
  return wallets.reduce((result, wallet) => {
    if(wallet.currency === currencyForExclude) {
      return undefined;
    }
    return wallet.currency === currency;
  }, undefined);
};

// FEES DATA FORMAT FUNCTIONS

export const format = val => {
  const formatted = val?.split('_').join(' ').toLowerCase();
  const capitalized = formatted?.charAt(0).toUpperCase() + formatted?.slice(1);
  return capitalized;
};

export const formServiceName = obj => {
  return format(obj.name);
};

export const formPaymentMethod = obj => {
  if (obj.transfer_type === 'EXCHANGE') {
    return format(obj.transfer_type);
  } else {
    return format(obj.payment_method);
  }
};

export const formTransactionType = obj => {
  if (obj.transfer_type === 'RETURN') {
    const capitalizedTransactionType = format(obj.transaction_type);
    const capitalizedTransferType = format(obj.transfer_type);
    return `${capitalizedTransactionType} ${capitalizedTransferType}`;
  } else {
    return format(obj.transaction_type);
  }
};
