import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import i18nContext from 'components/i18n-context';
import Input from 'uikit/Input/Input';
import Button from 'uikit/Button/Button';
import { BackButton } from 'components/common/BackButton/BackButton';
import { getErrorMessageForAlert, prepareFieldErrors } from 'services/utils';
import { SmsCodeWrapper } from '../common/SmsCodeWrapper/SmsCodeWrapper';
import { getPasswordValidation } from '../Registration/utils';
import { SMS_ERROR_CODE_DISABLE } from '../common/constants';

export const ChangePassword = ({ 
  onSubmitStep, 
  setPassword, 
  error: storeAPIError, 
  isConfirmation, 
  phone, 
  resendCode 
}) => {
  const i18n = useContext(i18nContext);
  const serverFieldsErrors = prepareFieldErrors(i18n, storeAPIError);
  const [showCountDown, setShowCountDown] = useState(false);
  const [alertState, setAlertState] = useState({ type: '', message: '' });
  const [ isDisabled, setIsDisabled ] = useState(false);
  const [ smsCode, setSmsCode ] = useState(null);

  useEffect( () => {
    if(isConfirmation) {
      setShowCountDown(true);
    }
    if ( SMS_ERROR_CODE_DISABLE.includes(storeAPIError?.code)) {
      setIsDisabled(true);
    }
    storeAPIError && setAlertState({ type: 'warning', message: getErrorMessageForAlert(i18n, storeAPIError) });
  }, [storeAPIError, isConfirmation]);

  const handleResendCode = async(e) => {
    e.preventDefault();
    setShowCountDown(true);
    await resendCode();
    isDisabled && setIsDisabled(false);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
  };

  const onTimerEnd = () => {
    setShowCountDown(false);
  };

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      password: null,
      repeat_password: null,
      code: null
    },
    validationSchema: Yup.object({
      password: getPasswordValidation(),
      repeat_password: Yup.string()
        .nullable(true)
        .required(i18n.getMessage('error.passwordEmpty'))
        .oneOf([Yup.ref('password')], i18n.getMessage('error.passwordsDontMatch'))
    }),
    onSubmit: (values) => {
      setAlertState({ type: '', message: '' });
      onSubmitStep( isConfirmation ? smsCode  : values.password);
    }
  });

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    submitCount
  } = form;

  const handleChangePassword = (e) => {
    handleChange(e);
    setPassword(e.target.value);
  };

  return (
    <section className="authorization-form email">
      <h1 className="authorization-title">{ i18n.getMessage('recovery.title.changePassword') }</h1>
      <div className="sms-description">
        { i18n.getMessage('recovery.changePassword.description') }
      </div>
      <form
        action="#"
        id="send"
        onSubmit={ handleSubmit }
        className="form">
        <div className="form-inputs-wrapper">
          <Input
            type={ 'password' }
            name={ 'password' }
            label={ i18n.getMessage('settings.form.label.newPassword') }
            placeholder={ i18n.getMessage('register.password.change.placeholder') }
            value={ values.password }
            error={ errors?.password || serverFieldsErrors.password }
            initialStatus={ submitCount }
            onChange={ handleChangePassword }
            isRequired={ true }
            subText={ i18n.getMessage('register.password.subText', { amount: '7-30' }) }
          />
          <Input
            type={ 'password' }
            name={ 'repeat_password' }
            label={ i18n.getMessage('settings.form.label.repeatPassword') }
            placeholder={ i18n.getMessage('register.password.change.placeholder') }
            value={ values.repeat_password }
            error={ errors?.repeat_password || serverFieldsErrors.password }
            initialStatus={ submitCount }
            onChange={ handleChangePassword }
            isRequired={ true }
            subText={ i18n.getMessage('register.password.subText', { amount: '7-30' }) }
          />
          {
            isConfirmation &&
              <SmsCodeWrapper
                PhoneComponent={
                  <strong>{ phone }</strong>
                }
                isDisabled={ isDisabled }
                error={ storeAPIError }
                onComplete={ (sms) => setSmsCode(sms) }
                showCountDown={ showCountDown }
                handleResendCode={ handleResendCode }
                onTimerEnd={ onTimerEnd }
                alertState={ alertState }
              />
          }
        </div>
        <Button
          type={ 'primary' }
          roleType={ 'submit' }
          size={ 'large' }
          fullWidth={ true }
          onClick={ () => {} }
          className="password-recovery-button"
        >
          { i18n.getMessage('recovery.button.confirm') }
        </Button>
      </form>
      <BackButton type={ 'register' } />
    </section>
  );
};

ChangePassword.propTypes = {
  onSubmitStep: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  isConfirmation: PropTypes.bool.isRequired,
  phone: PropTypes.string.isRequired,
  error: PropTypes.any
};
