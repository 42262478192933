import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import i18nContext from 'components/i18n-context';
import { ReactComponent as PhoneIcon } from 'assets/Message_open2.svg';
import { SecurityInput } from 'uikit/SecurityInput/SecurityInput';
import Alert from 'uikit/Alert/Alert';
import authStore from 'stores/authStore';
import ComplexTranslate from 'components/ComplexTranslate';
import { BackButton } from 'components/common/BackButton/BackButton';
import { CountDown } from 'components/common/CountDown/CountDown';
import { getErrorMessageForAlert } from 'services/utils';
import registrationStore from 'stores/registrationStore';
import { SmsCodeWrapper } from '../common/SmsCodeWrapper/SmsCodeWrapper';
import { SMS_ERROR_CODE_DISABLE } from '../common/constants';

const Sms = ({ sms, handleSms, resendCode, phone, error, isRegistration }) => {
  const i18n = useContext(i18nContext);
  const [showCountDown, setShowCountDown] = useState(false);
  const [alertState, setAlertState] = useState({ type: '', message: '' });
  const [ isDisabled, setIsDisabled ] = useState(false);


  useEffect(() => {
    if ( SMS_ERROR_CODE_DISABLE.includes(error?.code)) {
      setIsDisabled(true);
      handleSms(sms, true);
    }
    error && setAlertState({ type: 'warning', message: getErrorMessageForAlert(i18n, error) });
  }, [error]);

  useEffect(() => {
    setShowCountDown(true);
  }, []);

  const handleResendCode = async() => {
    setShowCountDown(true);
    await resendCode();
    isDisabled && setIsDisabled(false);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
  };

  const onTimerEnd = () => {
    setShowCountDown(false);
  };

  const onComplete = (sms) => {
    handleSms(sms);
    setAlertState({ type: '', message: '' });
  };

  return (
    <div className="authorization-form" id="form1">
      <PhoneIcon className="registration-icon" />
      <h1 className="authorization-title">{ i18n.getMessage('sms.title') }</h1>
      <SmsCodeWrapper
        PhoneComponent={ isRegistration ? 
          <button
            className="unset link-normal"
            onClick={ () => {
              registrationStore.changePhoneNumber();
            } }>
            { phone }
          </button> 
          :
          <strong>{ phone }</strong>
        }
        isDisabled={ isDisabled }
        error={ error }
        onComplete={ onComplete }
        showCountDown={ showCountDown }
        handleResendCode={ handleResendCode }
        onTimerEnd={ onTimerEnd }
        alertState={ alertState }
      />
      <BackButton onClick={ () => authStore.setIsLoginSuccess(false) } type={ 'login' } />
    </div>
  );
};

Sms.propTypes = {
  sms: PropTypes.string.isRequired,
  phone: PropTypes.string,
  handleSms: PropTypes.func.isRequired,
  resendCode: PropTypes.func.isRequired,
  error: PropTypes.any,
  isRegistration: PropTypes.bool
};

export default inject('authStore')(observer(Sms));
