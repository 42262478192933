import { useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';

import { getHeaderButtons } from 'components/common/MenuButtons';
import { PopUpLogOutConfirmationScheme } from 'components/common/PopUpScheme/PopUpLogOutConfirmationScheme';
import { IconButton } from 'uikit/IconButton/IconButton';
import { PopUp } from 'uikit/PopUp/PopUp';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { ReactComponent as PlusIcon } from 'assets/Subtract.svg';
import i18n from 'i18n';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../routes/constants';
import { USER_ACCOUNT_STATUSES } from '../constants';

const UserPanel = ({ onClick = () => {}, isMobile = false, userStore }) => {
  const navigate = useNavigate();
  const [ showLogOutPopUp, setShowLogOutPopUp ] = useState(false);

  const onHeaderClick = (id, buttonClick) => {
    if (id === 4) {
      return setShowLogOutPopUp(true);
    }
    // Fix for reloading page when we selecting Settings
    if (id === 2 && !isMobile) {
      return null;
    }
    onClick ? onClick(buttonClick) : buttonClick();
  };

  const getAvailableAccountsOptions = () =>
    userStore?.userData?.availableAccounts.map(account => ({
      key: account?.customer_account ?
        account?.customer_account?.account_number :
        account?.account_number,
      value: account?.customer_account ?
        `${account?.customer_account?.first_name} ${account?.customer_account?.last_name}` :
        account?.common_name
    }));

  const showHeaderDropDown =
    !!userStore?.userData?.availableAccounts?.filter(acc => acc.status === USER_ACCOUNT_STATUSES.VERIFIED);

  const handleChangeAccount = (name, value) => value === userStore?.userData?.account?.account_number ?
    null : userStore.changeCurrentUserAccount(value) && onClick();

  const handleCreateAccount = () => {
    navigate(ROUTE_PATHS.ACCOUNT_REGISTRATION);
  };

  const isDisabledButton = (button) => button.id === 2 && userStore.currentAccountPermission;

  return (
    <>
      <div className="account-dropdown-container">
        {
          showHeaderDropDown &&
            <InputDropDown
              className="account-dropdown"
              placeholder={ i18n.getMessage('multiAccountInputPlaceholder') }
              options={  getAvailableAccountsOptions() }
              specificOptions={ [{ key: 'open-acc',
                content: <div className="open-acc">
                  <PlusIcon /> <p>{ i18n.getMessage('button.openAnAccount') }</p>
                </div> }] }
              value={ userStore?.userData?.account?.account_number }
              onChange={ (name, key) => key === 'open-acc' ? handleCreateAccount() : handleChangeAccount(name,key) }
            />
        }
      </div>
      <div className="user-panel">
        { getHeaderButtons().map( button =>
          <IconButton
            key={ button.id }
            text={ button.text }
            onClick={ () => onHeaderClick(button.id, button.click) }
            path={ button.path }
            isDisabled={ !!isDisabledButton(button) }
            navigationType ={ button.id !== 4 }
            Icon={ button.icon }
          /> ) }
        <PopUp alignOnCenter show={ showLogOutPopUp }>
          <PopUpLogOutConfirmationScheme onClose={ () => setShowLogOutPopUp(false) } />
        </PopUp>
      </div>
    </>
  );
};

UserPanel.propTypes = {
  onClick: PropTypes.any,
  isMobile: PropTypes.bool,
  userStore: MobXPropTypes.observableObject
};

export default inject(stores => ({
  userStore: stores.userStore
}))(observer(UserPanel));
