import { useContext } from 'react';
import PropTypes from 'prop-types';

import i18nContext from 'components/i18n-context';
import { BackButton } from 'components/common/BackButton/BackButton';
import ComplexTranslate from 'components/ComplexTranslate';
import Alert from 'uikit/Alert/Alert';
import { errorState } from 'services/requestAgent';
import { ReactComponent as EmailIcon } from 'assets/Message_open.svg';

export const EmailConfirmation = ({ email, backToEmail }) => {
  const i18n = useContext(i18nContext);

  return (
    <section className="authorization-form email-confirmation">
      <EmailIcon className="registration-icon" />
      <Alert type={ 'warning' } message={ errorState.message } />
      <h1 className="authorization-title">{ i18n.getMessage('recovery.title.emailConfirmation') }</h1>
      <div className="sms-description">
        { i18n.getMessage('recovery.emailConfirmation.description', { email: email }) }
      </div>
      <div className="change-email-wrapper">
        <ComplexTranslate
          text={ 'recovery.emailConfirmation.description.changeEmail' }
          interpolation={ {
            changeEmail: <button
              onClick={ () => backToEmail() }
              className="unset link-normal" >{ i18n.getMessage('recovery.emailConfirmation.changeEmail') }</button>
          } } />
      </div>
      <BackButton />
    </section>
  );
};

EmailConfirmation.propTypes = {
  email: PropTypes.string,
  backToEmail: PropTypes.func.isRequired
};
