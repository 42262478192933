import { makeAutoObservable, runInAction } from 'mobx';
import {
  addRepresentative,
  removeRepresentative,
  updateRepresentative,
  getCandidateInfo
} from 'services/requestAgent';

class RepresentativeStore {
  isLoading = false;
  email = null;
  permissions = [];
  error = null;
  confirmationPopupError = null;
  isGetInfoSuccess = false;
  isAddRepresentativeSuccess = false;
  isUpdateRepresentativeSuccess = false;
  isDeleteRepresentativeSuccess = false;


  constructor() {
    makeAutoObservable(this);
  }

  resetStore() {
    this.isLoading = false;
    this.email = null;
    this.permissions = [];
    this.error = null;
    this.confirmationPopupError = null;
    this.isGetInfoSuccess = false;
    this.isAddRepresentativeSuccess = false;
    this.isUpdateRepresentativeSuccess = false;
    this.isDeleteRepresentativeSuccess = false;
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  setEmail(email) {
    this.email = email;
  }

  clearConfirmationPopupError() {
    this.confirmationPopupError = null;
  }

  async getAccountInfo(email) {
    try {
      this.setIsLoading(true);
      const data = await getCandidateInfo(email);
      runInAction(() => {
        this.isLoading = false;
        this.isGetInfoSuccess = true;
      });
      return data;
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      return null;
    }
  }

  async addNewRepresentative({ accountNumber, permissions, securityCode }) {
    try {
      this.setIsLoading(true);
      const data = await addRepresentative({ 
        accountNumber,
        email: this.email,
        permissions,
        securityCode
      });
      runInAction(() => {
        this.isLoading = false;
        this.isAddRepresentativeSuccess = true;
      });
      return data;
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async updateNewRepresentative({ accountNumber, id, permissions, securityCode }) {
    try {
      this.setIsLoading(true);
      const data = await updateRepresentative({
        accountNumber,
        id,
        permissions,
        securityCode
      });
      runInAction(() => {
        this.isLoading = false;
        this.isUpdateRepresentativeSuccess = true;
      });
      return data;
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async removeNewRepresentative({ accountNumber, id, securityCode }) {
    try {
      this.setIsLoading(true);
      const data = await removeRepresentative({ accountNumber, id, securityCode });
      runInAction(() => {
        this.isLoading = false;
        this.isDeleteRepresentativeSuccess = true;
      });
      return data;
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }
}

export default new RepresentativeStore();
