import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import PropTypes from 'prop-types';

const REFRESH_INTERVAL_SCALE = 500;

export const CountDown = ({ duration, onEnd }) => {

  const { getRemainingTime } = useIdleTimer({
    timeout: duration * 1000,
    stopOnIdle: true,
    startOnMount: true,
    events: []
  });

  const [remainingTime, setRemainingTime] = useState(getRemainingTime());

  useEffect(() => {
    if(remainingTime <= 0) {
      onEnd();
    }
  }, [remainingTime]);

  useEffect(() => {
    const interval = setInterval(() => setRemainingTime(getRemainingTime()), REFRESH_INTERVAL_SCALE);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const formatTime = time => {
    const minutes = parseInt(time / 60, 10);
    const seconds = parseInt(time % 60, 10);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };


  return (
    <span className="timer">
      { formatTime(remainingTime / 1000) }
    </span>
  );
};

CountDown.propTypes = {
  duration: PropTypes.number.isRequired,
  onEnd: PropTypes.func.isRequired
};
