import { replaceSpaces, strToFloat } from 'services/utils';

export default ({ providerTableData, data }) => ({
  sender_wallet: data.wallet && data.wallet?.wallet_number?.trim() || '',
  amount: strToFloat(providerTableData?.amount?.toString() || ''),
  currency: data.wallet && data.wallet?.currency?.trim() || '',
  transaction_recipient: {
    ...(providerTableData.recipient_type === 'Company' ? {
      company_name: providerTableData?.company_name?.trim() || ''
    } : {
      first_name: providerTableData?.first_name?.trim() || '',
      last_name: providerTableData?.last_name?.trim() || ''
    }),
    recipient_type: providerTableData.recipient_type.toUpperCase(),
    registration_number: providerTableData.registration_number,
    beneficiary_city: providerTableData.beneficiary_city,
    beneficiary_country: providerTableData.beneficiary_country,
    beneficiary_post_code: providerTableData.beneficiary_post_code,
    beneficiary_address: providerTableData.beneficiary_address,
    requisites: providerTableData?.recipient_wallet ? {
      iban: replaceSpaces(providerTableData?.recipient_wallet) || ''
    } : {
      bank_country: providerTableData?.bank_country?.trim(),
      bank_name: providerTableData?.bank_name?.trim(),
      account_number: providerTableData?.account_number?.trim(),
      bic: providerTableData?.bic?.trim()
    }
  },
  payment_method: data.paymentMethod || '',
  transfer_provider: data.wallet && data.wallet?.transfer_provider || '',
  comment: providerTableData?.comment?.trim() || '',
  documents: data.uploadedFiles ? data.uploadedFiles : []
});
