export const TIMEZONE = 'Europe/London';

const dateOpts = {
  timeZone: TIMEZONE,
  year: 'numeric',
  month: 'short',
  day: '2-digit'
};

const timeOpts = {
  timeZone: TIMEZONE,
  hour: '2-digit',
  minute: '2-digit'
};

export const parseDate = data => {
  const date = new Date(data);

  return {
    date: date.toLocaleString('en-UK', dateOpts),
    time: date.toLocaleString('en-UK', timeOpts) + ' GB'
  };
};

export const strToFloat = str => {
  const trimmed = str.trim().replace(/\.$/, '');
  const float = parseFloat(trimmed);

  return float === Number(trimmed) ?
    float :
    Number.NaN;
};

