import { useContext } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import i18nContext from 'components/i18n-context';
import ComplexTranslate from 'components/ComplexTranslate';
import Input from 'uikit/Input/Input';
import Button from 'uikit/Button/Button';
import { deleteNonLatin, replaceSpaces } from 'services/utils';
import { getPasswordValidation } from '../utils';


const BusinessRegistration = ({ registrationStore }) => {
  const i18n = useContext(i18nContext);

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      login: registrationStore.generalFields.login,
      password: registrationStore.generalFields.password
    },
    validationSchema: Yup.object({
      login: Yup.string()
        .nullable(true)
        .max(250, i18n.getMessage('error.Size'))
        .required(i18n.getMessage('register.error.emptyCompany')),
      password: getPasswordValidation()
    }),
    onSubmit: (values) => {
      registrationStore.setLogin(values.login.trim());
      registrationStore.setPassword(replaceSpaces(values.password));
      registrationStore.registerUser();
    }
  });

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    submitCount
  } = form;

  const serverFieldErrors = registrationStore?.error?.fields || {};

  return (
    <>
      <form
        action="#"
        id="send"
        onSubmit={ handleSubmit }
        className="form"
      >
        <Input
          type={ 'email_phone' }
          label={ i18n.getMessage('register.label.login') }
          placeholder={ i18n.getMessage('register.login.placeholder') }
          name={ 'login' }
          value={ deleteNonLatin(values.login) }
          onChange={ handleChange }
          initialStatus={ submitCount }
          autoFocus={ true }
          error={
            errors?.login ||
            serverFieldErrors?.login && i18n.getMessage(`error.${serverFieldErrors.login}`)
          }
        />
        <Input
          className="user"
          type={ 'password' }
          label={ i18n.getMessage('register.label.password') }
          placeholder={ i18n.getMessage('register.password.placeholder') }
          name={ 'password' }
          value={ replaceSpaces(values.password) }
          onChange={ handleChange }
          initialStatus={ submitCount }
          error={
            errors?.password ||
            serverFieldErrors?.password && i18n.getMessage(`error.${serverFieldErrors.password}`)
          }
          subText={ i18n.getMessage('register.password.subText', { amount: '7-30' }) }
        />
        <Button
          className="user"
          type={ 'primary' }
          roleType={ 'submit' }
          size={ 'large' }
          fullWidth={ true }
          onClick={ () => {} }
        >
          { i18n.getMessage('login.link.register') }
        </Button>
      </form>
      <p className="register-account-text">
        { i18n.getMessage('register.text.haveAccount') }
        <a href="/login" className="link-normal mx-1">{ i18n.getMessage('login.title') }</a>
      </p>
      <div className="register-createAccount-wrapper">
        <ComplexTranslate
          text={ 'register.text.creatingAccount' }
          interpolation={ {
            user: <a
              href={ process.env.REACT_APP_LINK_TERMS_AND_CONDITIONS }
              target="_blank"
              className="link-normal"
              rel="noreferrer">
              { i18n.getMessage('register.text.userAgreement') }
            </a>,
            privacy: <a
              href={ process.env.REACT_APP_LINK_PRIVACY_POLICY }
              target="_blank"
              className="link-normal"
              rel="noreferrer">
              { i18n.getMessage('register.text.userPrivacy') }
            </a>
          } } />
      </div>
    </>
  );
};

BusinessRegistration.propTypes = {
  registrationStore: MobXPropTypes.observableObject,
  error: PropTypes.any
};

export default inject('registrationStore')(observer(BusinessRegistration));
