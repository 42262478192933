import { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import { getPasswordValidation } from 'components/Registration/utils';
import { useFormik } from 'formik';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import * as Yup from 'yup';
import { getErrorMessageForAlert, replaceSpaces } from 'services/utils';
import Input from 'uikit/Input/Input';
import Button from 'uikit/Button/Button';
import Alert from 'uikit/Alert/Alert';
import './Settings.scss';

const OLD_PASSWORD_ERROR = 'INCORRECT_OLD_PASSWORD';
const SAME_PASSWORD_ERROR = 'PASSWORD_SAME_AS_OLD';
const PasswordReset = ({ passwordRecoveryStore }) => {
  const i18n = useContext(i18nContext);
  const storeAPIError = passwordRecoveryStore.error;

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      oldPassword: null,
      newPassword: null,
      repeatPassword: null
    },
    initialStatus: { password: true, username: true },
    validationSchema: Yup.object({
      oldPassword: getPasswordValidation()
        .required(i18n.getMessage('login.error.emptyFiled', { field: 'old password' })),
      newPassword: getPasswordValidation(),
      repeatPassword: Yup.string()
        .nullable(true)
        .required(i18n.getMessage('login.error.emptyFiled', { field: 'repeat password' }))
        .oneOf([Yup.ref('newPassword')], i18n.getMessage('error.passwordsDontMatch'))
    }),
    onSubmit: (values) => {
      passwordRecoveryStore.sendPasswordResetRequest({
        new_password: replaceSpaces(values.newPassword),
        old_password: replaceSpaces(values.oldPassword)
      });
    }
  });

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    submitCount
  } = form;

  const isAlertError = () => {
    return storeAPIError && ![OLD_PASSWORD_ERROR, SAME_PASSWORD_ERROR].includes(storeAPIError.code);
  };

  const getFieldError = (field, code) => {
    if (errors?.[field]) {
      return errors?.[field];
    }
    if (storeAPIError?.fields && storeAPIError.fields?.[field] || storeAPIError && storeAPIError?.code === code) {
      return getErrorMessageForAlert(i18n, storeAPIError);
    }
    return '';
  };

  return (
    <div className="settings-container-right">
      <form
        action="#"
        id="send"
        onSubmit={ handleSubmit }
        className="settings-input-wrapper">
        <Alert type={ 'info' } message={ i18n.getMessage('settings.form.text.passwordTips1') } />
        <Alert type={ isAlertError() ? 'warning' : 'info' }
          className={ 'settings-alert' }
          message={ isAlertError() ?
            getErrorMessageForAlert(i18n, storeAPIError)
            : i18n.getMessage('settings.form.text.passwordTips2') } />
        <Input
          name="oldPassword"
          type={ 'password' }
          label={ i18n.getMessage('settings.form.label.oldPassword') }
          className="settings-input"
          value={ replaceSpaces(values.oldPassword) }
          error={ getFieldError('oldPassword', OLD_PASSWORD_ERROR) }
          initialStatus={ submitCount }
          autoComplete={ 'off' }
          isRequired={ true }
          onChange={ handleChange }
          autoFocus={ true }
        />
        <Input
          name="newPassword"
          type={ 'password' }
          label={ i18n.getMessage('settings.form.label.newPassword') }
          className="settings-input"
          value={ replaceSpaces(values.newPassword) }
          error={ getFieldError('newPassword', SAME_PASSWORD_ERROR) }
          initialStatus={ submitCount }
          autoComplete={ 'off' }
          onChange={ handleChange }
          isRequired={ true }
        />
        <Input
          name="repeatPassword"
          type={ 'password' }
          label={ i18n.getMessage('settings.form.label.repeatPassword') }
          className="settings-input"
          value={ replaceSpaces(values.repeatPassword) }
          error={ errors?.repeatPassword || (storeAPIError?.fields && storeAPIError.fields.repeatPassword) }
          initialStatus={ submitCount }
          autoComplete={ 'off' }
          isRequired={ true }
          onChange={ handleChange }
        />
        <Button
          className="settings-input"
          type={ 'primary' }
          onClick={ () => {} }
          roleType={ 'submit' }
          size={ 'large' }
        >
          { i18n.getMessage('settings.btn.label.save') }
        </Button>
      </form>
    </div>
  );
};

PasswordReset.propTypes = {
  passwordRecoveryStore: MobXPropTypes.observableObject
};

export default inject( stores => ({
  passwordRecoveryStore: stores.passwordRecoveryStore
}))(observer(PasswordReset));
