import { useContext, useEffect } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';

import { Container } from 'uikit/Container/Container';
import i18nContext from 'components/i18n-context';
import Alert from 'uikit/Alert/Alert';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import Input from 'uikit/Input/Input';
import AmountInput from 'components/common/AmountInput';
import Button from 'uikit/Button/Button';
import { PopUp } from 'uikit/PopUp/PopUp';
import PopUpSuccessScheme from 'components/common/PopUpScheme/PopUpSuccessScheme';
import TransferConfirmationScheme from 'components/common/PopUpScheme/TransferConfirmationScheme';
import { CURRENCY_SYMBOLS } from 'components/common/constants';
import {
  amountFormattedValue,
  getErrorMessageForAlert,
  findWalletByCurrency,
  prepareFieldErrors
} from 'services/utils';

import './CurrencyExchange.scss';

const EXCHANGE_RATE_PRECISION = 4;

const CurrencyExchange = ({ userWallets, accountNumber, currencyExchangeStore, userStore }) => {
  const i18n = useContext(i18nContext);
  const getServerFieldsErrors = (variable) => prepareFieldErrors(i18n, currencyExchangeStore.error, variable);

  useEffect(() => {
    return () => {
      currencyExchangeStore.resetExchangeStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const walletFrom = findWalletByCurrency({ wallets: userWallets });
    const walletTo = findWalletByCurrency({ wallets: userWallets, currencyForExclude: walletFrom?.currency });
    currencyExchangeStore.setFromToWallets(walletFrom, walletTo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userWallets]);

  useEffect(() => {
    if(currencyExchangeStore.isExchangeCreateSuccess) {
      userStore.getUserWallets();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyExchangeStore.isExchangeCreateSuccess]);


  useEffect(() => {
    if(currencyExchangeStore.walletFrom && currencyExchangeStore.walletTo) {
      currencyExchangeStore.getExchangeRate();
      getCommission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyExchangeStore.walletFrom, currencyExchangeStore.walletTo]);

  const handleChangeWalletFrom = (name, value) => {
    const selectedWallet = userWallets.find(wallet => wallet.wallet_number === value);
    const walletTo = currencyExchangeStore.walletTo?.currency === selectedWallet.currency ?
      findWalletByCurrency({ wallets: userWallets, currencyForExclude: selectedWallet.currency }) :
      currencyExchangeStore.walletTo;
    currencyExchangeStore.setFromToWallets(
      selectedWallet,
      walletTo
    );
  };

  const handleChangeWalletTo = (name, value) => {
    const selectedWallet = userWallets.find(wallet => wallet.wallet_number === value);
    const walletFrom = currencyExchangeStore.walletFrom?.currency === selectedWallet.currency ?
      findWalletByCurrency({ wallets: userWallets, currencyForExclude: selectedWallet.currency }) :
      currencyExchangeStore.walletFrom;
    currencyExchangeStore.setFromToWallets(
      walletFrom,
      selectedWallet
    );
  };

  const handleAmountChange = ({ target: { name, value } }) => {
    currencyExchangeStore.setFromToAmount(name, value);
  };

  const getAvailableBalance = (wallet) =>
    wallet && i18n.getMessage('sendMoney.topLabel', {
      available: amountFormattedValue(wallet.available),
      currency: wallet.currency
    });

  const walletsOptions = userWallets?.map(wallet => {
    return {
      key: wallet.wallet_number,
      value: `${wallet.iban?.iban || wallet.multi_iban?.iban} ${wallet.currency}`
    };
  });

  const getCommission = async () => {
    if(currencyExchangeStore.amountFrom &&
      currencyExchangeStore.amountTo &&
      currencyExchangeStore.walletFrom &&
      currencyExchangeStore.walletTo 
    ) {
      await currencyExchangeStore?.exchangeCurrency(accountNumber, true);
    }
  };

  const isWalletsExist = currencyExchangeStore.walletFrom && currencyExchangeStore.walletTo;

  // eslint-disable-next-line max-len
  const commissionValue = `${amountFormattedValue(currencyExchangeStore?.exchangeData?.total_commissions)} ${currencyExchangeStore?.exchangeData?.currency_code || ''}`;

  return (
    <section className="right-section currency-exchange">
      <Container header={ i18n.getMessage('container.exchange') }>
        <div className="exchange-wrapper">
          <Alert
            type={ 'warning' }
            className="exchange-wrapper-alert"
            message={
              currencyExchangeStore?.error &&
                !currencyExchangeStore?.error?.type ? getErrorMessageForAlert(i18n, currencyExchangeStore.error) : ''
            }
          />
          <div className="exchange-inputs-wrapper">
            <InputDropDown
              isRequired={ true }
              name="walletFrom"
              topLabel={ getAvailableBalance(currencyExchangeStore.walletFrom) }
              value={ currencyExchangeStore.walletFrom?.wallet_number }
              options={ walletsOptions }
              onChange={ handleChangeWalletFrom }
              label={ i18n.getMessage('currencyExchange.form.from') }
              isMulti={ false }
            />
            <InputDropDown
              isRequired={ true }
              name="walletTo"
              topLabel={ getAvailableBalance(currencyExchangeStore.walletTo) }
              value={ currencyExchangeStore.walletTo?.wallet_number }
              options={ walletsOptions }
              onChange={ handleChangeWalletTo }
              label={ i18n.getMessage('currencyExchange.form.to') }
              isMulti={ false }
            />
            {
              !isWalletsExist &&
                <div>{ i18n.getMessage('transfer.provider.notFound') }</div>
            }
            {
              isWalletsExist &&
                <>
                  <Input
                    isRequired={ true }
                    value={ currencyExchangeStore.walletFrom?.currency }
                    label={ i18n.getMessage('currencyExchange.form.currency') }
                    isDisabled={ true }
                  />
                  <Input
                    className="exchange-input"
                    isRequired={ true }
                    label={ i18n.getMessage('currencyExchange.form.currency') }
                    value={ currencyExchangeStore.walletTo?.currency }
                    isDisabled={ true }
                  />
                  <AmountInput
                    className="exchange-input"
                    isRequired={ true }
                    label={ i18n.getMessage(
                      'currencyExchange.form.amountFrom',
                      { minimum: amountFormattedValue(currencyExchangeStore.exchangeRate?.min) }
                    ) }
                    name="amountFrom"
                    value={ currencyExchangeStore.amountFrom }
                    // eslint-disable-next-line max-len
                    subText={ `1${CURRENCY_SYMBOLS[currencyExchangeStore.walletFrom?.currency]} = ${amountFormattedValue(currencyExchangeStore.exchangeRate?.rate, EXCHANGE_RATE_PRECISION)}${CURRENCY_SYMBOLS[currencyExchangeStore.walletTo?.currency]}` }
                    onChange={ handleAmountChange }
                    placeholder={ '00.00' }
                    error={ getServerFieldsErrors(
                      amountFormattedValue(currencyExchangeStore.exchangeRate?.min)).amount
                    }
                    onBlur={ getCommission }
                  />
                  <AmountInput
                    className="exchange-input"
                    isRequired={ true }
                    label={ i18n.getMessage('currencyExchange.form.amountTo') }
                    // eslint-disable-next-line max-len
                    subText={ `1${CURRENCY_SYMBOLS[currencyExchangeStore.walletTo?.currency]} = ${amountFormattedValue(1 / currencyExchangeStore.exchangeRate?.rate, EXCHANGE_RATE_PRECISION)}${CURRENCY_SYMBOLS[currencyExchangeStore.walletFrom?.currency]}` }
                    name="amountTo"
                    value={ currencyExchangeStore.amountTo }
                    placeholder={ '00.00' }
                    onChange={ handleAmountChange }
                    onBlur={ getCommission }
                  />
                  <div className="exchange-rate input-wrapper disabled">
                    <label htmlFor={ 'your-rate' }>{ i18n.getMessage('currencyExchange.form.yourRate') }</label>
                    <div
                      name={ 'your-rate' }
                      className="input" >
                      <span>
                        { amountFormattedValue(currencyExchangeStore.exchangeRate?.rate, EXCHANGE_RATE_PRECISION) }
                      </span>
                      <span>{
                        `${currencyExchangeStore.exchangeRate?.from}/${currencyExchangeStore.exchangeRate?.to}`
                      }</span>
                    </div>
                  </div>
                  <Input
                    className="exchange-input"
                    label={ i18n.getMessage('currencyExchange.form.commission') }
                    value={ commissionValue }
                    isDisabled={ true }
                  />
                </>
            }
          </div>
          {
            isWalletsExist &&
              <div className="exchange-rate">
                
                <Button
                  type={ 'primary' }
                  roleType={ 'submit' }
                  size={ 'large' }
                  isDisabled={ 
                    currencyExchangeStore?.error ||
                    !currencyExchangeStore.amountFrom ||
                    !currencyExchangeStore.exchangeData ||
                    currencyExchangeStore.isCommissionLoading
                  }
                  onClick={ () => currencyExchangeStore?.exchangeCurrency(accountNumber) }
                >
                  { i18n.getMessage('currencyExchange.form.exchangeNow') }
                </Button>
              </div>
          }
        </div>
      </Container>
      <PopUp
        className={ currencyExchangeStore?.isExchangeCreateSuccess? 'transaction-success' : 'transaction-info' }
        show={ currencyExchangeStore?.isExchangeConfirmSuccess || currencyExchangeStore?.isExchangeCreateSuccess }
        alignOnCenter={ currencyExchangeStore?.isExchangeCreateSuccess }
        onClose={ currencyExchangeStore?.isExchangeConfirmSuccess ? () => currencyExchangeStore?.resetConfirmSuccess()
          : () => currencyExchangeStore?.resetCreateSuccess() }
      >
        {
          currencyExchangeStore?.isExchangeCreateSuccess ?
            <PopUpSuccessScheme
              onClose={ () => currencyExchangeStore.resetCreateSuccess() }
              message={ i18n.getMessage('popUp.message.moneyTransfer') }
            />
            :
            <TransferConfirmationScheme
              isCurrency={ true }
              transferData={ currencyExchangeStore?.exchangeData }
              error={ currencyExchangeStore?.confirmationPopupError }
              generateSecurityCode={ () => currencyExchangeStore.generateExchangeSecurityCode() }
              resendSecurityCode={ () => currencyExchangeStore.resendExchangeSecurityCode() }
              clearError={ () => currencyExchangeStore?.clearConfirmationPopupError() }
              onConfirm={ currencyExchangeStore?.confirmExchangeCurrency(accountNumber) }
              onClose={ () => currencyExchangeStore?.resetConfirmSuccess() }
            />
        }
      </PopUp>
    </section>
  );
};

CurrencyExchange.propTypes = {
  userWallets: PropTypes.array,
  accountNumber: PropTypes.string,
  currencyExchangeStore: MobXPropTypes.observableObject,
  userStore: MobXPropTypes.observableObject
};

export default inject(stores => ({
  userWallets: stores.userStore?.userWallets,
  accountNumber: stores.userStore.userData.account?.account_number,
  currencyExchangeStore: stores.currencyExchangeStore,
  userStore: stores.userStore
}))(observer(CurrencyExchange));
