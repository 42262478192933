import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import i18nContext from 'components/i18n-context';
import Button from 'uikit/Button/Button';
import { RECIPIENT_TYPES, SMS_ERROR_CODE_DISABLE } from 'components/common/constants';
import { SmsCodeWrapper } from 'components/common/SmsCodeWrapper/SmsCodeWrapper';
import { getSecureUserPhone } from 'services/authUtils';
import { amountFormattedValue, getErrorMessageForAlert, isPhoneConfirmationType } from 'services/utils';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';

import './PopUpScheme.scss';

const getTransferDetailsFields = (i18n, transferData, isCurrency = false) => {
  if (isCurrency) {
    return [
      {
        label: i18n.getMessage('currencyExchange.form.from'),
        value: transferData.iban
      },
      {
        label: i18n.getMessage('currencyExchange.form.sentAmount'),
        value: `${transferData.amount} ${transferData.source_currency_code}`
      },
      {
        label: i18n.getMessage('currencyExchange.form.to'),
        value: transferData.counterparty.iban
      },
      {
        label: i18n.getMessage('currencyExchange.form.receivedAmount'),
        value: `${transferData.target_amount} ${transferData.target_currency_code}`
      },
      {
        label: i18n.getMessage('currencyExchange.form.yourRate'),
        // eslint-disable-next-line max-len
        value: `${transferData?.applied_rate?.rate} ${transferData?.applied_rate?.source_currency}/${transferData?.applied_rate?.target_currency}`
      },
      {
        label: i18n.getMessage('transfer.confirmation.label.commission'),
        value: `${amountFormattedValue(transferData.total_commissions)} ${transferData.currency_code}`
      }
    ].filter(field => field.value || field.value === 0);
  }

  return [
    {
      label: i18n.getMessage('transfer.confirmation.label.amount'),
      value: `${amountFormattedValue(transferData.amount)} ${transferData.currency_code}`
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.commission'),
      value: `${amountFormattedValue(transferData.total_commissions)} ${transferData.currency_code}`
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.recipientName'),
      value: transferData.counterparty.name
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.recipientAccountNumber'),
      value: transferData.counterparty.iban
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.recipientType'),
      value: RECIPIENT_TYPES[transferData.recipient_type]
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.paymentMethod'),
      value: i18n.getMessage(`transfer.paymentMethod.${transferData.payment_method}`)
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.files'),
      value: transferData.uploadedFiles?.map(file => file.name).join(',\n ')
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.commentToTransfer'),
      value: transferData.comment
    }
  ].filter(field => field.value || field.value === 0);
};

const TransferConfirmationScheme = ({ 
  transferData,
  onClose,
  onConfirm,
  error,
  clearError,
  isCurrency,
  generateSecurityCode,
  resendSecurityCode
}) => {
  const i18n = useContext(i18nContext);
  const [alertState, setAlertState] = useState({ type: '', message: '' });
  const [showCountDown, setShowCountDown] = useState(false);
  const [securityCode, setSecurityCode] = useState('');
  const [showConfirmationCode, setShowConfirmationCode] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (SMS_ERROR_CODE_DISABLE.includes(error?.code)) {
      setIsDisabled(true);
    }
    error && setAlertState({ type: 'warning', message: getErrorMessageForAlert(i18n, error) });
  }, [error]);

  useEffect(() => {
    setShowCountDown(true);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
    return () => {
      clearError();
    };
  }, []);

  const handleResendCode = async() => {
    clearError();
    setShowCountDown(true);
    await resendSecurityCode();
    isDisabled && setIsDisabled(false);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
  };

  const onTimerEnd = () => {
    setShowCountDown(false);
  };

  const onComplete = (code) => {
    setSecurityCode(code);
    setAlertState({ type: '', message: '' });
  };

  const handleConfirm = () => {
    if(!showConfirmationCode) {
      setShowConfirmationCode(true);
      if(isPhoneConfirmationType()) {
        generateSecurityCode();
      }
      return;
    }
    onConfirm(securityCode);
  };

  const handleEdit = () => {
    if(showConfirmationCode) {
      setShowConfirmationCode(false);
      return;
    }
    onClose();
  };

  const schemeLine = (label, value, index) => {
    return <div key={ `${label}-${index}` } className="scheme-line">
      <p className="left">{ i18n.getMessage(label) + ':' }</p>
      <p className="center">{ value }</p>
    </div>;
  };

  return (
    <div className="scheme-wrapper transfer-confirmation">
      <div className="scheme-header">
        <p className="account">{ i18n.getMessage('popUp.paymentConfirmation') }</p>
        {
          showConfirmationCode &&
            <CloseIcon className="scheme-close-icon" onClick={ onClose } />
        }
      </div>
      <div className="scheme-line-wrapper">
        {
          getTransferDetailsFields(i18n, transferData, isCurrency).map(
            (field, index) => schemeLine(field.label, field.value, index)
          )
        }
      </div>
      {
        showConfirmationCode &&
          <SmsCodeWrapper
            PhoneComponent={
              <strong>{ getSecureUserPhone() }</strong>
            }
            isDisabled={ isDisabled }
            error={ !error?.type && error }
            onComplete={ onComplete }
            showCountDown={ showCountDown }
            handleResendCode={ handleResendCode }
            onTimerEnd={ onTimerEnd }
            alertState={ alertState }
          />
      }
      <div className="buttons-wrapper">
        {
          !showConfirmationCode &&
            <Button
              type={ 'outline' }
              size={ 'large' }
              onClick={ handleEdit }
            >
              { i18n.getMessage('transferConfirmation.button.editTransfer') }
            </Button>
        }
        <Button
          type={ 'primary' }
          size={ 'large' }
          roleType={ 'submit' }
          isDisabled={ ['CONFIRMATION_CODE_IS_OUT_OF_DATE', 'CONFIRMATION_CODE_TRIES_EXCEED'].includes(error?.code) }
          onClick={ handleConfirm }
        >
          { i18n.getMessage('transferConfirmation.button.confirm') }
        </Button>
      </div>
    </div>
  );
};

TransferConfirmationScheme.propTypes = {
  transferData: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  error: PropTypes.any,
  isCurrency: PropTypes.bool,
  generateSecurityCode: PropTypes.func.isRequired,
  resendSecurityCode: PropTypes.func.isRequired
};

export default TransferConfirmationScheme;

