/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import ApplicationLayout from 'components/EntryLayouts/ApplicationLayout';
import AuthorizationLayout from 'components/EntryLayouts/AuthorizationLayout';
import AccountRegistration from 'components/Registration/Account';
import UserRegistration from 'components/Registration/User';
import PasswordRecoveryStages from 'components/PasswordRecovery';

import LoginStages from 'components/Authorization';
import { getAccessExpiresOn, isTokenValid } from 'services/authUtils';
import { ElementWrapper } from 'routes/ElementWrapper';
import { ROUTE_PATHS, UNAUTHORIZED_ROUTE_PATHS, UNVERIFIED_ROUTE_PATHS } from 'routes/constants';
import { ACCOUNT_PERMISSIONS } from 'components/common/constants';
import Account from './Account/Account';
import DashBoard from './DashBoard/DashBoard';
import NewPayment from './NewPayment/NewPayment';
import { Transactions } from './Transactions/Transactions';
import Settings from './Settings/Settings';
import Profile from './Profile/Profile';
import { setTokenRefresh } from '../services/requestAgent';
import CurrencyExchange from './CurrencyExchange/CurrencyExchange';
import Fees from './Fees/Fees';
import { NotFound } from './common/NotFound/NotFound';
import i18nContext from './i18n-context';
import Representatives from './Representatives/index';


function App({ userStore }) {
  const navigate = useNavigate();
  const location = useLocation();
  const i18n = useContext(i18nContext);

  useEffect(() => {
    if (isTokenValid()) {
      const expiresOn = getAccessExpiresOn();
      setTokenRefresh((expiresOn - Date.now()) / 1000);

      // Fetch initial data
      if(!Object.keys(userStore.userData).length) {
        userStore.loadUserData();
        userStore.getAppConstants();
      }
    }

    // Need to do some logic (API) to check user authorization status
    if(
      !isTokenValid() &&
      !Object.keys(UNAUTHORIZED_ROUTE_PATHS).filter(path =>
        location.pathname.includes(UNAUTHORIZED_ROUTE_PATHS[path])
      ).length
    ) {
      navigate(ROUTE_PATHS.LOGIN);
    }

    // Redirection / -> /dashboard
    if(location.pathname === ROUTE_PATHS.ROOT) {
      navigate(ROUTE_PATHS.DASHBOARD);
    }
  }, [navigate, location.pathname]);

  useEffect(() => {
    /* Redirection for unverified account. 
    *  All routes (except UNVERIFIED_ROUTE_PATHS, UNAUTHORIZED_ROUTE_PATHS ) will be redirect to /dashboard
    */

    if(userStore.isAccountVerified !== null &&
      !userStore.isAccountVerified &&
      !Object.values({ ...UNVERIFIED_ROUTE_PATHS, ...UNAUTHORIZED_ROUTE_PATHS }).includes(location.pathname)) {
      navigate(ROUTE_PATHS.DASHBOARD);
    }
  }, [navigate, location.pathname, userStore.isAccountVerified]);

  // Without any permissions access will be block for all shared accounts
  const isUserHasPermission = (expectedPermissions = []) => {
    const userPermissions = userStore?.currentAccountPermission;
    // When we don't have permission in account object -> main account
    if(!userPermissions) {
      return true;
    }
    return !!userPermissions?.filter(permission => 
      expectedPermissions.includes(permission)).length;
  };

  return (
    <Routes>
      <Route path={ ROUTE_PATHS.ROOT } element={ <ApplicationLayout/> }>
        <Route
          path={ ROUTE_PATHS.DASHBOARD }
          element={ 
            <ElementWrapper
              isHasPermission={ isUserHasPermission([ACCOUNT_PERMISSIONS.READ_ACCOUNT]) }
              component={ DashBoard }
              headerText={ i18n.getMessage('menu.dashboard.text') }
            /> 
          } 
        />
        <Route 
          path={ ROUTE_PATHS.SETTINGS }
          element={ 
            <ElementWrapper 
              isHasPermission={ isUserHasPermission() }
              component={ Settings }
              headerText={ i18n.getMessage('navigation.settings.text') }
            /> 
          } 
        />
        <Route 
          path={ ROUTE_PATHS.PROFILE }
          element={ 
            <ElementWrapper
              isHasPermission={ isUserHasPermission() }
              component={ Profile }
              headerText={ i18n.getMessage('navigation.profile.text') }
            /> 
          } 
        />
        {
          userStore.isAccountVerified &&
            <>
              <Route 
                path={ ROUTE_PATHS.NEW_PAYMENT } 
                element={ 
                  <ElementWrapper
                    isHasPermission={ isUserHasPermission([ACCOUNT_PERMISSIONS.CREATE_TRANSACTION]) }
                    component={ NewPayment }
                    headerText={ i18n.getMessage('container.sendMoney') }
                  /> 
                } 
              />
              <Route 
                path={ ROUTE_PATHS.CURRENCY_EXCHANGE } 
                element={ 
                  <ElementWrapper
                    isHasPermission={ isUserHasPermission([ACCOUNT_PERMISSIONS.CREATE_CURRENCY_EXCHANGE]) }
                    component={ CurrencyExchange }
                    headerText={ i18n.getMessage('container.exchange') }
                  /> 
                } 
              />
              <Route 
                path={ ROUTE_PATHS.ACCOUNT } 
                element={ 
                  <ElementWrapper
                    isHasPermission={ isUserHasPermission([ACCOUNT_PERMISSIONS.READ_ACCOUNT]) }
                    component={ Account }
                    headerText={ i18n.getMessage('container.accounts') }
                  /> 
                } 
              />
              <Route 
                path={ ROUTE_PATHS.TRANSACTIONS } 
                element={ 
                  <ElementWrapper
                    isHasPermission={ isUserHasPermission([
                      ACCOUNT_PERMISSIONS.READ_ACCOUNT,
                      ACCOUNT_PERMISSIONS.CREATE_REPORT
                    ]) }
                    component={ Transactions }
                    headerText={ i18n.getMessage('container.Transactions') }
                  /> 
                } 
              />
            </>
        }

        <Route
          path={ ROUTE_PATHS.FEES }
          element={ 
            <ElementWrapper
              isHasPermission={ isUserHasPermission() }
              component={ Fees }
              headerText={ i18n.getMessage('container.fees') }
            /> 
          } 
        />
        <Route
          path={ ROUTE_PATHS.REPRESENTATIVES }
          element={
            <ElementWrapper
              isHasPermission={ isUserHasPermission() }
              component={ Representatives }
              headerText={ i18n.getMessage('container.representatives') }
            />
          }
        />
      </Route>
      <Route path={ ROUTE_PATHS.PASSWORD_RECOVERY } element={ <ElementWrapper component={ AuthorizationLayout }/> }>
        <Route index element={ <ElementWrapper component={ PasswordRecoveryStages }/> }/>
        <Route 
          path={ 'email_confirmation/:tokenId' }
          element={ <ElementWrapper component={ PasswordRecoveryStages }/> }
        />
      </Route>
      <Route path={ ROUTE_PATHS.LOGIN } element={ <AuthorizationLayout/> }>
        <Route index element={ <ElementWrapper component={ LoginStages }/> }/>
      </Route>
      <Route path={ ROUTE_PATHS.REGISTRATION } element={ <AuthorizationLayout/> }>
        <Route index element={ <ElementWrapper component={ UserRegistration }/> }/>
      </Route>
      <Route path={ ROUTE_PATHS.ACCOUNT_REGISTRATION } element={ <AuthorizationLayout/> }>
        <Route index element={ <ElementWrapper component={ AccountRegistration }/> }/>
      </Route>
      {
        userStore.isAccountVerified &&
          <Route path="*" element={ <NotFound /> }/>
      }
    </Routes>
  );
}

App.propTypes = {
  userStore: MobXPropTypes.observableObject
};

export default inject('userStore')(observer(App));
