/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import PersonalRegistration from './Personal';
import BusinessRegistration from './Business';
import VerificationCode from './VerificationCode';
import { ACCOUNT_TYPE, REGISTRATION_ACCOUNT_TYPE, REGISTRATION_STATUSES } from 'components/common/constants';
import i18nContext from 'components/i18n-context';
import { RadioGroup } from 'uikit/RadioGroup/RadioGroup';
import Alert from 'uikit/Alert/Alert';
import { getErrorMessageForAlert } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';

import '../RegistrationStyles.scss';
import { PopUp } from 'uikit/PopUp/PopUp';
import PopUpSuccessScheme from 'components/common/PopUpScheme/PopUpSuccessScheme';

const Registration = ({ registrationStore, userStore }) => {
  const navigate = useNavigate();
  const i18n = useContext(i18nContext);

  useEffect(() => {
    registrationStore.clearError();
  }, [registrationStore.registrationStatus]);

  const handleChangeRegistrationType = (type) => {
    registrationStore.setRegistrationType(type);
  };


  const onPopUpClose = async() => {
    registrationStore.reset();
    await userStore.loadUserData();
    navigate(`${ROUTE_PATHS.DASHBOARD}`);
  };

  switch(registrationStore.registrationStatus) {
  case REGISTRATION_STATUSES.REGISTRATION_STATUS_EMAIL_CONFIRMATION:
    return <VerificationCode/>;
  case REGISTRATION_STATUSES.REGISTRATION_STATUS_CONFIRMED: {
    return <PopUp show={ true }>
      <PopUpSuccessScheme onClose={ onPopUpClose } message={ i18n.getMessage('popUp.success.registration') } />
    </PopUp>;
  }
  default:
    return <div className="registration-form" id="form1">
      <h1 className="registration-title"> { i18n.getMessage('register.title.createAccount') } </h1>
      <RadioGroup
        classes={ 'registration-radio-group' }
        radioProps={
          [{
            label: 'Personal',
            value: i18n.getMessage('account.label.personal'),
            checked: registrationStore.registrationType === REGISTRATION_ACCOUNT_TYPE.PERSONAL
          }, {
            label: 'Business',
            value: i18n.getMessage('account.label.business'),
            checked: registrationStore.registrationType === REGISTRATION_ACCOUNT_TYPE.BUSINESS
          }]
        }
        checkedValue={ registrationStore.registrationType }
        disableLeft={ !!userStore?.userData?.availableAccounts?.filter(acc => acc.type === ACCOUNT_TYPE.INDIVIDUAL) }
        onChange={ handleChangeRegistrationType }/>
      <Alert
        type={ 'warning' }
        message={
          registrationStore?.error && getErrorMessageForAlert(i18n, registrationStore.error)
        }
      />
      <div className="form-data">
        {
          registrationStore.registrationType === REGISTRATION_ACCOUNT_TYPE.PERSONAL ?
            <PersonalRegistration /> :
            <BusinessRegistration />
        }
      </div>
    </div>;
  }
};

Registration.propTypes = {
  registrationStore: MobXPropTypes.observableObject,
  userStore: MobXPropTypes.observableObject
};

export default inject(stores => ({
  userStore: stores.userStore,
  registrationStore: stores.registrationStore
}))(observer(Registration));
