import { useContext } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import ComplexTranslate from 'components/ComplexTranslate';
import i18nContext from 'components/i18n-context';
import Input from 'uikit/Input/Input';
import Button from 'uikit/Button/Button';
import { deleteNonLatin, emailRegExp, replaceSpaces } from 'services/utils';

const PersonalRegistration = ({ registrationStore }) => {
  const i18n = useContext(i18nContext);

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      name: registrationStore.personalFields.firstName,
      surname: registrationStore.personalFields.surname,
      email: null
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .nullable(true)
        .max(250, i18n.getMessage('error.Size'))
        .required(i18n.getMessage('register.error.emptyName')),
      surname: Yup.string()
        .nullable(true)
        .max(250, i18n.getMessage('error.Size'))
        .required(i18n.getMessage('register.error.emptySurname')),
      email: Yup.string()
        .nullable(true)
        .max(50, i18n.getMessage('error.emailSize'))
        .trim()
        .matches(
          emailRegExp,
          { message: i18n.getMessage('register.error.invalidEmail') }
        )
        .required(i18n.getMessage('register.error.emptyEmail'))
    }),
    onSubmit: (values) => {
      registrationStore.setFirstName(values.name.trim());
      registrationStore.setSurname(values.surname.trim());
      registrationStore.setEmail(replaceSpaces(values.email));
      registrationStore.registerPersonalAccount();
    }
  });

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    submitCount
  } = form;

  const serverFieldErrors = registrationStore?.error?.fields || {};

  return (
    <>
      <form
        action="#"
        id="send"
        onSubmit={ handleSubmit }
        className="form"
      >
        <div className="register-name-wrapper">
          <Input
            type={ 'email_phone' }
            label={ i18n.getMessage('register.label.firstName') }
            placeholder={ i18n.getMessage('register.firstName.placeholder') }
            name={ 'name' }
            value={ deleteNonLatin(values.name) }
            onChange={ handleChange }
            error={
              errors?.name ||
              serverFieldErrors?.name && i18n.getMessage(`error.${serverFieldErrors.name}`)
            }
            initialStatus={ submitCount }
          />
          <Input
            type={ 'email_phone' }
            label={ i18n.getMessage('register.label.lastName') }
            placeholder={ i18n.getMessage('register.lastName.placeholder') }
            name={ 'surname' }
            value={ deleteNonLatin(values.surname) }
            onChange={ handleChange }
            error={
              errors?.surname ||
              serverFieldErrors?.surname && i18n.getMessage(`error.${serverFieldErrors.surname}`)
            }
            initialStatus={ submitCount }
          />
        </div>
        <Input
          type={ 'email_phone' }
          label={ i18n.getMessage('register.label.email') }
          placeholder={ i18n.getMessage('register.email.placeholder') }
          name={ 'email' }
          value={ replaceSpaces(values.email) }
          onChange={ handleChange }
          autoFocus={ true }
          error={
            errors?.email ||
            serverFieldErrors?.email && i18n.getMessage(`error.${serverFieldErrors.email}`)
          }
          initialStatus={ submitCount }
        />
        <Button
          type={ 'primary' }
          roleType={ 'submit' }
          size={ 'large' }
          fullWidth={ true }
          onClick={ () => {} }
        >
          { i18n.getMessage('login.link.register') }
        </Button>
      </form>
      <div className="register-createAccount-wrapper">
        <ComplexTranslate
          text={ 'register.text.creatingAccount' }
          interpolation={ {
            user: <a
              href={ process.env.REACT_APP_LINK_TERMS_AND_CONDITIONS }
              target="_blank"
              className="link-normal"
              rel="noreferrer">
              { i18n.getMessage('register.text.userAgreement') }
            </a>,
            privacy: <a
              href={ process.env.REACT_APP_LINK_PRIVACY_POLICY }
              target="_blank"
              className="link-normal"
              rel="noreferrer">
              { i18n.getMessage('register.text.userPrivacy') }
            </a>
          } } />
      </div>
    </>
  );
};

PersonalRegistration.propTypes = {
  registrationStore: MobXPropTypes.observableObject
};

export default inject('registrationStore')(observer(PersonalRegistration));
