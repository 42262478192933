export function classNames () {
  const hasOwn = {}.hasOwnProperty;
  const classes = [];

  for (let argument of arguments) {
    if (!argument) continue;

    const argType = typeof argument;

    if (argType === 'string' || argType === 'number') {
      classes.push(argument);
    }
    if (Array.isArray(argument) && argument.length) {
      const inner = classNames.apply(null, argument);
      if (inner) {
        classes.push(inner);
      }
    }
    if (argType === 'object') {
      for (let key in argument) {
        if (key === 'undefined') continue;
        if (hasOwn.call(argument, key) && argument[key]) {
          classes.push(key);
        }
      }
    }

    return classes.join(' ');
  }
}
