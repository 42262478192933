import { useContext } from 'react';
import i18nContext from '../../i18n-context';
import './NoOutlet.scss';
import { ReactComponent as NotFoundIcon } from 'assets/notFound.svg';
import { ReactComponent as DeclinedIcon } from 'assets/Declined.svg';
import Button from 'uikit/Button/Button';
import PropTypes from 'prop-types';
import { ReactComponent as PlusIcon } from 'assets/Plus.svg';
import { ROUTE_PATHS } from '../../../routes/constants';
import { useNavigate } from 'react-router-dom';

const NOT_FOUND = 'ACCOUNT_NOT_FOUND';
const KYC_DECLINED = 'DECLINED';
const NO_ACCOUNT = 'ACCOUNT_NOT_REGISTERED';

const NoOutlet = ({
  error
}) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const outletState = {
    icon : <DeclinedIcon />,
    label : i18n.getMessage('noOutlet.declined.label'),
    description : i18n.getMessage('noOutlet.declined.description')
  };

  if(!error) {
    return null;
  }

  if(error?.code === NOT_FOUND) {
    outletState.icon = <NotFoundIcon />;
    outletState.label = i18n.getMessage('noOutlet.notFound.label');
    outletState.description = i18n.getMessage('noOutlet.notFound.description');
  }

  if(error?.code === KYC_DECLINED) {
    outletState.icon = <DeclinedIcon />;
    outletState.label = i18n.getMessage('noOutlet.declined.label');
    outletState.description = i18n.getMessage('noOutlet.declined.description');
  }

  if(error?.code === NO_ACCOUNT) {
    return <div className="verification">
      <PlusIcon className="corporate-icon" />
      <div className="bank-wrapper">
        <h1 className="bank-header account">
          { i18n.getMessage('noOutlet.accountNotRegistered.label') }
        </h1>
        <Button
          type={ 'primary' }
          roleType={ 'submit' }
          size={ 'large' }
          onClick={ () => navigate(ROUTE_PATHS.ACCOUNT_REGISTRATION) }
        >
          { i18n.getMessage('button.openAnAccount') }
        </Button>
      </div>
    </div>;
  }

  return (
    <section className="right-section no-outlet">
      <div className="wrapper">
        <div className="no-outlet__left-side">{ outletState.icon }</div>
        <div className="no-outlet__right-side">
          <h2 className="no-outlet__label">{ outletState.label }</h2>
          <p className="no-outlet__description">{ outletState.description }</p>
          <Button
            size="large"
            onClick={ () => location.href='mailto:support@finmatic.net' }
            className="no-outlet__button"
          >
            { i18n.getMessage('noOutlet.button.text') }
          </Button>
        </div>
      </div>
    </section>
  );
};

NoOutlet.propTypes = {
  error: PropTypes.object
};

export default NoOutlet;
