import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as BankIcon } from '../../assets/bank-icon.svg';
import Button from '../../uikit/Button/Button';
import i18nContext from '../i18n-context';

export const Individual = ({ isUnderReview, handleOpenVerification }) => {
  const i18n = useContext(i18nContext);
  return (
    <div className="verification">
      <BankIcon className="bank-icon" />
      <div className="bank-wrapper">
        <h1 className="bank-header">{
          isUnderReview ?
            i18n.getMessage('verification.kyc.underReview.title') :
            i18n.getMessage('verification.kyc.new.title')
        }</h1>
        <p className="bank-description">{
          isUnderReview ?
            i18n.getMessage('verification.kyc.underReview.description') :
            i18n.getMessage('verification.kyc.new.description')
        }</p>
        <Button
          type={ 'primary' }
          roleType={ 'submit' }
          className={ isUnderReview ? 'btn-size' : '' }
          size={ 'large' }
          onClick={ () => handleOpenVerification('basic-kyc-level') }
        >
          {
            isUnderReview ?
              i18n.getMessage('button.continueVerification') :
              i18n.getMessage('button.beginVerification')
          }
        </Button>
      </div>
    </div>
  );
};

Individual.propTypes = {
  handleOpenVerification: PropTypes.func.isRequired,
  isUnderReview: PropTypes.bool
};
