import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'uikit/Button/Button';
import i18nContext from '../i18n-context';
import Input from 'uikit/Input/Input';
import { prepareFieldErrors } from 'services/utils';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';

export const AddRepresentativeScheme = ({ addNewClick, closePopUp, error }) => {
  const i18n = useContext(i18nContext);
  const [email, setEmail] = useState('');
  const serverFieldsErrors = prepareFieldErrors(i18n, error);

  return (
    <div className="scheme-wrapper add-representatives">
      <div className="representatives-add-header-wrapper">
        <h2 className="representatives-popUp-header">
          { i18n.getMessage('representatives.label.addNewRepresentative') }
        </h2>
        <CloseIcon className="representatives-icon" onClick={ closePopUp } />
      </div>
      <Input
        className="representatives-email"
        label={ i18n.getMessage('representatives.header.email') }
        value={ email }
        onChange={ e => setEmail(e.target.value) }
        error={ serverFieldsErrors.email }
        placeholder="Name@example.com"
      />
      <Button
        size="large"
        fullWidth={ true }
        onClick={ () => addNewClick(email) }
      >
        { i18n.getMessage('representatives.label.next') }
      </Button>
    </div>
  );
};

AddRepresentativeScheme.propTypes = {
  addNewClick: PropTypes.func,
  closePopUp: PropTypes.func,
  error: PropTypes.any
};

