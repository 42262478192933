import { useContext, useState } from 'react';
import './Account.scss';
import { Container } from 'uikit/Container/Container';
import i18nContext from '../i18n-context';
import { Card } from 'uikit/Card/Card';
import { inject, observer,  PropTypes as MobXPropTypes } from 'mobx-react';
import PopUpScheme from 'components/common/PopUpScheme/PopUpScheme';
import { PopUp } from 'uikit/PopUp/PopUp';
import { WALLET_PROPERTIES, WALLET_TOOLTIP_TEXT } from 'components/common/constants';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import paymentStore from '../../stores/paymentStore';

const Account = ({ userWallets, transactionsStore }) => {
  const i18n = useContext(i18nContext);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const navigate = useNavigate();
  const [popUpState, setPopUpState] = useState({ open: false, account: {} });

  const showPopup = (account) => {
    setPopUpState({ open: true, account: account });
  };

  const closePopUp = () => {
    setPopUpState({ open: false, account: {} });
  };

  const goToTransaction = (wallet) => {
    transactionsStore.setWallet(wallet);
    navigate('/transactions');
  };

  const goToPayment = (wallet) => {
    paymentStore. setSelectedWallet(wallet);
    navigate('/new-payment');
  };
  
  const mobileText = {
    account: i18n.getMessage('accounts.iban'),
    currency: i18n.getMessage('accounts.currency'),
    reserved: i18n.getMessage('accounts.reserved'),
    balance: i18n.getMessage('accounts.availableBalance'),
    actions: i18n.getMessage('accounts.actions')
  };

  return (
    <section className="right-section accounts">
      <Container className="accounts-container" header={ i18n.getMessage('container.accounts') }>
        <div className="accounts-list">
          { !isMobile &&
            <div className="accounts-head">
              <p className="head-accounts">{ i18n.getMessage('accounts.iban') }</p>
              <p className="head-currency">{ i18n.getMessage('accounts.currency') }</p>
              <p className="head-reserved">{ i18n.getMessage('accounts.reserved') }</p>
              <p className="head-available">{ i18n.getMessage('accounts.availableBalance') }</p>
              <p className="head-actions">{ i18n.getMessage('accounts.actions') }</p>
            </div>
          }
          
          { userWallets?.map(wallet => <Card
            key={ wallet[ WALLET_PROPERTIES.WALLET_NUMBER ] }
            type={ 'list' }
            account={ wallet[ WALLET_PROPERTIES.IBAN ]?.iban || wallet[ WALLET_PROPERTIES.MULTI_IBAN ]?.iban }
            balance={ wallet[ WALLET_PROPERTIES.AVAILABLE ] }
            currency={ wallet[ WALLET_PROPERTIES.CURRENCY ] }
            reserved={ wallet[ WALLET_PROPERTIES.ON_HOLD ] }
            mobileText={ mobileText }
            onExclaimPointClick={ () => showPopup(wallet) }
            onPaymentClick={ () => goToPayment(wallet) }
            onTransactionClick={ () => goToTransaction(wallet) }
            tooltipText={ WALLET_TOOLTIP_TEXT }
          />
          ) }
        </div>
        <PopUp className="account-info" show={ popUpState.open } onClose={ closePopUp }>
          <PopUpScheme activeWallet={ popUpState.account } onClose={ closePopUp } />
        </PopUp>
      </Container>
    </section>
  );
};

Account.propTypes = {
  transactionsStore: MobXPropTypes.observableObject,
  paymentStore: MobXPropTypes.observableObject,
  userWallets: PropTypes.array
};


export default inject(stores => ({
  userWallets: stores.userStore?.userWallets,
  transactionsStore: stores.transactionsStore,
  paymentStore: stores.paymentStore
}))(observer(Account));
