import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { ReactComponent as CalendarIcon } from 'assets/calendar-icon.svg';

export const TransactionDateFilter = ({ name, onChange, value, label }) => {

  const pickDate = (date) => {
    onChange( name, date);
  };

  return (
    <div className={ `datepicker date ${ value ? 'selected' : '' }` }>
      <label htmlFor={ name }>{ label }</label>
      <DatePicker
        showMonthDropdown={ true }
        className="date-input"
        dateFormat="dd/MM/yyyy"
        name={ name }
        id={ name }
        placeholderText="dd/mm/yyyy"
        selected={ value }
        onChange={ (date) => pickDate(date) }
      />
      <CalendarIcon className="date-icon" />
    </div>
  );
};

TransactionDateFilter.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string
};
