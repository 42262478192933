import { makeAutoObservable, runInAction } from 'mobx';
import {
  transactionPaymentCheck,
  transactionPaymentConfirm,
  uploadDocuments,
  internalIbanCheck, resendSecurityCode, generateSecurityCode
} from 'services/requestAgent';

const  FIFTEEN_MEGABYTES_IN_BYTES = 15 * 1024 * 1024;
class PaymentStore {
  isLoading = false;
  isCommissionLoading = false;
  isIbanCheckLoading = false;
  isFileUploading = false;
  error = null;
  confirmationPopupError = null;
  currentWallet = null;
  providerData = {};
  paymentMethod = null;
  uploadedFiles = [ ];
  isInternalIban = null;
  commission = {
    value: '',
    currency: '',
    type: ''
  };
  transactionForCreate = null;
  serverTransactionData = null;
  isTransactionConfirmation = false;
  isSuccess = false;
  previousTransactionInfo = null;

  constructor() {
    makeAutoObservable(this);
  }

  resetPaymentStore() {
    this.isLoading = false;
    this.isCommissionLoading = false;
    this.isFileUploading = false;
    this.error = null;
    this.providerData = {};
    this.paymentMethod = null;
    this.uploadedFiles = [ ];
    this.commission = {
      value: '',
      currency: '',
      type: ''
    };
    this.transactionForCreate = null;
    this.serverTransactionData = null;
    this.isTransactionConfirmation = false;
    this.isSuccess = false;
    this.previousTransactionInfo = null;
    this.confirmationPopupError = null;
    this.currentWallet = null;
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  clearConfirmationPopupError() {
    this.confirmationPopupError = null;
  }

  setIsCommissionLoading(status) {
    this.isCommissionLoading = status;
    this.error = null;
  }

  setIsFileUploading(status) {
    this.isFileUploading = status;
    this.error = null;
  }

  setIsIbanCheckLoading(status) {
    this.isIbanCheckLoading = status;
    this.error = null;
  }

  setIsTransactionConfirmation(isConfirmation) {
    this.isTransactionConfirmation = isConfirmation;
  }

  setSelectedWallet(wallet) {
    this.currentWallet = wallet;
  }

  setPreviousTransactionInfo(transaction) {
    this.previousTransactionInfo = transaction;
  }

  setProviderData(providerData) {
    this.providerData = providerData;
  }

  resetSuccess() {
    this.isSuccess = false;
  }

  removePaymentFile(fileId) {
    this.uploadedFiles = this.uploadedFiles.filter(file => file?.id !== fileId);
  }
  
  uploadDocuments(accountNumber) {
    return async({ target: { files } }) => {
      if(!Object.keys(files).length) {
        return null;
      }
      this.setIsFileUploading(true);
      try {
        const selectedFiles = [...files];
        const Data = new FormData();
        selectedFiles.forEach( file => {
          if(file.size > FIFTEEN_MEGABYTES_IN_BYTES ) {
            throw { code: 'REQUEST_HAS_BEEN_TERMINATED' };
          } else {
            Data.append('file', file, file?.name);
          }
        });

        const documentArray = await uploadDocuments(accountNumber, Data);

        runInAction(() => {
          this.isFileUploading = false;
          this.uploadedFiles = [
            ...this.uploadedFiles,
            ...documentArray.map( file => ({ name: file?.name, id: file?.id }))
          ];
        });
      } catch (err) {
        runInAction(() => {
          this.isFileUploading = false;
          this.error = { type: 'attachDoc', ...err };
        });
      }
    };
  }

  async getCommission(accountNumber, data, formType) {
    this.setIsCommissionLoading(true);
    try {
      const transactionData = await transactionPaymentCheck(accountNumber, data, formType);
  
      runInAction(() => {
        this.isCommissionLoading = false;
        this.paymentMethod = transactionData.payment_method;
        this.commission = {
          value: transactionData.total_commissions,
          currency: transactionData.currency_code,
          type: transactionData.commission_type
        };
      });
    } catch (err) {
      runInAction(() => {
        this.isCommissionLoading = false;
        this.error = err;
      });
    }
  }

  async generatePaymentSecurityCode() {
    try {
      await generateSecurityCode();
    } catch (err) {
      runInAction(() => {
        this.confirmationPopupError = err;
      });
    }
  }

  async resendPaymentSecurityCode() {
    try {
      await resendSecurityCode();
    } catch (err) {
      runInAction(() => {
        this.confirmationPopupError = err;
      });
    }
  }

  async validateTransfer({ accountNumber, providerData, model }, formType) {
    this.setIsLoading(true);
    const data = model({
      providerTableData: providerData,
      data: {
        wallet: this.currentWallet,
        paymentMethod: this.paymentMethod,
        uploadedFiles: this.uploadedFiles.map( file => file.id )
      }
    });

    try {
      const transactionData = await transactionPaymentCheck(accountNumber, data, formType);
  
      runInAction(() => {
        this.isLoading = false;
        this.isTransactionConfirmation = true;
        this.paymentMethod = transactionData.payment_method;
        this.transactionForCreate = {
          ...data,
          source_amount: transactionData.source_amount,
          target_amount: transactionData.target_amount
        };
        this.serverTransactionData = transactionData;
        this.commission = {
          value: transactionData.total_commissions,
          currency: transactionData.currency_code,
          type: transactionData.commission_type
        };
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  createTransfer(accountNumber, activeTab) {
    return async (securityCode) => {
      this.setIsLoading(true);
      try {
        await transactionPaymentConfirm(accountNumber, {
          securityCode,
          ...this.transactionForCreate
        }, activeTab
        );
    
        runInAction(() => {
          this.isLoading = false;
          this.isTransactionConfirmation = false;
          this.isSuccess = true;
          this.uploadedFiles = [];
          this.paymentMethod = null;
          this.previousTransactionInfo = null;
        });
      } catch (err) {
        runInAction(() => {
          this.isLoading = false;
          this.confirmationPopupError = err;
        });
      }
    };
  }

  async validateIban (iban, currentProviderType, accountNumber ) {
    this.setIsIbanCheckLoading(true);
    try {
      const internalIban = await internalIbanCheck(accountNumber, iban);

      runInAction(() => {
        this.isIbanCheckLoading = false;
        this.isInternalIban = internalIban?.payment_provider === currentProviderType;
      });
    } catch (err) {
      runInAction(() => {
        this.isIbanCheckLoading = false;
        this.error = err;
      });
    }
  }
}

export default new PaymentStore();
