import React from 'react';

import { ReactComponent as UploadIcon } from './upload-file-icon.svg';
import { ReactComponent as DeleteIcon } from './delete-file-icon.svg';
import { classNames } from 'uikit/utils';

import './AttachDoc.scss';

export const AttachDoc = ({
  name,
  error,
  files,
  onChange,
  label,
  className,
  initialStatus,
  isDisabled,
  isRequired,
  onRemoveFiles,
  hintText
})=> {

  const inputChange = e => {
    onChange(e);
  };

  const inputClasses = classNames({
    'input-wrapper-file': true,
    'active': files?.length,
    'error': !!error,
    'hit': !error && !!initialStatus,
    'disabled': isDisabled,
    [className]: true
  });

  const renderFileInfo = (file) => {
    return <div className="files-item" key={ file?.name + file?.id }>
      <p className="file-text">{ file?.name }</p>
      <DeleteIcon onClick={ () => onRemoveFiles(file?.id) } className="delete-file-icon" />
      <div className="tooltip"><p className="tooltip-text">{ file?.name }</p></div>
    </div>;
  };

  return (
    <div className={ inputClasses }>
      { label && <label htmlFor={ name }>{ label + (isRequired ? '*' : '') }</label> }
      <div className="input">
        <div className="files-list-wrapper">
          { files?.map(file => renderFileInfo(file)) }
        </div>
        <div className="upload-file-button">
          <input
            name={ name }
            type="file"
            multiple
            accept=".jpg,.jpeg,.pdf, .png"
            title=" "
            onChange={ inputChange }
            disabled={ isDisabled }
            className="file-input"
          />
          <UploadIcon className="upload-file-icon" />
        </div>
      </div>
      <p className={ `${error ? 'input-sub-text input-error' : 'input-hint-text'}` }>
        { error || hintText }
      </p>
    </div>
  );
};

AttachDoc.propTypes = {

};
