import { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from './arrow-down.svg';
import { classNames } from '../utils';
import './InputDropDown.scss';
import { CheckBox } from '../CheckBox/CheckBox';

export const InputDropDown = ({
  options,
  specificOptions,
  name,
  error,
  value,
  onChange,
  label = '',
  placeholder,
  subText,
  className,
  topLabel,
  isRequired,
  isMulti,
  isDisabled
}) => {

  const [ isMenuOpen, setIsMenuOpen ] = useState(false);
  const inputClasses = classNames({
    'input-wrapper-dropdown': true,
    'active': isMenuOpen || value?.length,
    'disabled': isDisabled,
    'multi-select': isMulti,
    [className]: true
  });

  const getValue = () => {
    if( isMulti) {
      const multiValue = options?.filter(option => value?.includes(option.key));
      return multiValue.map(val => val.value).join('/');
    }
    return options?.find(option => option?.key === value)?.value;
  };

  const inputChange = data => {
    if( isMulti) {
      const valueSet = value?.includes(data) ? value.filter(val => val !== data) : [...value, data];
      return onChange(name, valueSet);
    }
    onChange(name, data);
    setIsMenuOpen( false );
  };

  const toggleMenu = () => {
    if(options && !isDisabled) {
      setIsMenuOpen( !isMenuOpen );
    }
  };

  const closeMenu = (e) => {
    if(!e?.relatedTarget?.className.includes('dropdown-choice')) {
      setIsMenuOpen( false );
    }
  };

  return (
    <div onBlur={ (e) => closeMenu(e) }
      role="button"
      tabIndex={ 0 }
      onKeyDown={ toggleMenu }
      onClick={ toggleMenu }
      className={ inputClasses }
    >
      { topLabel && <p className="top-label">{ topLabel }</p> }
      <label htmlFor={ name }>{ label + (isRequired ? '*' : '') }</label>
      <input
        name={ name }
        type={ 'text' }
        value={ getValue() || '' }
        onChange={ (e) => onChange(e) }
        placeholder={ placeholder }
        disabled
        className="input" >
      </input>
      <div
        className={ `input-icon eye ${error ? 'error' : ''}` }
      >
        <ArrowIcon />
      </div>
      {
        isMenuOpen && <div className="input-dropdown-menu">
          { options.map( dataPoint => <CheckBox
            key={ dataPoint.key }
            onChange={ () => inputChange(dataPoint.key) }
            isActive={ isMulti && value?.includes(dataPoint.key) || dataPoint.key === value }
            showCheckBox={ isMulti }
            value={ dataPoint.value } />) }
          { specificOptions && specificOptions.map( dataPoint => <CheckBox
            key={ dataPoint.key }
            onChange={ () => inputChange(dataPoint.key) }
            isActive={ isMulti && value?.includes(dataPoint.key) || dataPoint.key === value }
            showCheckBox={ isMulti }
            value={ null }
            content={ dataPoint.content }
          />)

          }
        </div>
      }
      { ( error || subText ) && <p className={ `input-sub-text ${error ? 'input-error' : ''}` }>
        { error || subText }</p> }
    </div>
  );
};

InputDropDown.propTypes = {
  options: PropTypes.array,
  specificOptions: PropTypes.array,
  name: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  subText: PropTypes.string,
  className: PropTypes.string,
  topLabel: PropTypes.string,
  isRequired: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool
};
