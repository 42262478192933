import PropTypes from 'prop-types';
import './IconButton.scss';
import { classNames } from '../utils';
import { NavLink } from 'react-router-dom';

export const IconButton = ({ Icon, text, onClick, path, navigationType, active, className, isDisabled }) => {
  const IconButtonClasses = classNames({
    'icon-button': true,
    'active': active,
    [className]: true
  });

  const handleDisableNavLinkTrickClick = (e) => {
    if(isDisabled) e.preventDefault();
    if (onClick) onClick();
  };

  if( navigationType ) {
    return (
      <NavLink
        onClick={ handleDisableNavLinkTrickClick }
        className={ ({ isActive }) => `icon-button ${ isActive ? 'active' : ''}` }
        to={ { pathname: path } }
      >
        <Icon />
        <p>{ text }</p>
      </NavLink>
    );
  }

  return (
    <button className={ IconButtonClasses } onClick={ onClick }>
      <Icon />
      <p>{ text }</p>
    </button>
  );
};

IconButton.propTypes = {
  Icon: PropTypes.object.isRequired,
  text: PropTypes.string,
  navigationType: PropTypes.bool,
  path: PropTypes.string,
  active: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.oneOfType([ PropTypes.string, PropTypes.func ])
};
