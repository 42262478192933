export const UNVERIFIED_ROUTE_PATHS = {
  DASHBOARD: '/dashboard',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

export const UNAUTHORIZED_ROUTE_PATHS = {
  LOGIN: '/login',
  REGISTRATION: '/registration',
  USER_REGISTRATION: '/registration/user',
  ACCOUNT_REGISTRATION: '/registration/account',
  PASSWORD_RECOVERY: '/password_recovery'
};

export const ROUTE_PATHS = {
  ROOT: '/',
  NEW_PAYMENT: '/new-payment',
  CURRENCY_EXCHANGE: '/currency-exchange',
  ACCOUNT: 'account',
  CARDS: '/cards',
  TRANSACTIONS: '/transactions',
  INVOICES: '/invoices',
  FEES: '/fees',
  REPRESENTATIVES: '/representatives',
  ...UNVERIFIED_ROUTE_PATHS,
  ...UNAUTHORIZED_ROUTE_PATHS
};

export const INITIAL_PATH_AFTER_LOGIN = ROUTE_PATHS.DASHBOARD;
