import { makeAutoObservable, runInAction } from 'mobx';
import { strToFloat } from 'services/utils';
import {
  getCurrencyExchangeRate,
  exchange,
  exchangeConfirm,
  generateSecurityCode, resendSecurityCode
} from 'services/requestAgent';

const getOppositeAmount = amountType => {
  return amountType === 'amountFrom' ? 'amountTo' : 'amountFrom';
};

class CurrencyExchangeStore {
  isExchangeRateLoading = false;
  isExchangeLoading = false;
  isExchangeConfirmLoading = false;
  isExchangeCreateLoading = false;
  isCommissionLoading = false;
  isExchangeConfirmSuccess = false;
  isExchangeCreateSuccess = false;
  error = null;
  confirmationPopupError = null;
  walletFrom = null;
  walletTo = null;
  amountFrom = null;
  amountTo = null;
  exchangeData = null;
  exchangeRate = {
    from: null,
    to: null,
    rate: null,
    min: null
  };

  constructor() {
    makeAutoObservable(this);
  }

  setFromToWallets(from, to) {
    this.walletFrom = from;
    this.walletTo = to;
    this.error = null;
  }

  setFromToAmount(amountType, value) {
    const amount = value;
    this[amountType] = amount;
    if(!value) {
      this.exchangeData = null;
    }
    const oppositeAmount = !Number.isNaN(amount) && this.exchangeRate && 
    this.exchangeRate.from === this.walletFrom.currency && this.exchangeRate.to === this.walletTo.currency ?
      (
        amountType === 'amountFrom' ? 
          amount * this.exchangeRate.rate : 
          amount / this.exchangeRate.rate
      ).toFixed(2) :
      null;
    this[getOppositeAmount(amountType)] = oppositeAmount === '0.00' ? null : oppositeAmount;
    this.error = null;
  }

  setIsExchangeRateLoading(status) {
    this.isExchangeRateLoading = status;
    this.error = null;
  }

  setIsExchangeLoading(status) {
    this.isExchangeLoading = status;
    this.error = null;
  }

  setIsExchangeConfirmLoading(status) {
    this.isExchangeConfirmLoading = status;
    this.error = null;
  }

  setIsExchangeCreateLoading(status) {
    this.isExchangeCreateLoading = status;
    this.error = null;
  }

  setIsCommissionLoading(status) {
    this.isCommissionLoading = status;
    this.error = null;
  }

  resetCreateSuccess(){
    this.isExchangeCreateSuccess = false;
    this.isExchangeConfirmSuccess = false;
    this.amountFrom = null;
    this.amountTo = null;
  }

  resetConfirmSuccess(){
    this.isExchangeConfirmSuccess = false;
  }

  clearConfirmationPopupError() {
    this.confirmationPopupError = null;
  }

  resetExchangeStore() {
    this.isExchangeRateLoading = false;
    this.isExchangeLoading = false;
    this.isExchangeConfirmLoading = false;
    this.isExchangeCreateLoading = false;
    this.isExchangeConfirmSuccess = false;
    this.isExchangeCreateSuccess = false;
    this.error = null;
    this.confirmationPopupError = null;
    this.walletFrom = null;
    this.walletTo = null;
    this.amountFrom = null;
    this.amountTo = null;
    this.exchangeData = null;
    this.exchangeRate = {
      from: null,
      to: null,
      rate: null,
      min: null
    };
  }

  async generateExchangeSecurityCode() {
    try {
      await generateSecurityCode();
    } catch (err) {
      runInAction(() => {
        this.confirmationPopupError = err;
      });
    }
  }

  async resendExchangeSecurityCode() {
    try {
      await resendSecurityCode();
    } catch (err) {
      runInAction(() => {
        this.confirmationPopupError = err;
      });
    }
  }

  async getExchangeRate() {
    try {
      this.setIsExchangeRateLoading(true);
      const rate = await getCurrencyExchangeRate({
        from: this.walletFrom.currency,
        to: this.walletTo.currency
      });
      runInAction(() => {
        this.isExchangeRateLoading = false;
        this.exchangeRate = rate;
      });
      if(this.amountFrom) {
        this.setFromToAmount('amountFrom', this.amountFrom);
      }
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isExchangeRateLoading = false;
      });
      throw err;
    }
  }

  async exchangeCurrency(accountNumber, isCommissionCalculating = false) {
    try {
      if(isCommissionCalculating) {
        this.setIsCommissionLoading(true);
      } else {
        this.setIsExchangeLoading(true);
      }
      const data = await exchange(accountNumber, { 
        source_wallet: this.walletFrom.wallet_number,
        target_wallet: this.walletTo.wallet_number,
        amount: strToFloat(this.amountFrom)
      });
      runInAction(() => {
        this.isExchangeLoading = false;
        this.isCommissionLoading = false;
        this.isExchangeConfirmSuccess = !isCommissionCalculating;
        this.exchangeData = data;
        this.exchangeRate = {
          ...this.exchangeRate,
          from: data.applied_rate.source_currency,
          to: data.applied_rate.target_currency,
          rate: data.applied_rate.rate
        };
      });
    } catch (err) {
      if(err.code === 'EXCHANGE_RATE_MIN_VALUE') {
        let newError = {};
        newError.fields = {
          amount: err.code
        };
        runInAction(() => {
          this.error = newError;
          this.isExchangeLoading = false;
          this.isCommissionLoading = false;
          this.exchangeData = null;
        });
        return;
      }
      runInAction(() => {
        this.error = err;
        this.exchangeData = null;
        this.isExchangeLoading = false;
      });
      throw err;
    }
  }

  confirmExchangeCurrency(accountNumber) {
    return async (securityCode) => {
      try {
        this.setIsExchangeCreateLoading(true);
        await exchangeConfirm(accountNumber, { 
          source_wallet: this.walletFrom.wallet_number,
          target_wallet: this.walletTo.wallet_number,
          source_amount: this.exchangeData.source_amount,
          target_amount: this.exchangeData.target_amount,
          amount: strToFloat(this.amountFrom),
          securityCode
        });
        runInAction(() => {
          this.isExchangeCreateLoading = false;
          this.isExchangeCreateSuccess = true;
        });
      } catch (err) {
        runInAction(() => {
          this.confirmationPopupError = err;
          this.isExchangeCreateLoading = false;
        });
        throw err;
      }
    };
  }
}

export default new CurrencyExchangeStore();
