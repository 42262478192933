import ComplexTranslate from 'components/ComplexTranslate';
import { useContext } from 'react';
import i18nContext from 'components/i18n-context';

const Footer = () => {
  const i18n = useContext(i18nContext);
  return (
    <footer className="footer copyright-text demo-border">
      <p className={ 'copyright' }>
        <ComplexTranslate
          text={ 'footer.text' }
          interpolation={ {
            Finmatic: <a href="/dashboard" className="link-normal">{ i18n.getMessage('footer.Finmatic') }</a>
          } } />
      </p>
    </footer>
  );
};

export default Footer;
