import LatestTransactions from 'components/common/LatestTransactions/LatestTransactions';
import './Transactions.scss';

export const Transactions = () => {
  return (
    <section className="right-section transactions">
      <LatestTransactions />
    </section>
  );
};
