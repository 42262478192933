/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import OpenPaydAdvancedForm from './OpenPaydAdvancedForm';
import { useEffect } from 'react';
import OpenPaydSimpleForm from './OpenPaydSimpleForm';
import { FORM_TYPES } from '../../constants';
import { openPaydSimpleValidation, openPaydAdvancedValidation } from './ModelValidators';
import openPaydSimpleModel from './Models/openPaydSimpleModel';
import openPaydAdvancedModel from './Models/openPaydAdvancedModel';

const OpenPaydForm = (tableProps) => {
  const formType = tableProps.formType;
  const validationSchema = formType === FORM_TYPES.SIMPLE
    ? openPaydSimpleValidation({ ...tableProps.validationProps, model: openPaydSimpleModel })
    : openPaydAdvancedValidation({ ...tableProps.validationProps, model: openPaydAdvancedModel });
  const form = useFormik({ enableReinitialize: true, ...validationSchema });
  useEffect(() => {
    form.setValues(
      form.values
    );
  }, [formType]);
  const formProps = { ...tableProps, validationSchema };
  return (
    <>
      {
        formType === FORM_TYPES.SIMPLE ?
          <OpenPaydSimpleForm
            commonForm={ form }
            { ...formProps }
          />
          :
          <OpenPaydAdvancedForm
            commonForm={ form }
            { ...formProps }
          />
      }
    </>
  );
};

OpenPaydForm.propTypes = {
  accountNumber: PropTypes.string,
  getCommission: PropTypes.func.isRequired,
  uploadDocuments: PropTypes.func.isRequired,
  wallet: PropTypes.object,
  paymentMethod: PropTypes.string,
  commission: PropTypes.object,
  isCommissionLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  uploadedFiles: PropTypes.array,
  removePaymentFile: PropTypes.func,
  commonForm: PropTypes.object,
  currency: PropTypes.string,
  isSuccess: PropTypes.bool,
  // todo: describe validationProps object values
  validationProps: PropTypes.object.isRequired
};

export default OpenPaydForm;
