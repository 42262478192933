import PaymentForm from 'components/common/PaymentForm';
import { inject, observer } from 'mobx-react';

const NewPayment = () => {
  return (
    <section className="right-section">
      <PaymentForm/>
    </section>
  );
};

export default inject('userStore')(observer(NewPayment));
