import { useEffect } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { Email } from './Email';
import { EmailConfirmation } from './EmailConfirmation';
import { ChangePassword } from './ChangePassword';
import { PASSWORD_RECOVERY_STATUSES } from 'components/common/constants';
import 'components/Authorization/Login.scss';

const PasswordRecoveryStages = ({ passwordRecoveryStore }) => {
  let { tokenId } = useParams();

  useEffect(() => {
    if(tokenId) {
      passwordRecoveryStore.getPasswordRecoveryLinkData(tokenId);
    }
  }, [passwordRecoveryStore, tokenId]);

  switch(passwordRecoveryStore.recoveryStatus) {
  case PASSWORD_RECOVERY_STATUSES.EMAIL_CONFIRMATION: {
    const onBackToEmail = () => passwordRecoveryStore.setRecoveryStatus(null);

    return <EmailConfirmation
      email={ passwordRecoveryStore.values.username }
      backToEmail={ onBackToEmail }
      error={ passwordRecoveryStore.error }
    />;
  }
  case PASSWORD_RECOVERY_STATUSES.PASSWORD_SET: {
    const onSubmit = (fieldData) => {
      if(!passwordRecoveryStore.isSecurityCodeGenerated) {
        passwordRecoveryStore.setPassword(fieldData);
      } else {
        passwordRecoveryStore.setCode(fieldData);
      }
      passwordRecoveryStore.sendPasswordChange();
    };

    return <ChangePassword 
      isConfirmation={ passwordRecoveryStore.isSecurityCodeGenerated }
      phone={ passwordRecoveryStore.phone }
      onSubmitStep={ onSubmit }
      setPassword={ (password) => passwordRecoveryStore.setPassword(password) }
      resendCode={ () => passwordRecoveryStore.generateSecurityCode() }
      error={ passwordRecoveryStore.error } 
    />;
  }
  default: {
    const onEmailSubmit = (username) => {
      passwordRecoveryStore.setUsername(username);
      passwordRecoveryStore.sendPasswordRecoveryRequest();
    };
    return <Email onSubmit={ onEmailSubmit } 
      error={ passwordRecoveryStore.error }
      recoveryLinkError={ passwordRecoveryStore.recoveryLinkError }
      userName={ passwordRecoveryStore.values?.username } />;
  }
  }
};

PasswordRecoveryStages.propTypes = {
  passwordRecoveryStore: MobXPropTypes.observableObject
};

export default inject('passwordRecoveryStore')(observer(PasswordRecoveryStages));
