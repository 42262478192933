import React from 'react';
import PropTypes from 'prop-types';
import './Container.scss';
import { classNames } from '../utils';

export const Container = ({ header, children, className }) => {
  const containerClasses = classNames({
    'uikit-container': true,
    [className]: true
  });

  return (
    <div className={ containerClasses }>
      <div className="container-header">
        <p className="container-header-text">{ header }</p>
      </div>
      { children }
    </div>
  );
};

Container.propTypes = {

};
