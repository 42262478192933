import { replaceSpaces, strToFloat } from 'services/utils';

export default ({ providerTableData, data }) => ({
  sender_wallet: data.wallet && data.wallet?.wallet_number?.trim() || '',
  currency: data.wallet && data.wallet?.currency?.trim() || '',
  amount: strToFloat(providerTableData?.amount?.toString() || ''),
  transaction_recipient: {
    ...(providerTableData.recipient_type === 'Company' ? {
      company_name: providerTableData?.company_name?.trim() || ''
    } : {
      first_name: providerTableData?.first_name?.trim() || '',
      last_name: providerTableData?.last_name?.trim() || ''
    }),
    recipient_type: providerTableData.recipient_type.toUpperCase(),
    requisites: {
      recipient_wallet: replaceSpaces(providerTableData?.recipient_wallet) || ''
    }
  },
  payment_method: data.paymentMethod || '',
  transfer_provider: data.wallet && data.wallet?.transfer_provider || '',
  comment: providerTableData?.comment?.trim() || '',
  documents: data.uploadedFiles ? data.uploadedFiles : []
});
