import { makeAutoObservable, runInAction } from 'mobx';
import {
  getUserInformation,
  getWallets,
  getConstants,
  getTariffs,
  getUserInfoByAccNumber
} from 'services/requestAgent';
import { setSelectedMultiAccount, getSelectedMultiAccount } from 'services/authUtils';
import { WALLET_PROPERTIES, ACCOUNT_LEVEL, ACCOUNT_TYPE, USER_ACCOUNT_STATUSES } from 'components/common/constants';

class UserStore {
  isLoading = false;
  error = null;
  userData = {};
  userWallets = [];
  currentWallet = null;
  isTransactions = false;
  activeAccount= null;
  appConstants = {};
  isAccountVerified = null;
  enabledTariffs = [];
  currentAccountPermission=null;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  getVerifiedStatus(userData) {
    if ( process.env.REACT_APP_KYC_MODE !== '/external' ) return true;

    if ( userData.account.type === ACCOUNT_TYPE.COMPANY ) {
      return userData.account.status === USER_ACCOUNT_STATUSES.VERIFIED;
    }

    if ( userData.account?.type === ACCOUNT_TYPE.INDIVIDUAL ) {
      return userData.account?.level !== ACCOUNT_LEVEL.ZERO;
    }
  }

  setActiveAccount(account) {
    this.activeAccount = this.userWallets.find( wallet => wallet[ WALLET_PROPERTIES.WALLET_NUMBER ] === account );
  }

  setUserData(data) {
    this.userData = data;
  }

  setWallets(wallets) {
    this.userWallets = wallets;
  }

  setCurrentWallet(wallet) {
    this.currentWallet = wallet;
  }

  resetEnabledTariffs() {
    this.enabledTariffs = [];
  }

  updateRepresentativeData(updatedRepresentative, isRemove = false) {
    const existingRepresentative = this.userData.representatives?.find(({ id }) => id === updatedRepresentative.id);

    this.userData.representatives = existingRepresentative ? this.userData.representatives.reduce(
      (result, representative) => {
        if (representative.id === updatedRepresentative.id) {
          if(isRemove) return result;
          result.push(updatedRepresentative);
          return result;
        }
        result.push(representative);
        return result;
      }, []) : [...this.userData.representatives, updatedRepresentative];
  }

  getCurrentAccountPermissions(availableAccounts, accountNumber) {
    return availableAccounts.find(account =>
      account.account_number === accountNumber ||
      account?.customer_account?.account_number === accountNumber
    )?.permissions || null;
  }

  changeCurrentUserAccount = async (accountNumber) => {
    this.currentAccountPermission = this.getCurrentAccountPermissions(this.userData.availableAccounts, accountNumber);
    setSelectedMultiAccount(accountNumber);
    await this.loadUserData(accountNumber);
  };

  async getAppConstants() {
    try {
      this.setIsLoading(true);
      const data = await getConstants();
      runInAction(() => {
        this.appConstants = data;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  getCurrentAccount(selectedSharedAccountData, ownUserAccounts) {
    if (selectedSharedAccountData) {
      return selectedSharedAccountData;
    }
    return ownUserAccounts.find(({ type }) => type === ACCOUNT_TYPE.COMPANY) || ownUserAccounts[0];
  }

  async loadUserData(accountNumber) {
    this.setIsLoading(true);
    try {
      this.setIsLoading(true);
      let selectedSharedAccountData = null;
      const data = await getUserInformation();
      const selectedAccountNumber = accountNumber || getSelectedMultiAccount() || null;
      if(selectedAccountNumber) {
        selectedSharedAccountData = await getUserInfoByAccNumber(selectedAccountNumber);
      }
      // DTO changed for UI representation (past representation without multiaccounts)
      const userDataRepresentation = {
        account: this.getCurrentAccount(selectedSharedAccountData, data.user_accounts),
        representatives: data.representatives,
        availableAccounts: [...data.user_accounts, ...data.shared_accounts],
        ...data.user_info
      };

      const currentAccountPermission = this.getCurrentAccountPermissions(
        userDataRepresentation.availableAccounts,
        selectedAccountNumber
      );

      // Move wallets logic to separate store!
      if(!userDataRepresentation?.account?.account_number) {
        return this.error = { code: 'ACCOUNT_NOT_REGISTERED' };
      }
      const wallets = await getWallets(userDataRepresentation.account.account_number);
      runInAction(() => {
        this.userData = userDataRepresentation;
        this.currentAccountPermission = currentAccountPermission;
        this.userWallets = wallets;
        this.currentWallet = wallets[0];
        this.isAccountVerified = this.getVerifiedStatus(userDataRepresentation);
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async loadEnabledTariffs(walletNumber, transactional) {
    try {
      // Move tariffs logic to separate store as well likely
      const data = await getTariffs(this.userData.account?.account_number, walletNumber, transactional);
      runInAction(() => {
        this.enabledTariffs = data;
      });

    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
      throw err;
    }
  }

  // Temporary solution for updating balances
  async getUserWallets() {
    try {
      const wallets = await getWallets(this.userData.account.account_number);
      runInAction(() => {
        this.userWallets = wallets;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
      throw err;
    }
  }
}

export default new UserStore();
