/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import i18nContext from 'components/i18n-context';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { RECIPIENT_TYPES } from 'components/common/constants';
import { amountFormattedValue, prepareFieldErrors } from 'services/utils';
import openPaydSimpleModel from './Models/openPaydSimpleModel';
import { IconButton } from '../../../../uikit/IconButton/IconButton';
import { ReactComponent as LocalIcon } from 'assets/payments.svg';
import { ReactComponent as IbanIcon } from 'assets/payment-advanced.svg';
import BottomPartForm from './BottomPartForm';

const LOCAL_TAB = 'LOCAL_ACCOUNT';
const IBAN_TAB = 'IBAN';

const OpenPaydSimpleForm = ({
  accountNumber,
  getCommission,
  uploadDocuments,
  isInternalIban,
  checkIban,
  validationSchema,
  wallet,
  paymentMethod,
  uploadedFiles,
  commission,
  removePaymentFile,
  error,
  isCommissionLoading,
  isLoading,
  isSuccess,
  currency,
  commonForm
}) => {
  const i18n = useContext(i18nContext);
  const form = useFormik(validationSchema);
  const serverFieldsErrors = prepareFieldErrors(i18n, error);
  const [disabled, setDisabled] = useState(true);
  const [ showMethodCommission, setShowMethodCommission ] = useState(false);
  const [activeTab, setActiveTab] = useState( currency === 'GBP' ? LOCAL_TAB : IBAN_TAB);
  const model = openPaydSimpleModel;

  useEffect(() => {
    handleGetCommission();
    if (values?.recipient_wallet) checkIban(values.recipient_wallet);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      resetForm({});
    }
    setDisabled(true);
  }, [isSuccess]);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    validateForm, 
    resetForm
  } = commonForm || form;

  const handleGetCommission = async() => {
    if(
      (values.recipient_wallet || (values.account_number && values.sort_code)) &&
      values.amount &&
      values.recipient_type &&
      values.comment &&
      (values.recipient_type === RECIPIENT_TYPES.COMPANY ? 
        values.company_name : 
        (values.first_name && values.last_name)
      )
    ) {
      const errorsResult = await validateForm();
      if(!Object.keys(errorsResult).length) {
        await getCommission(
          accountNumber,
          model({
            providerTableData: values,
            data: { wallet }
          })
        );
        setShowMethodCommission(true);
        setDisabled(false);
      }
    }
  };

  const handleRecipientTypeChange = (name, data) => handleChange({ target: { name, value: data } });
  const onAmountIBANChange = (e) => {
    setShowMethodCommission(false);
    handleChange(e);
  };

  const handleActiveTab = activeTab => {
    if(activeTab === LOCAL_TAB) {
      values.recipient_wallet = '';
    }
    if (activeTab === IBAN_TAB) {
      values.account_number = '';
      values.sort_code = '';
    }
    setActiveTab(activeTab);
  };

  const getCommissionValue = () => {
    if( !commission.value && !commission.currency && !commission.type) {
      return '0.00';
    }
    return `${amountFormattedValue(commission.value)} ${commission.currency}`;
  };

  const handleIbanComplete = async() => {
    values.recipient_wallet && await checkIban(values.recipient_wallet);
    await handleGetCommission();
  };

  const bottomPartProps = {
    values,
    errors,
    error,
    handleChange,
    handleSubmit,
    serverFieldsErrors,
    onAmountIBANChange,
    handleGetCommission,
    showMethodCommission,
    getCommissionValue,
    paymentMethod,
    isSubmitDisabled: disabled ||
      isLoading ||
      isCommissionLoading ||
      !!Object.keys(errors).length ||
      !!Object.keys(serverFieldsErrors).length ||
      !paymentMethod,
    isLoading: isLoading || isCommissionLoading,
    uploadedFiles,
    uploadDocuments,
    removePaymentFile
  };

  return (
    <>
      { currency === 'GBP' &&
        <div className="navigation-payment">
          <IconButton
            className="navigation-payment-button"
            text={ i18n.getMessage('transfer.divider.simpleForm') }
            active={ activeTab === LOCAL_TAB }
            onClick={ () => handleActiveTab(LOCAL_TAB) }
            Icon={ LocalIcon } />
          <IconButton
            className="navigation-payment-button"
            text={ i18n.getMessage('transfer.label.iban') }
            active={ activeTab === IBAN_TAB }
            onClick={ () => handleActiveTab(IBAN_TAB) }
            Icon={ IbanIcon } />
        </div>
      }
      { activeTab === IBAN_TAB &&
        <div className="inputs-wrapper">
          <Input
            isRequired={ true }
            isDisabled={ !!values.account_number || !!values.sort_code }
            label={ i18n.getMessage('transfer.form.recipientIban.label') }
            name={ 'recipient_wallet' }
            value={ values.recipient_wallet }
            error={ !values.account_number && errors.recipient_wallet || serverFieldsErrors.iban }
            onChange={ onAmountIBANChange }
            onBlur={ handleIbanComplete }
            subText={ isInternalIban && i18n.getMessage('transfer.form.recipientIban.subText') }
          />
        </div>
      }
      { currency === 'GBP' && activeTab === LOCAL_TAB &&
        <div className="inputs-wrapper-evenly">
          <Input
            isRequired={ true }
            isDisabled={ !!values.recipient_wallet }
            name={ 'account_number' }
            label={ i18n.getMessage('transfer.form.accountNumberGBP.label') }
            value={ values.account_number }
            error={ errors.account_number || serverFieldsErrors.account_number }
            onChange={ handleChange }
            onBlur={ handleGetCommission }
            max={ 8 }
          />
          <Input
            isRequired={ true }
            isDisabled={ !!values.recipient_wallet }
            name={ 'sort_code' }
            label={ i18n.getMessage('transfer.form.sortCode.label') }
            value={ values.sort_code?.replace(/^(\d{2})(\d{2})(\d{2})/, '$1-$2-$3') }
            error={ errors.sort_code || serverFieldsErrors.sort_code }
            onChange={ handleChange }
            onBlur={ handleGetCommission }
            max={ 8 }
          />
        </div>
      }
      <div className={ `inputs-wrapper ${ values.recipient_type}` }>
        <InputDropDown
          label={ i18n.getMessage('transfer.form.recipientType.label') }
          name={ 'recipient_type' }
          value={ values.recipient_type }
          onChange={ handleRecipientTypeChange }
          options={ Object.values(RECIPIENT_TYPES).map(value => ({ key: value, value })) }
        />
        {
          values.recipient_type === RECIPIENT_TYPES.COMPANY &&
            <Input
              isRequired={ true }
              label={ i18n.getMessage('transfer.form.companyName.label') }
              name={ 'company_name' }
              value={ values.company_name }
              error={ errors.company_name || serverFieldsErrors.companyName }
              onChange={ handleChange }
              onBlur={ handleGetCommission }
            />
        }
        {
          values.recipient_type === RECIPIENT_TYPES.INDIVIDUAL &&
            <Input
              isRequired={ true }
              label={ i18n.getMessage('transfer.form.firstName.label') }
              name={ 'first_name' }
              value={ values.first_name }
              error={ errors.first_name || serverFieldsErrors.firstName }
              onChange={ handleChange }
              onBlur={ handleGetCommission }
            />
        }
        {
          values.recipient_type === RECIPIENT_TYPES.INDIVIDUAL &&
            <Input
              isRequired={ true }
              label={ i18n.getMessage('transfer.form.lastName.label') }
              name={ 'last_name' }
              value={ values.last_name }
              error={ errors.last_name || serverFieldsErrors.lastName }
              onChange={ handleChange }
              onBlur={ handleGetCommission }
            />
        }
      </div>
      <BottomPartForm { ...bottomPartProps } />
    </>
  );
};

OpenPaydSimpleForm.propTypes = {
  accountNumber: PropTypes.string,
  getCommission: PropTypes.func.isRequired,
  uploadDocuments: PropTypes.func.isRequired,
  isInternalIban: PropTypes.bool,
  checkIban: PropTypes.func.isRequired,
  validationSchema: PropTypes.object,
  wallet: PropTypes.object,
  paymentMethod: PropTypes.string,
  commission: PropTypes.object,
  isCommissionLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  uploadedFiles: PropTypes.array,
  removePaymentFile: PropTypes.func,
  commonForm: PropTypes.object,
  currency: PropTypes.string,
  isSuccess: PropTypes.bool
};

export default OpenPaydSimpleForm;
