import { makeAutoObservable, runInAction } from 'mobx';
import {
  setPhoneNumber,
  confirmSmsCode,
  resendEmailRegistration,
  createAccount,
  confirmAccountEmail,
  createUser,
  registerAccount,
  confirmEmail,
  resendCodeAccountEmail
} from 'services/requestAgent';
import { REGISTRATION_ACCOUNT_TYPE, REGISTRATION_STATUSES } from 'components/common/constants';

const EMAIL_CONFIRMATION_ERROR = 'WRONG_EMAIL_CONFIRMATION';
const EMAIL_CODE_EXCEEDED_ERROR = 'EMAIL_CONFIRMATION_EXCEEDED_ATTEMPTS';
const MAX_ATTEMPTS_EMAIL_CODE = 5;

class RegistrationStore {
  isLoading = false;
  isAcceptTerms = false;
  isAfterUserRegistration = false;
  error = null;
  registrationType = REGISTRATION_ACCOUNT_TYPE.BUSINESS;
  verificationCode = '';
  verificationCodeAttempts = 0;
  registrationStatus = null;
  responseId = null;

  generalFields = {
    login: '',
    email: '',
    password: '',
    passwordRepeat: '',
    code: '',
    codeRepeat: '',
    phoneNumber: ''
  };

  personalFields = {
    firstName: '',
    surname: ''
  };

  businessFields = {
    companyName: ''
  };


  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  setLogin(login) {
    this.generalFields.login = login;
  }

  setEmail(email) {
    this.generalFields.email = email;
  }
  
  setPassword(password) {
    this.generalFields.password = password;
  }
  
  setPasswordRepeat(passwordRepeat) {
    this.generalFields.passwordRepeat = passwordRepeat;
  }
  
  setCode(code) {
    this.generalFields.code = code;
  }
  
  setCodeRepeat(codeRepeat) {
    this.generalFields.codeRepeat = codeRepeat;
  }

  setFirstName(firstName) {
    this.personalFields.firstName = firstName;
  }

  setSurname(surname) {
    this.personalFields.surname = surname;
  }

  setCompanyName(companyName) {
    this.businessFields.companyName = companyName;
  }

  setVerificationCode(verificationCode) {
    this.verificationCode = verificationCode;
  }

  setRegistrationStatus(registrationStatus) {
    this.registrationStatus = registrationStatus;
  }

  setPhoneNumber(phoneNumber) {
    this.generalFields.phoneNumber = phoneNumber;
  }

  setRegistrationType(type) {
    this.registrationType = type;
  }

  setResponseId(status) {
    this.responseId = status?.response_id;
  }

  setIsAfterRegistrationUser(status) {
    this.isAfterUserRegistration = status;
  }

  clearError() {
    this.error = null;
  }
  
  reset() {
    this.isLoading = false;
    this.isAcceptTerms = false;
    this.error = null;
    this.registrationStatus = null;
    this.verificationCode = '';
    this.generalFields.email = '';
    this.generalFields.password = '';
    this.generalFields.passwordRepeat = '';
    this.personalFields.firstName = '';
    this.personalFields.surname = '';
    this.businessFields.companyName = '';
    this.generalFields.code = '';
    this.generalFields.codeRepeat = '';
    this.registrationType = REGISTRATION_ACCOUNT_TYPE.PERSONAL;
    this.responseId = null;
  }

  async registerUser() {
    try {
      this.setIsLoading(true);
      const status = await createUser({
        login: this.generalFields.login,
        password: this.generalFields.password
      });
      this.setResponseId(status);
      this.setRegistrationStatus(REGISTRATION_STATUSES.REGISTRATION_STATUS_SECOND_FACTOR_CONFIRMATION_PHONE);
      this.setIsLoading(false);
      this.verificationCodeAttempts = 0;
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async registerPersonalAccount() {
    try {
      this.setIsLoading(true);
      const data = {
        account_type: 'INDIVIDUAL',
        email: this.generalFields.email,
        first_name: this.personalFields.firstName,
        last_name: this.personalFields.surname
      };
      const status = this.isAfterUserRegistration ? await registerAccount(data) : await createAccount(data);
      this.setResponseId(status);
      this.setRegistrationStatus(REGISTRATION_STATUSES.REGISTRATION_STATUS_EMAIL_CONFIRMATION);
      this.setIsLoading(false);
      this.verificationCodeAttempts = 0;
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async registerBusinessAccount() {
    try {
      this.setIsLoading(true);
      const data = {
        account_type: 'CORPORATE',
        email: this.generalFields.email,
        company_name: this.businessFields.companyName
      };
      const status = this.isAfterUserRegistration ? await registerAccount(data) : await createAccount(data);
      this.setResponseId(status);
      this.setRegistrationStatus(REGISTRATION_STATUSES.REGISTRATION_STATUS_EMAIL_CONFIRMATION);
      this.setIsLoading(false);
      this.verificationCodeAttempts = 0;
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async sendVerificationCode() {
    try {
      this.setIsLoading(true);
      this.verificationCodeAttempts++;
      const status = this.isAfterUserRegistration ?
        await confirmEmail(this.verificationCode) : await confirmAccountEmail(this.verificationCode, this.responseId);
      this.setResponseId(status);
      if(status === REGISTRATION_STATUSES.REGISTRATION_STATUS_EMAIL_CONFIRMATION) {
        throw {
          code: this.verificationCodeAttempts < MAX_ATTEMPTS_EMAIL_CODE ?
            EMAIL_CONFIRMATION_ERROR : EMAIL_CODE_EXCEEDED_ERROR
        };

      }
      this.setRegistrationStatus(REGISTRATION_STATUSES.REGISTRATION_STATUS_CONFIRMED);
      this.setIsLoading(false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async resendVerificationCode() {
    try {
      this.setIsLoading(true);
      this.isAfterUserRegistration ?  await resendEmailRegistration() : await resendCodeAccountEmail(this.responseId);
      this.verificationCodeAttempts = 0;
      this.setIsLoading(false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async sendCode() {
    try {
      this.setIsLoading(true);
      let status;
      status = await confirmSmsCode(this.generalFields.code);
      this.setResponseId(status);
      this.setRegistrationStatus( REGISTRATION_STATUSES.REGISTRATION_STATUS_CONFIRMED);
      this.setIsLoading(false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async sendPhoneNumber() {
    try {
      this.setIsLoading(true);
      const status = await setPhoneNumber(this.generalFields.phoneNumber);
      this.setRegistrationStatus(status);
      this.setIsLoading(false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  changeConfirmationEmail() {
    runInAction(() => {
      this.registrationStatus = null;
    });
  }

  changePhoneNumber() {
    runInAction(() => {
      this.registrationStatus = REGISTRATION_STATUSES.REGISTRATION_STATUS_SECOND_FACTOR_CONFIRMATION_PHONE;
    });
  }
}

export default new RegistrationStore();
