import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from '../../i18n-context';
import ComplexTranslate from 'components/ComplexTranslate';
import registrationStore from '../../../stores/registrationStore';
import { SecurityInput } from '../../../uikit/SecurityInput/SecurityInput';
import { CountDown } from '../CountDown/CountDown';
import Alert from '../../../uikit/Alert/Alert';
import './SmsCodeWrapper.scss';

const RESEND_TIMEOUT = 60;
const CODE_SENT_TIMEOUT  = 'CODE_SEND_TIMEOUT';
export const SmsCodeWrapper = ({
  PhoneComponent,
  isDisabled,
  error,
  onComplete,
  showCountDown,
  handleResendCode,
  onTimerEnd,
  alertState
})=> {
  const i18n = useContext(i18nContext);

  return (
    <div className="sms-code-wrapper">
      <Alert type={ alertState.type }
        message={ i18n.getMessage(alertState.message) } />
      <div className="sms-description">
        <ComplexTranslate
          text={ 'sms.description' }
          interpolation={ {
            phone: PhoneComponent
          } } />
      </div>
      <SecurityInput disabled={ isDisabled } type={ 'sms' } error={ alertState.type } onComplete={ onComplete } />
      <div className="resend-wrapper">
        { !showCountDown ?
          <ComplexTranslate
            text={ 'sms.description.resend' }
            interpolation={ {
              resendCode: <button
                className="unset link-normal"
                disabled={ error?.code === CODE_SENT_TIMEOUT }
                onClick={ (e) => handleResendCode(e) }>
                { i18n.getMessage('sms.resendCode') }
              </button>
            } } />
          :
          <p className="resend-info">
            {
              i18n.getMessage('sms.resentMessage') + ' '
            }
            <CountDown onEnd={ onTimerEnd } duration={ RESEND_TIMEOUT } />
          </p>
        }
      </div>
    </div>
  );
};

SmsCodeWrapper.propTypes = {
  PhoneComponent: PropTypes.object,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  onComplete: PropTypes.func,
  showCountDown: PropTypes.bool,
  handleResendCode: PropTypes.func,
  onTimerEnd: PropTypes.func
};
