import { useContext } from 'react';
import i18nContext from '../../i18n-context';
import Button from '../../../uikit/Button/Button';
import { useNavigate } from 'react-router-dom';
import './NotFound.scss';
import Background from 'assets/404.png';

export const NotFound = () => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const backImage = {
    backgroundImage: `url(${Background})`
  };
  return (
    <div className="not-found-wrapper">
      <div className="not-found-text" style={ backImage }>
        <h2 className="not-found-header">{ i18n.getMessage('notFound.text.pageNotFound') }</h2>
        <p className="not-found-description">{ i18n.getMessage('notFound.text.weAreSorry') }</p>
      </div>
      <Button size="large" onClick={ () => navigate('/') }>
        { i18n.getMessage('notFound.label.backToHome').toUpperCase() }
      </Button>
    </div>
  );
};

