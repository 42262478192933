import PropTypes from 'prop-types';
import { DontHavePermission } from '../components/common/DontHavePermission/DontHavePermission';

export const ElementWrapper = ({ isHasPermission = true, component: Component, headerText, ...rest }) => {
  /**
   * You can use url params or location object using useParams, useLocation from react-router-dom
   * Also if you need to use history.push or something like this you must use useNavigate hook
   */
  return isHasPermission ? <Component headerText={ headerText } { ...rest }/> : <DontHavePermission headerText={ headerText } />;
};

ElementWrapper.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.elementType.isRequired
  ]),
  headerText: PropTypes.string,
  rest: PropTypes.object,
  isHasPermission: PropTypes.bool
};
