import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SuccessIcon } from 'assets/message_success.svg';
import i18nContext from '../../i18n-context';

const PopUpSuccessScheme = ({ onClose, message }) => {
  const i18n = useContext(i18nContext);

  const KEY_CODE = {
    escape: 27
  };

  useEffect(() => {
    const closePopup = (e) => e.keyCode === KEY_CODE.escape && onClose();
    window.addEventListener('keydown', closePopup);
    return () => window.removeEventListener('keydown', closePopup);
  },[]);

  return (
    <div className="scheme-wrapper pop-up-success">
      <SuccessIcon />
      <h2 className="scheme-success-header">{ i18n.getMessage('popUp.success') }</h2>
      <p className="scheme-success-message">{ message }</p>
      <button className="scheme-success-button"
        onClick={ () => onClose() }
      >{ i18n.getMessage('popUp.success.button') }</button>
    </div>
  );
};

PopUpSuccessScheme.propTypes = {
  onClose: PropTypes.func,
  message: PropTypes.string
};

export default PopUpSuccessScheme;
