import PropTypes from 'prop-types';
import './SecurityInput.scss';
import { useEffect, useRef, useState } from 'react';

const securityLength = {
  pin: 4,
  email: 5,
  sms: 6
};

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40
};

const numberKeyDownRegExp = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);

export const SecurityInput = ( { type, onComplete, disabled, error } ) => {
  const defaultSecurityState = new Array(securityLength[type]).fill('');
  const [state, setState] = useState( defaultSecurityState );
  const inputRef = useRef([]);

  useEffect(() => {
    if ( error ) {
      inputRef.current[0].focus();
      setState( defaultSecurityState );
    }
  }, [error]);

  const triggerChange = ( values ) => {
    const val = values.join('');
    if (val.length >= state.length) {
      checkCompletion( values);
    }
  };

  const onValueChange = e => {
    const index = parseInt(e.target.dataset.id);
    if ( e.target.value === '') return;
    if ( type === 'email' && !/^[a-zA-Z0-9]+$/.test(e.target.value)) return;

    let next;
    let values = [...state];
    const value = e.target.value;
    if (value.length > 1) {
      let nextIndex = value.length + index - 1;
      if (nextIndex >= state.length) {
        nextIndex = state.length - 1;
      }
      next = inputRef.current[nextIndex];
      const split = value.split('');
      split.forEach((item, i) => {
        const cursor = index + i;
        if (cursor < state.length) {
          values[cursor] = item;
        }
      });
      setState( values );
    }
    if (value.length === 1) {
      next = inputRef.current[ index +1 ];
      values[index] = value;
      setState( values );
    }
    
    if (next) {
      next.focus();
      next.select();
    }

    triggerChange(values);
  };

  const onFocus = e => {
    e.target.select(e);
  };

  const onKeyDownChange = e => {
    if(type === 'sms' && !numberKeyDownRegExp.test(e.key) ) {
      e.preventDefault();
      return;
    }
    const index = parseInt(e.target.dataset.id);
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prev = inputRef.current[prevIndex];
    const next = inputRef.current[nextIndex];

    if( e.keyCode === KEY_CODE.backspace) {
      e.preventDefault();
      const vals = [...state];
      if (state[index]) {
        vals[index] = '';
        setState( vals );
      }
      if (prev) {
        vals[prevIndex] = '';
        prev.focus();
        setState( vals );
      }
      triggerChange(vals);
      return;
    }

    if ( e.keyCode === KEY_CODE.left && prev ){
      e.preventDefault();
      prev.focus();
      return;
    }

    if ( e.keyCode === KEY_CODE.right && next ) {
      e.preventDefault();
      next.focus();
      return;
    }

    if (e.keyCode === KEY_CODE.up && e.keyCode === KEY_CODE.down ) e.preventDefault();
  };

  const checkCompletion = (values) => {
    if(values.every(value => value)) {
      onComplete(values.join(''));
    }
  };

  return (
    <div className="security-wrapper">
      { state.map((cell, index) =>
        <input
          className="security-cell"
          disabled={ disabled }
          type={ type === 'email' ? 'text' : 'number' }
          pattern={ type === 'email' ? '^[a-zA-Z0-9]+$' : '[0-9]*' }
          key={ index }
          data-id={ index }
          onChange={ onValueChange }
          onKeyDown={ onKeyDownChange }
          onFocus={ onFocus }
          ref={ el => inputRef.current[index] = el }
          value={ state[index] }
          autoFocus={ !index }
        />)
      }
    </div>
  );
};

SecurityInput.propTypes = {
  type: PropTypes.string,
  onComplete: PropTypes.func,
  disabled: PropTypes.bool
};
