import { useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { Card } from 'uikit/Card/Card';
import { PopUp } from 'uikit/PopUp/PopUp';
import PaymentForm from 'components/common/PaymentForm';
import PopUpScheme from 'components/common/PopUpScheme/PopUpScheme';
import { WALLET_PROPERTIES, WALLET_TOOLTIP_TEXT, ACCOUNT_PERMISSIONS, KYC_STATUSES } from 'components/common/constants';
import LatestTransactionsCompact from 'components/common/LatestTransactions/LatestTransactionsCompact';
import Verification from 'components/Verification/Verification';

import './DashBoard.scss';
import NoOutlet from '../common/NoOutlet/NoOutlet';

const DashBoard = ({ userStore, paymentStore, transactionStore }) => {
  const navigate = useNavigate();
  const [popUpState, setPopUpState] = useState({ open: false, account: {} });

  const showPopup = (account) => {
    setPopUpState({ open: true, account: account });
  };

  const closePopUp = () => {
    setPopUpState({ open: false, account: {} });
  };

  const goToTransaction = (wallet) => {
    transactionStore.setWallet(wallet);
    navigate('/transactions');
  };

  const goToPayment = (wallet) => {
    paymentStore.setSelectedWallet(wallet);
    navigate('/new-payment');
  };

  if(userStore.userData?.account?.kyc_status === KYC_STATUSES.DECLINED) {
    return <NoOutlet error={ { code: KYC_STATUSES.DECLINED } } />;
  }

  if(userStore.isAccountVerified !== null && !userStore.isAccountVerified) {
    return (
      <section className="right-section verifications">
        <Verification kycStatus={ userStore.userData?.account?.kyc_status }/>
      </section>
    );
  }

  const isShowPaymentByPermission = userStore?.currentAccountPermission ? 
    userStore?.currentAccountPermission?.find(permission => 
      permission === ACCOUNT_PERMISSIONS.CREATE_TRANSACTION
    ) : true;

  return (
    <section className="right-section dashboard">
      <div className="card-wrapper">
        { userStore.userWallets?.map(wallet => <Card
          key={ wallet[ WALLET_PROPERTIES.WALLET_NUMBER ] }
          type={ 'card' }
          account={ wallet[ WALLET_PROPERTIES.IBAN ]?.iban || wallet[ WALLET_PROPERTIES.MULTI_IBAN ]?.iban }
          balance={ wallet[ WALLET_PROPERTIES.AVAILABLE ] }
          currency={ wallet[ WALLET_PROPERTIES.CURRENCY ] }
          reserved={ wallet[ WALLET_PROPERTIES.ON_HOLD ] }
          buttonText={ 'Review account' }
          onExclaimPointClick={ () => showPopup(wallet) }
          onTransactionClick={ () => goToTransaction( wallet) }
          onPaymentClick={ () => goToPayment( wallet) }
          tooltipText={ WALLET_TOOLTIP_TEXT }
        />
        ) }
      </div>
      <PopUp className="wallet-info" show={ popUpState.open } onClose={ closePopUp }>
        <PopUpScheme activeWallet={ popUpState.account } onClose={ closePopUp } />
      </PopUp>
      { process.env.REACT_APP_IS_PAYFORM_ON_MAIN === 'true' && isShowPaymentByPermission && <PaymentForm /> }
      <LatestTransactionsCompact />
    </section>
  );
};

DashBoard.propTypes = {
  userStore: MobXPropTypes.observableObject,
  paymentStore: MobXPropTypes.observableObject,
  transactionStore: MobXPropTypes.observableObject
};

export default inject(stores => ({
  userStore: stores.userStore,
  paymentStore: stores.paymentStore,
  transactionStore: stores.transactionsStore
}))(observer(DashBoard));
