/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { COUNTRIES_LIST, RECIPIENT_TYPES } from '../../constants';
import Input from 'uikit/Input/Input';
import i18nContext from '../../../i18n-context';
import { useFormik } from 'formik';
import { amountFormattedValue, prepareFieldErrors } from '../../../../services/utils';
import { openPaydAdvancedModel } from './Models';
import { IconButton } from 'uikit/IconButton/IconButton';
import { ReactComponent as IbanIcon } from 'assets/globe.svg';
import { ReactComponent as NonIbanIcon } from 'assets/temple.svg';
import BottomPartForm from './BottomPartForm';

const IBAN_TAB = 'IBAN';
const NON_IBAN_TAB = 'NON_IBAN';

const OpenPaydAdvancedForm = ({
  accountNumber,
  getCommission,
  checkIban,
  isIbanCheckLoading,
  uploadDocuments,
  validationSchema,
  wallet,
  paymentMethod,
  uploadedFiles,
  commission,
  removePaymentFile,
  error,
  isCommissionLoading,
  isLoading,
  isSuccess,
  commonForm
}) => {
  const i18n = useContext(i18nContext);
  const form = useFormik(validationSchema);
  const serverFieldsErrors = prepareFieldErrors(i18n, error);
  const [disabled, setDisabled] = useState(true);
  const [activeTab, setActiveTab] = useState('IBAN');
  const [ showMethodCommission, setShowMethodCommission ] = useState(false);
  const model = openPaydAdvancedModel;

  useEffect(() => {
    handleGetCommission();
    if (values?.recipient_wallet) checkIban(values.recipient_wallet);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
    setDisabled(true);
  }, [isSuccess]);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    validateForm,
    resetForm
  } = commonForm || form;

  const handleRecipientTypeChange = (name, data) => handleChange({ target: { name, value: data } });

  const handleGetCommission = async() => {
    if(
      (values.recipient_wallet || ( values.bank_country && values.bank_name && values.account_number && values.bic )) &&
      values.amount &&
      values.recipient_type &&
      values.comment &&
      (values.recipient_type === RECIPIENT_TYPES.COMPANY ?
        values.company_name :
        (values.first_name && values.last_name)
      )
    ) {
      setDisabled(false);
      setShowMethodCommission(true);
      const errorsResult = await validateForm();
      if(!Object.keys(errorsResult).length) {
        await getCommission(
          accountNumber,
          model({
            providerTableData: values,
            data: { wallet }
          })
        );
      }
    }
  };

  const handleActiveTab = activeTab => {
    if(activeTab === NON_IBAN_TAB) {
      values.recipient_wallet = '';
    }
    if(activeTab === IBAN_TAB) {
      values.beneficiary_country = null;
      values.bank_name = '';
      values.account_number = '';
      values.bic = '';
    }
    setActiveTab(activeTab);
  };

  const onAmountIBANChange = (e) => {
    setShowMethodCommission(false);
    handleChange(e);
  };

  const getCommissionValue = () => {
    if( !commission.value && !commission.currency && !commission.type) {
      return '0.00';
    }
    return `${amountFormattedValue(commission.value)} ${commission.currency}`;
  };

  const handleIbanComplete = async() => {
    values.recipient_wallet && await checkIban(values.recipient_wallet);
    await handleGetCommission();
  };

  const bottomPartProps = {
    values,
    errors,
    error,
    handleChange,
    handleSubmit,
    serverFieldsErrors,
    onAmountIBANChange,
    handleGetCommission,
    showMethodCommission,
    getCommissionValue,
    paymentMethod,
    isSubmitDisabled: disabled ||
      isLoading ||
      isCommissionLoading ||
      !!Object.keys(errors).length ||
      !!Object.keys(serverFieldsErrors).length ||
      !paymentMethod,
    isLoading: isLoading || isCommissionLoading,
    uploadedFiles,
    uploadDocuments,
    removePaymentFile
  };

  return (
    <>
      <div className="navigation-payment">
        <IconButton
          className="navigation-payment-button"
          text={ i18n.getMessage('transfer.label.iban') }
          active={ activeTab === IBAN_TAB }
          onClick={ () => handleActiveTab(IBAN_TAB) }
          Icon={ IbanIcon } />
        <IconButton
          className="navigation-payment-button"
          text={ i18n.getMessage('transfer.divider.advanceForm') }
          active={ activeTab === NON_IBAN_TAB }
          onClick={ () => !isIbanCheckLoading && handleActiveTab(NON_IBAN_TAB) }
          Icon={ NonIbanIcon } />
      </div>
      { activeTab === IBAN_TAB &&
        <Input
          isRequired={ true }
          isDisabled={ !!values.account_number || !!values.bank_name || !!values.bic }
          label={ i18n.getMessage('transfer.form.recipientIban.label') }
          name={ 'recipient_wallet' }
          value={ values.recipient_wallet }
          error={ errors.recipient_wallet || serverFieldsErrors.iban }
          onChange={ onAmountIBANChange }
          onBlur={ handleIbanComplete }
        />
      }
      { activeTab === NON_IBAN_TAB &&
        <>
          <div className="inputs-wrapper-evenly">
            <InputDropDown
              isRequired={ true }
              isDisabled={ !!values.recipient_wallet }
              label={ i18n.getMessage('transfer.form.bankCountry.label') }
              name={ 'bank_country' }
              value={ values.bank_country }
              onChange={ handleRecipientTypeChange }
              options={ COUNTRIES_LIST.map(value => ({ key: value, value: i18n.getMessage(`country.${value}`) })) }
              error={ errors.bank_country || serverFieldsErrors.bank_country }
              onBlur={ handleGetCommission }
            />
            <Input
              isRequired={ true }
              isDisabled={ !!values.recipient_wallet }
              label={ i18n.getMessage('transfer.form.bankName.label') }
              name={ 'bank_name' }
              value={ values.bank_name }
              onChange={ handleChange }
              error={ errors.bank_name || serverFieldsErrors.bank_name }
              onBlur={ handleGetCommission }
            />
          </div>
          <div className="inputs-wrapper-evenly">
            <Input
              isRequired={ true }
              isDisabled={ !!values.recipient_wallet }
              label={ i18n.getMessage('transfer.form.accountNumber.label') }
              name={ 'account_number' }
              value={ values.account_number }
              onChange={ handleChange }
              error={ errors.account_number || serverFieldsErrors.account_number }
              onBlur={ handleGetCommission }
            />
            <Input
              isRequired={ true }
              isDisabled={ !!values.recipient_wallet }
              label={ i18n.getMessage('transfer.form.BIC.label') }
              error={ errors?.bic || serverFieldsErrors?.bic }
              name={ 'bic' }
              value={ values.bic }
              onChange={ handleChange }
              onBlur={ handleGetCommission }
            />
          </div>
        </>
      }
      <div className={ `inputs-wrapper ${ values.recipient_type}` }>
        <InputDropDown
          label={ i18n.getMessage('transfer.form.recipientType.label') }
          name={ 'recipient_type' }
          value={ values.recipient_type }
          onChange={ handleRecipientTypeChange }
          options={ Object.values(RECIPIENT_TYPES).map(value => ({ key: value, value })) }
          isDisabled={ isIbanCheckLoading }
        />
        {
          values.recipient_type === RECIPIENT_TYPES.COMPANY &&
            <Input
              isRequired={ true }
              label={ i18n.getMessage('transfer.form.companyName.label') }
              name={ 'company_name' }
              value={ values.company_name }
              error={ errors.company_name || serverFieldsErrors.companyName }
              onChange={ handleChange }
              onBlur={ handleGetCommission }
              isDisabled={ isIbanCheckLoading }
            />
        }
        {
          values.recipient_type === RECIPIENT_TYPES.INDIVIDUAL &&
            <Input
              isRequired={ true }
              label={ i18n.getMessage('transfer.form.firstName.label') }
              name={ 'first_name' }
              value={ values.first_name }
              error={ errors.first_name || serverFieldsErrors.firstName }
              onChange={ handleChange }
              onBlur={ handleGetCommission }
              isDisabled={ isIbanCheckLoading }
            />
        }
        {
          values.recipient_type === RECIPIENT_TYPES.INDIVIDUAL &&
            <Input
              isRequired={ true }
              label={ i18n.getMessage('transfer.form.lastName.label') }
              name={ 'last_name' }
              value={ values.last_name }
              error={ errors.last_name || serverFieldsErrors.lastName }
              onChange={ handleChange }
              onBlur={ handleGetCommission }
              isDisabled={ isIbanCheckLoading }
            />
        }
      </div>
      <div className="inputs-wrapper-evenly">
        <Input
          isRequired={ true }
          label={ i18n.getMessage('transfer.form.registrationNumber.label') }
          name={ 'registration_number' }
          value={ values.registration_number }
          onChange={ handleChange }
          error={ errors.registration_number || serverFieldsErrors.registration_number }
          onBlur={ handleGetCommission }
          isDisabled={ isIbanCheckLoading }
        />
        <Input
          isRequired={ true }
          label={ i18n.getMessage('transfer.form.beneficiaryCity.label') }
          name={ 'beneficiary_city' }
          value={ values.beneficiary_city }
          onChange={ handleChange }
          error={ errors.beneficiary_city || serverFieldsErrors.beneficiary_city }
          onBlur={ handleGetCommission }
          isDisabled={ isIbanCheckLoading }
        />
      </div>
      <div className="inputs-wrapper-evenly">
        <InputDropDown
          isRequired={ true }
          label={ i18n.getMessage('transfer.form.beneficiaryCountry.label') }
          name={ 'beneficiary_country' }
          value={ values.beneficiary_country }
          onChange={ handleRecipientTypeChange }
          options={ COUNTRIES_LIST.map(value => ({ key: value, value: i18n.getMessage(`country.${value}`) })) }
          error={ errors.beneficiary_country || serverFieldsErrors.beneficiary_country }
          onBlur={ handleGetCommission }
          isDisabled={ isIbanCheckLoading }
        />
        <Input
          isRequired={ true }
          label={ i18n.getMessage('transfer.form.beneficiaryPostalCode.label') }
          name={ 'beneficiary_post_code' }
          value={ values.beneficiary_post_code }
          onChange={ handleChange }
          error={ errors.beneficiary_post_code || serverFieldsErrors.beneficiary_post_code }
          onBlur={ handleGetCommission }
          isDisabled={ isIbanCheckLoading }
        />
      </div>
      <Input
        isRequired={ true }
        label={ i18n.getMessage('transfer.form.beneficiaryAddress.label') }
        name={ 'beneficiary_address' }
        value={ values.beneficiary_address }
        onChange={ handleChange }
        error={ errors.beneficiary_address || serverFieldsErrors.beneficiary_address }
        onBlur={ handleGetCommission }
        isDisabled={ isIbanCheckLoading }
      />
      <BottomPartForm { ...bottomPartProps } />
    </>
  );
};

OpenPaydAdvancedForm.propTypes = {
  accountNumber: PropTypes.string,
  getCommission: PropTypes.func.isRequired,
  uploadDocuments: PropTypes.func.isRequired,
  checkIban: PropTypes.func.isRequired,
  isIbanCheckLoading: PropTypes.bool,
  validationSchema: PropTypes.object,
  wallet: PropTypes.object,
  paymentMethod: PropTypes.string,
  commission: PropTypes.object,
  isCommissionLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  uploadedFiles: PropTypes.array,
  removePaymentFile: PropTypes.func,
  commonForm: PropTypes.object,
  currency: PropTypes.string,
  isSuccess: PropTypes.bool
};

export default OpenPaydAdvancedForm;
