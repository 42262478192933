import { Outlet } from 'react-router-dom';
import { ReactComponent as IconLogo } from '../../assets/logo.svg';
import Footer from '../common/Footer';

const AuthorizationLayout = () => {
  return (
    <div className="page authorization">
      <main className="content">
        <IconLogo className="icon-logo" />
        <Outlet/>
      </main>
      <Footer />
    </div>
  );
};

export default AuthorizationLayout;
