import { makeAutoObservable, runInAction } from 'mobx';
import { exportCurrentPDF, exportPDFList, getTransactionList, exportTransactionListCSV } from 'services/requestAgent';
import { convertDateToServerFilter, debounce } from 'services/utils';
import { PREDEFINED_FILTERS, getFromDate } from 'components/common/LatestTransactions/utils';

const getFilters = (rangeFilter) => {
  const { from, to } =  getFromDate(rangeFilter);
  return {
    search_text: '',
    from_date: new Date(from) || null,
    to_date: new Date(to) || null,
    currencies: [],
    wallets: [],
    types: [],
    transfer_types: [],
    statuses: []
  };
};

class TransactionsStore {
  isFileLoading = false;
  isLoading = false;
  error = null;
  transactionList = [];
  latestTransactionList = [];
  totalElements = null;
  totalPages = null;
  rangeFilter = PREDEFINED_FILTERS.WEEK;
  filters = getFilters(this.rangeFilter);

  pagination = {
    size: 7,
    page: 0
  };

  latestTransactionSort = {
    sort_column: 'updated_at',
    sort_direction: 'DESC',
    size: 5
  };

  transactionSort = {
    sort_column: null,
    sort_direction: null
  };

  constructor() {
    makeAutoObservable(this);
  }

  setIsFileLoading(status) {
    this.isFileLoading = status;
    this.error = null;
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  setWallet(wallet) {
    this.filters.wallets = [wallet.wallet_number];
  }

  setFilter(fieldName, value) {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  }

  setRangeFilter(filterType, dateRange) {
    this.pagination.page = 0;
    this.rangeFilter = filterType;
    this.filters.from_date = new Date(dateRange.from);
    this.filters.to_date = new Date(dateRange.to);
  }

  clearPredefinedFilter() {
    this.rangeFilter = null;
  }

  clearFilters() {
    this.rangeFilter = PREDEFINED_FILTERS.WEEK;
    this.filters = getFilters(this.rangeFilter);
  }

  setTransactionPage(page) {
    this.pagination.page = page;
  }

  setSearchTextFilter(accountNumber) {
    return (value) => {
      this.filters.search_text = value;
      this.pagination.page = 0;
      this.getTransactionsWithDebounce(accountNumber);
    };
  }

  setTransactionSort(sortData) {
    this.transactionSort.sort_column = sortData.sort_column;
    this.transactionSort.sort_direction = sortData.sort_direction;
  }

  async getLatestTransactions(accountNumber) {
    try {
      this.setIsLoading(true);
      const { content: data } = await getTransactionList(accountNumber, this.latestTransactionSort);
      runInAction(() => {
        this.latestTransactionList = data;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  getTransactionsWithDebounce = debounce(this.getTransactions, 400);

  async getTransactions(accountNumber) {
    try {
      this.setIsLoading(true);
      const query = {
        ...this.filters,
        ...this.pagination,
        from_date: convertDateToServerFilter(this.filters.from_date),
        to_date: convertDateToServerFilter(this.filters.to_date),
        ...this.transactionSort
      };

      const { 
        content: data,
        total_elements,
        total_pages,
        number,
        size 
      } = await getTransactionList(accountNumber, query);

      runInAction(() => {
        this.totalElements = total_elements;
        this.totalPages = total_pages;
        this.pagination.size = size;
        this.pagination.page = number;
        this.transactionList = data;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async downloadCurrentPDF(accountNumber, transactionNumber, type) {
    try {
      this.setIsFileLoading(true);
      await exportCurrentPDF(accountNumber, transactionNumber, type);
      this.setIsFileLoading(false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isFileLoading = false;
      });
      throw err;
    }
  }

  async downloadPDFList(accountNumber) {
    const query = {
      ...this.filters,
      ...this.pagination,
      from_date: convertDateToServerFilter(this.filters.from_date),
      to_date: convertDateToServerFilter(this.filters.to_date),
      ...this.transactionSort
    };

    try {
      this.setIsFileLoading(true);
      await exportPDFList(accountNumber, query);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isFileLoading = false;
      });
      throw err;
    }
  }

  async downloadCSVList(accountNumber) {
    const query = {
      ...this.filters,
      ...this.pagination,
      from_date: convertDateToServerFilter(this.filters.from_date),
      to_date: convertDateToServerFilter(this.filters.to_date),
      ...this.transactionSort
    };

    try {
      this.setIsFileLoading(true);
      await exportTransactionListCSV(accountNumber, query);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isFileLoading = false;
      });
      throw err;
    }
  }
}

export default new TransactionsStore();
