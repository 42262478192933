import PropTypes from 'prop-types';
import { classNames } from 'uikit/utils';
import { useState } from 'react';
import { ReactComponent as PersonalIcon } from './Personal.svg';
import { ReactComponent as BusinessIcon } from './Business.svg';
import { REGISTRATION_ACCOUNT_TYPE } from 'components/common/constants';

import './RadioGroup.scss';

export const RadioGroup = ({ radioProps, onChange, checkedValue, classes, disableLeft }) => {
  const [selected, setSelected] = useState( disableLeft ? REGISTRATION_ACCOUNT_TYPE.BUSINESS : checkedValue);
  const radioClasses = classNames({
    'radio-group': true,
    'disabled-left': disableLeft,
    [classes]: true
  });

  const changeType = (type) => {
    if( !disableLeft ) {
      onChange(type);
      setSelected(type);
    }
  };

  return (
    <div className={ radioClasses }>
      <button className={ `radio-button unset ${selected === REGISTRATION_ACCOUNT_TYPE.PERSONAL ? 'selected' : ''}` } 
        onClick={ () => changeType(REGISTRATION_ACCOUNT_TYPE.PERSONAL) }>
        <PersonalIcon />
        <p>{ radioProps[0].value }</p>
      </button>
      <button className={ `radio-button unset ${selected === REGISTRATION_ACCOUNT_TYPE.BUSINESS? 'selected' : ''}` } 
        onClick={ () => changeType(REGISTRATION_ACCOUNT_TYPE.BUSINESS) }>
        <BusinessIcon />
        <p>{ radioProps[1].value }</p>
      </button>
    </div>
  );
};

RadioGroup.propTypes = {
  radioProps: PropTypes.array,
  onChange: PropTypes.func,
  checkedValue: PropTypes.string,
  disableLeft: PropTypes.bool
};
