/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import i18nContext from 'components/i18n-context';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { RECIPIENT_TYPES } from 'components/common/constants';
import { amountFormattedValue, prepareFieldErrors } from 'services/utils';
import clearJunctionValidation from './ModelValidators/clearJunctionValidation';
import { clearJunctionModel } from './Models';
import BottomPartForm from './BottomPartForm';

const CleatJunctionTable = ({ 
  accountNumber,
  getCommission,
  uploadDocuments,
  wallet,
  paymentMethod,
  uploadedFiles,
  commission,
  removePaymentFile,
  error,
  isCommissionLoading,
  isLoading,
  isSuccess,
  validationProps
}) => {
  const i18n = useContext(i18nContext);
  const model = clearJunctionModel;
  const validationSchema = clearJunctionValidation({ ...validationProps, model });
  const form = useFormik(validationSchema);
  const serverFieldsErrors = prepareFieldErrors(i18n, error);
  const [disabled, setDisabled] = useState(true);
  const [ showMethodCommission, setShowMethodCommission ] = useState(false);

  useEffect(() => {
    handleGetCommission();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      resetForm({});
    }
    setDisabled(true);
  }, [isSuccess]);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    validateForm, 
    resetForm
  } = form;

  const handleGetCommission = async() => {
    if(
      (values.recipient_wallet || (values.account_number && values.sort_code)) &&
      values.amount &&
      values.recipient_type &&
      values.comment &&
      (values.recipient_type === RECIPIENT_TYPES.COMPANY ? 
        values.company_name : 
        (values.first_name && values.last_name)
      )
    ) {
      const errorsResult = await validateForm();
      if(!Object.keys(errorsResult).length) {
        await getCommission(
          accountNumber,
          model({ 
            providerTableData: values,
            data: { wallet }
          })
        );
        setShowMethodCommission(true);
        setDisabled(false);
      }
    }
  };

  const handleRecipientTypeChange = (name, data) => handleChange({ target: { name, value: data } });
  const onAmountIBANChange = (e) => {
    setShowMethodCommission(false);
    handleChange(e);
  };

  const getCommissionValue = () => {
    if( !commission.value && !commission.currency && !commission.type) {
      return '0.00';
    }
    return `${amountFormattedValue(commission.value)} ${commission.currency}`;
  };

  const bottomPartProps = {
    values,
    errors,
    error,
    handleChange,
    handleSubmit,
    serverFieldsErrors,
    onAmountIBANChange,
    handleGetCommission,
    showMethodCommission,
    getCommissionValue,
    paymentMethod,
    isSubmitDisabled: disabled ||
      isLoading ||
      isCommissionLoading ||
      !!Object.keys(errors).length ||
      !!Object.keys(serverFieldsErrors).length ||
      !paymentMethod,
    isLoading: isLoading || isCommissionLoading,
    uploadedFiles,
    uploadDocuments,
    removePaymentFile
  };

  return (
    <>
      <div className="inputs-wrapper">
        <Input
          isRequired={ true }
          isDisabled={ !!values.account_number || !!values.sort_code }
          label={ i18n.getMessage('transfer.form.recipientIban.label') }
          name={ 'recipient_wallet' }
          value={ values.recipient_wallet }
          error={ !values.account_number && errors.recipient_wallet || serverFieldsErrors.iban }
          onChange={ onAmountIBANChange }
          onBlur={ handleGetCommission }
        />
      </div>
      <div className={ `inputs-wrapper ${ values.recipient_type}` }>
        <InputDropDown
          label={ i18n.getMessage('transfer.form.recipientType.label') }
          name={ 'recipient_type' }
          value={ values.recipient_type }
          onChange={ handleRecipientTypeChange }
          options={ Object.values(RECIPIENT_TYPES).map(value => ({ key: value, value })) }
        />
        {
          values.recipient_type === RECIPIENT_TYPES.COMPANY &&
            <Input
              isRequired={ true }
              label={ i18n.getMessage('transfer.form.companyName.label') }
              name={ 'company_name' }
              value={ values.company_name }
              error={ errors.company_name || serverFieldsErrors.companyName }
              onChange={ handleChange }
              onBlur={ handleGetCommission }
            />
        }
        {
          values.recipient_type === RECIPIENT_TYPES.INDIVIDUAL &&
            <Input
              isRequired={ true }
              label={ i18n.getMessage('transfer.form.firstName.label') }
              name={ 'first_name' }
              value={ values.first_name }
              error={ errors.first_name || serverFieldsErrors.firstName }
              onChange={ handleChange }
              onBlur={ handleGetCommission }
            />
        }
        {
          values.recipient_type === RECIPIENT_TYPES.INDIVIDUAL &&
            <Input
              isRequired={ true }
              label={ i18n.getMessage('transfer.form.lastName.label') }
              name={ 'last_name' }
              value={ values.last_name }
              error={ errors.last_name || serverFieldsErrors.lastName }
              onChange={ handleChange }
              onBlur={ handleGetCommission }
            />
        }
      </div>
      <BottomPartForm { ...bottomPartProps } />
    </>
  );
};

CleatJunctionTable.propTypes = {
  accountNumber: PropTypes.string,
  getCommission: PropTypes.func.isRequired,
  uploadDocuments: PropTypes.func.isRequired,
  wallet: PropTypes.object,
  paymentMethod: PropTypes.string,
  commission: PropTypes.object,
  isCommissionLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  uploadedFiles: PropTypes.array,
  removePaymentFile: PropTypes.func,
  isSuccess: PropTypes.bool,
  validationProps: PropTypes.object.isRequired
};

export default CleatJunctionTable;
