import PropTypes from 'prop-types';
import { ReactComponent as InfoIcon } from './details.svg';
import { ReactComponent as NewPaymentIcon } from './new-payment.svg';
import { ReactComponent as TransactionIcon } from './transactions.svg';
import './ExclamationPoint.scss';

export const ActionButton = ({ onClick, iconType, tooltipText }) => {
  const getIcon = (iconType) => {
    if( iconType === 'new-payment') return <NewPaymentIcon className="exclamation-point-icon" />;
    if( iconType === 'transaction') return <TransactionIcon className="exclamation-point-icon" />;
    return <InfoIcon className="exclamation-point-icon" />;
  };

  return (
    <button className="exclamation-wrapper" onClick={ () => onClick() }>
      { tooltipText && <div className="tooltip"><p className="tooltip-text">{ tooltipText }</p></div> }
      { getIcon(iconType) }
    </button>
  );
};

ActionButton.propTypes = {
  onClick: PropTypes.func,
  iconType: PropTypes.string,
  tooltipText: PropTypes.string
};
