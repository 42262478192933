import { makeAutoObservable, runInAction } from 'mobx';
import { getLoginAttempts } from 'services/requestAgent';
import { convertDate } from '../services/utils';
import { getFromDate, PREDEFINED_FILTERS } from '../components/common/LatestTransactions/utils';

const getFilters = (rangeFilter) => {
  const { from, to } = getFromDate(rangeFilter);
  return {
    from_date: new Date(from) || null,
    to_date: new Date(to) || null,
    status: [ 'SUCCESS', 'FAIL']
  };
};

class ActivityLogStore {
  activityLogList = [];
  isLoading = false;
  error = null;
  totalElements = null;
  totalPages = null;
  pagination = {
    size: 20,
    page: 0
  };
  rangeFilter = PREDEFINED_FILTERS.WEEK;
  filters = getFilters(this.rangeFilter);

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  setActivityPage( pageNumber ) {
    this.pagination.page = pageNumber;
  }

  setFilter(fieldName, value) {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  }

  clearPredefinedFilter() {
    this.rangeFilter = null;
  }

  async getLoginAttempts() {
    try {
      this.setIsLoading(true);
      const query = {
        status: this.filters.status,
        ...this.pagination,
        from: convertDate(this.filters.from_date),
        to: convertDate(this.filters.to_date, true)
      };
      const { content:data, total_elements, total_pages, size , number } = await getLoginAttempts(query);
      runInAction(() => {
        this.activityLogList = data;
        this.totalElements = total_elements;
        this.totalPages = total_pages;
        this.pagination.size = size;
        this.pagination.page = number;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

}

export default new ActivityLogStore();
