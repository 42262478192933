/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { TransactionDateFilter } from 'components/common/LatestTransactions/TransactionDateFilter';
import i18nContext from 'components/i18n-context';
import './ActivityLog.scss';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Pagination from 'components/common/Pagination/Pagination';
import Loader from 'components/common/Loader';
import { ReactComponent as LatestIcon } from 'assets/latest-transactions.svg';
import { ActivityLogTable } from './ActivityLogTable';

const ActivityLog = ({ activityLogStore }) => {
  const i18n = useContext(i18nContext);

  useEffect(() => {
    activityLogStore.getLoginAttempts();
  }, []);

  const ACTIVITY_LOG_TABLE_HEADER = [
    i18n.getMessage('activityLog.header.device'),
    i18n.getMessage('activityLog.header.lastLogin'),
    i18n.getMessage('activityLog.header.status'),
    i18n.getMessage('activityLog.header.ip'),
    i18n.getMessage('activityLog.header.browser')
  ];

  const ACTIVITY_LOG_TABLE_STATUS = [
    { key: 'SUCCESS', value: i18n.getMessage('activityLog.text.success') },
    { key: 'FAIL', value: i18n.getMessage('activityLog.text.fail') }
  ];

  const activityStatusOptions = ACTIVITY_LOG_TABLE_STATUS.map(type => {
    return {
      key: type.key,
      value: type.value
    };
  });

  const handleSetDateFilter = (name, value) => {
    if (name !== 'status') activityLogStore.clearPredefinedFilter();
    activityLogStore.setFilter(name, value);
    activityLogStore.getLoginAttempts();
  };

  const handleSetCurrentPage = ( pageNumber ) => {
    activityLogStore.setActivityPage( pageNumber );
    activityLogStore.getLoginAttempts();
  };

  const renderActivityTable = () => {
    if (activityLogStore.isLoading) return <Loader />;
    if (activityLogStore.activityLogList?.length === 0) return <div className="activity-empty">
      <LatestIcon className="empty-transactions-icon" />
      <h2>{ i18n.getMessage('activityLog.text.noActivities') }</h2>
    </div>;

    return <>
      <ActivityLogTable
        activityHeader={ ACTIVITY_LOG_TABLE_HEADER }
        activityLogList={ activityLogStore.activityLogList } />
      <Pagination
        className="activity-pagination"
        currentPage={ activityLogStore.pagination.page }
        numberOfPages={ activityLogStore.totalPages }
        setCurrentPage={ handleSetCurrentPage }
      />
    </>;
  };

  return (
    <div className="activity">
      <form action="#" className="activity-filters">
        <TransactionDateFilter
          name="from_date"
          value={ activityLogStore.filters.from_date }
          onChange={ handleSetDateFilter }
          label={ i18n.getMessage('transaction.text.from') }
        />
        <TransactionDateFilter
          name="to_date"
          value={ activityLogStore.filters.to_date }
          onChange={ handleSetDateFilter }
          label={ i18n.getMessage('transaction.text.to') }
        />
        <InputDropDown className="wallet"
          name="status"
          options={ activityStatusOptions }
          value={ activityLogStore.filters.status }
          onChange={ handleSetDateFilter }
          placeholder={ i18n.getMessage('activityLog.header.status') }
          isMulti
        />
      </form>
      { renderActivityTable() }
    </div>
  );
};

ActivityLog.propTypes = {
  activityLogStore: MobXPropTypes.observableObject
};

export default inject('activityLogStore')(observer(ActivityLog));
