import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CopyIcon } from 'assets/copy-icon.svg';
import './ClipBoard.scss';

export const ClipBoard = ({ copyText }) => {
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  const copyToClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowCopyNotification(true);
      setTimeout(() => {
        setShowCopyNotification(false);
      }, 2000);
    } catch (err) {
      console.log('failed to copy from clipboard:', err);
    }
  };

  return (
    <div className="clipboard-wrapper">
      <CopyIcon className="clipboard-icon" onClick={ () => copyToClipBoard(copyText) } />
      { showCopyNotification && <div className="clipboard-notification"><p>Field copied</p></div> }
    </div>
  );
};

ClipBoard.propTypes = {
  copyText: PropTypes.string
};
