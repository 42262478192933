/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import SumsubWebSdk from '@sumsub/websdk-react';

import { Container } from 'uikit/Container/Container';
import { PopUp } from 'uikit/PopUp/PopUp';
import PopUpSuccessScheme from 'components/common/PopUpScheme/PopUpSuccessScheme';
import { getSumsubAccessToken, setUnderReviewStatus } from 'services/requestAgent';

import i18nContext from 'components/i18n-context';

import './Verification.scss';
import PropTypes from 'prop-types';


const Sumsub = ({ email, accountNumber, levelName }) => {
  const i18n = useContext(i18nContext);
  const [accessToken, setAccessToken] = useState(null);
  const [isApplicantSubmitted, setIsApplicantSubmitted] = useState(false);

  useEffect(() => {
    getAccessToken();
  }, []);

  const getAccessToken = async () => {
    const { token: accessToken } = await getSumsubAccessToken(accountNumber, levelName);
    setAccessToken(accessToken);
  };

  const handleSubmittedSuccessOk = () => setIsApplicantSubmitted(false);

  const handleOnMessage = (type) => {
    if(type === 'idCheck.onApplicantSubmitted') {
      setUnderReviewStatus(accountNumber);
      setIsApplicantSubmitted(true);
    }
    if(type === 'idCheck.onApplicantResubmitted') {
      setIsApplicantSubmitted(true);
    }
  };

  return (
    <section className="right-section">
      <Container className="send-money" header={ i18n.getMessage('container.verification') }>
        <div className="sumsub">
          {
            accessToken &&
              <SumsubWebSdk
                accessToken={ accessToken }
                expirationHandler={ getAccessToken }
                config={ {
                  email: email
                } }
                onMessage={ handleOnMessage }
              />
          }
        </div>
        <PopUp
          show={ isApplicantSubmitted }
          alignOnCenter>
          <PopUpSuccessScheme
            message={ i18n.getMessage('verification.submitted.success.message') }
            onClose={ handleSubmittedSuccessOk } />
        </PopUp>
      </Container>
    </section>
  );
};

Sumsub.propTypes = {
  email: PropTypes.string,
  accountNumber: PropTypes.string,
  levelName: PropTypes.string
};

export default Sumsub;
