import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from '../i18n-context';
import { ReactComponent as SuccessIcon } from 'assets/message_success.svg';

export const LinkedSuccessScheme = ({ closePopUp, message }) => {
  const i18n = useContext(i18nContext);
  
  return (
    <div className="scheme-representatives-wrapper pop-up-success">
      <SuccessIcon />
      <h2 className="scheme-success-header">{ message }</h2>
      <button className="scheme-success-button next-button"
        onClick={ closePopUp }
      >{ i18n.getMessage('representatives.label.next') }</button>
    </div>
  );
};

LinkedSuccessScheme.propTypes = {
  closePopUp: PropTypes.func.isRequired,
  message: PropTypes.string
};
