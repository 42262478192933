import { useContext, useState } from 'react';
import './Settings.scss';
import { IconButton } from 'uikit/IconButton/IconButton';
import { ReactComponent as PasswordResetIcon } from 'assets/shield.svg';
import { ReactComponent as ActivityLogIcon } from 'assets/activity-log.svg';
import i18nContext from 'components/i18n-context';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { PopUp } from '../../uikit/PopUp/PopUp';
import PopUpSuccessScheme from '../common/PopUpScheme/PopUpSuccessScheme';
import { Container } from '../../uikit/Container/Container';
import PasswordReset from './PasswordReset';
import ActivityLog from './ActivityLog/ActivityLog';

const Settings = ({ passwordRecoveryStore }) => {
  const i18n = useContext(i18nContext);
  const [activeTab, setActiveTab] = useState('passwordReset');

  return (
    <section className="right-section settings">
      <Container header={ i18n.getMessage('navigation.settings.text') }>
        <div className="settings-wrapper">
          <div className="settings-container-left">
            <IconButton
              className={ `settings-icon ${ activeTab === 'passwordReset' ? 'active' : ''}` }
              active={ activeTab === 'passwordReset' }
              onClick={ () => setActiveTab('passwordReset') }
              Icon={ PasswordResetIcon }
              text={ i18n.getMessage('settings.tab.label.passwordReset') } />
            <IconButton
              className={ `settings-icon ${ activeTab === 'activityLog' ? 'active' : ''}` }
              active={ activeTab === 'activityLog' }
              onClick={ () => setActiveTab('activityLog') }
              Icon={ ActivityLogIcon }
              text={ i18n.getMessage('settings.tab.label.activityLog') } />
          </div>
          { activeTab === 'passwordReset' && <PasswordReset /> }
          { activeTab === 'activityLog' && <ActivityLog /> }
        </div>
      </Container>
      <PopUp show={ passwordRecoveryStore.isResetPasswordSuccess }>
        <PopUpSuccessScheme
          message={ i18n.getMessage('settings.passwordReset.success') }
          onClose={ () => passwordRecoveryStore.reset() } />
      </PopUp>
    </section>
  );
};

Settings.propTypes = {
  passwordRecoveryStore: MobXPropTypes.observableObject
};

export default inject( stores => ({
  passwordRecoveryStore: stores.passwordRecoveryStore,
  isAccountVerified: stores.userStore.isAccountVerified
}))(observer(Settings));
