import PropTypes from 'prop-types';
import { parseDate } from 'components/utils';
import { ReactComponent as CalendarIcon } from 'assets/calendar-icon.svg';

export const ActivityLogTable = ({ activityLogList, activityHeader }) => {
  return (
    <div className="activity-table">
      <div className="activity-head">{
        activityHeader.map(headColumn =>
          <p key={ headColumn } className="head-Column">{ headColumn }</p>)
      }</div>
      {
        activityLogList.map((activity, index) => {
          const createdAt = parseDate(activity.createdAt);
          return <div className="activity-list-item" key={ activity?.createdAt || index }>
            <div className="activity-device">
              <p className="card-mobile-text"> { activityHeader[0] }</p>
              <p className="activity-text">{ activity?.userAgent?.deviceType?.toLowerCase() }</p>
            </div>
            <div className="activity-lastLogin">
              <p className="card-mobile-text"> { activityHeader[1] }</p>
              <div className="item-mobile-date">
                <CalendarIcon className="card-mobile-text icon"/>
                <p>{ createdAt.date }</p>
                <p>{ createdAt.time }</p>
              </div>
            </div>
            <div className="activity-status">
              <p className="card-mobile-text"> { activityHeader[2] }</p>
              <p className={ `activity-text status ${activity?.status === 'FAIL' ? 'error' : ''}` }>
                { activity?.status.toLowerCase() }
              </p>
            </div>
            <div className="activity-ip">
              <p className="card-mobile-text"> { activityHeader[3] }</p>
              <p className="activity-text">{ activity?.ip }</p>
            </div>
            <div className="activity-browser">
              <p className="card-mobile-text"> { activityHeader[4] }</p>
              <p className="activity-text">{ activity?.userAgent?.operatingSystem }</p>
              <p className="activity-text">
                { `${activity?.userAgent?.browser} ${activity?.userAgent?.browserVersion}` }
              </p>
            </div>
          </div>;
        }) }
    </div>
  );
};

ActivityLogTable.propTypes = {
  activityLogList: PropTypes.array,
  activityHeader: PropTypes.array
};
