import PropTypes from 'prop-types';

import { IconButton } from 'uikit/IconButton/IconButton';
import { getNavigationButtons } from 'components/common/MenuButtons';
import './NavigationBar.scss';
import UserPanel from '../Header/UserPanel';

const NavigationBar = ({ compact, isAccountVerified, isMobile, collapseMenu }) => {

  const onClick = (buttonClick) => {
    buttonClick && buttonClick();
    collapseMenu();
  };

  return (
    <nav id="navbar" className={ `navbar ${ compact  ? 'collapsed' : ''}` }>
      <div className="navigation-wrapper">
        { getNavigationButtons(isAccountVerified).filter(button => !button.isDisabled).map( button =>
          <IconButton
            key={ button.id }
            navigationType
            onClick={ isMobile ? () => onClick( button.onClick ) : null }
            path={ button.path }
            text={ compact && !isMobile ? '' : button.text }
            Icon={ button.icon }
            active={ false }
            isDisabled={ button.isDisabled }
          />
        ) }
        { isMobile &&  <UserPanel isMobile={ isMobile } onClick={ onClick } /> }
      </div>
    </nav>
  );
};

NavigationBar.propTypes = {
  collapseMenu: PropTypes.func,
  isMobile: PropTypes.bool,
  isAccountVerified: PropTypes.bool,
  compact: PropTypes.bool.isRequired
};

export default NavigationBar;
