import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Container } from '../../../uikit/Container/Container';
import i18nContext from '../../i18n-context';
import { ReactComponent as LockIcon } from 'assets/lock-permission.svg';
import './DontHavePermission.scss';

export const DontHavePermission = ({ headerText }) => {
  const i18n = useContext(i18nContext);
  
  return (
    <section className="right-section dont-permission">
      <Container header={ headerText }>
        <div className="dont-permission-wrapper">
          <LockIcon className="dont-permission-icon" />
          <p className="dont-permission-text">{ i18n.getMessage('noPermission.text') }</p>
        </div>
      </Container>
    </section>
  );
};

DontHavePermission.propTypes = {
  headerText: PropTypes.string
};
