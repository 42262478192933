import i18n from '../../i18n';

export const REGISTRATION_ACCOUNT_TYPE = {
  PERSONAL: 'PERSONAL',
  BUSINESS: 'BUSINESS'
};

export const ACCOUNT_TYPE = {
  INDIVIDUAL: 'individual',
  COMPANY: 'corporate'
};

export const ACCOUNT_LEVEL = {
  ZERO: 'LEVEL_0'
};

export const REGISTRATION_STATUSES = {
  REGISTRATION_STATUS_EMAIL_CONFIRMATION: 'EMAIL_CONFIRMATION',
  REGISTRATION_STATUS_SECOND_FACTOR_CONFIRMATION_PHONE: 'PHONE_SETUP',
  REGISTRATION_STATUS_PHONE_CONFIRMATION: 'PHONE_CONFIRMATION',
  REGISTRATION_STATUS_CONFIRMED: 'CONFIRMED'
};

export const PASSWORD_RECOVERY_STATUSES = {
  EMAIL_CONFIRMATION: 'EMAIL_CONFIRMATION', // custom front status for rendering
  PASSWORD_SET: 'PASSWORD_SET' // API status
};

export const ICON_BUTTONS_TYPE = {
  NAVIGATION: 'NAVIGATION',
  MENU: 'MENU'
};

export const USER_ACCOUNT_STATUSES = {
  NEW: 'NEW',
  CREATED: 'CREATED',
  VERIFICATION: 'VERIFICATION',
  VERIFIED: 'VERIFIED',
  VIRTUAL: 'VIRTUAL'
};

export const KYC_STATUSES = {
  NEW: 'NEW',
  UNDER_REVIEW: 'UNDER_REVIEW',
  VERIFIED: 'VERIFIED',
  DECLINED: 'DECLINED'
};

export const TRANSACTIONS_TABLE_HEADER = [
  'date',
  'status',
  'fromTo',
  'purpose',
  'amountCommission',
  ''
];
export const TRANSACTIONS_TABLE_STATUS = {
  created: 'CREATED',
  onHold: 'ON_HOLD',
  pending: 'PENDING',
  processing: 'PROCESSING',
  completed: 'PAID',
  rejected: 'REJECTED',
  failed: 'FAILED'
};

export const WALLET_PROPERTIES = {
  IBAN: 'iban',
  MULTI_IBAN: 'multi_iban',
  WALLET_NUMBER: 'wallet_number',
  MULTI_WALLET_NUMBER: 'multi_wallet_number',
  AVAILABLE: 'available',
  CURRENCY: 'currency',
  FROZEN: 'frozen',
  MINIMAL: 'minimal',
  ON_HOLD: 'on_hold',
  STATUS: 'status',
  TOTAL: 'total',
  TRANSFER_PROVIDER: 'transfer_provider',
  TYPE: 'type'
};

export const TRANSACTION_TYPES = {
  INCOMING: 'INCOMING',
  OUTGOING: 'OUTGOING'
};

export const RECIPIENT_TYPES = {
  INDIVIDUAL: 'Individual',
  COMPANY: 'Company'
};


export const WALLET_TOOLTIP_TEXT = [
  i18n.getMessage('tooltip.Transactions'),
  i18n.getMessage('tooltip.newPayment'),
  i18n.getMessage('tooltip.accountDetails')
];

export const SESSION_STORAGE_CONSTANTS = {
  SESSION_ENDED: 'session ended'
};

export const SESSION_STORAGE_OPTIONS = {
  PASSWORD_RECOVERY: 'passwordRecovery',
  SESSION_ENDED: 'sessionEnded'
};

export const SMS_ERROR_CODE_DISABLE = [
  'CONFIRMATION_CODE_TRIES_EXCEED',
  'CONFIRMATION_CODE_IS_OUT_OF_DATE',
  'CODE_SEND_TIMEOUT'
];

export const CURRENCY_SYMBOLS = {
  EUR: '€',
  GBP: '£',
  USD: '$'
};

export const ACCOUNT_PERMISSIONS = {
  READ_ACCOUNT: 'READ_ACCOUNT',
  CREATE_TRANSACTION: 'CREATE_TRANSACTION',
  CREATE_CURRENCY_EXCHANGE: 'CREATE_CURRENCY_EXCHANGE',
  CREATE_REPORT: 'CREATE_REPORT',
  READ_MESSAGES: 'READ_MESSAGES',
  CREATE_MESSAGE: 'CREATE_MESSAGE'
};

export const PAYMENT_METHODS = {
  SWIFT: 'SWIFT',
  TARGET2: 'TARGET2',
  SEPA: 'SEPA Instant, SEPA',
  FASTER_PAYMENTS: 'Faster Payments'
};

export const PAYMENT_PROVIDERS = {
  CLEARJUNCTION: 'CLEAR_JUNCTION',
  OPENPAYD: 'OPEN_PAYD'
};

export const CURRENCY_TYPES = {
  GBP: 'GBP',
  EUR: 'EUR',
  USD: 'USD'
};

export const FORM_TYPES = {
  SIMPLE: 'simple',
  ADVANCED: 'advanced'
};


export const COUNTRIES_LIST = ['AL','DZ','AS','AD','AO','AI','AQ','AG','AR','AM','AW','AU','AT','AZ','BS','BH','BD',
  'BB','BE','BZ','BJ','BM','BT','BO','BQ','BA','BW','BV','BR','IO','BN','BG','BF','CV','KH','CM','CA','KY','CF','TD',
  'CL','CN','CX','CC','CO','KM','CK','CR','HR','CW','CY','CZ','CI','DK','DJ','DM','DO','EC','EG','SV','GQ',
  'ER','EE','SZ','ET','FK','FO','FJ','FI','FR','GF','PF','TF','GA','GM','GE','DE','GH','GI','GR','GL','GD','GP','GU',
  'GT','GG','GY','HT','HM','VA','HN','HK','HU','IS','IN','ID','IR','IE','IM','IL','IT','JM','JP','JE','JO','KZ','KE',
  'KI','KP','KR','KW','KG','LA','LV','LB','LS','LR','LI','LT','LU','MO','MG','MW','MY','MV','MT','MH','MQ','MR','MU',
  'YT','MX','FM','MD','MC','MN','ME','MS','MA','MZ','NA','NR','NP','NL','NC','NZ','NE','NG','NU','NF','MP','NO','OM',
  'PK','PW','PS','PA','PG','PY','PE','PH','PN','PL','PT','PR','QA','MK','RO','RU','RW','RE','BL','SH','KN','LC','MF',
  'PM','VC','WS','SM','ST','SA','SN','RS','SC','SL','SG','SX','SK','SI','SB','ZA','GS','ES','LK','SD','SR','SJ','SE',
  'CH','SY','TW','TJ','TZ','TH','TL','TG','TK','TO','TT','TR','TM','TC','TV','UG','AE','GB','UM','US','UY','UZ','VU',
  'VE','VN','VG','VI','WF','EH','ZM','AX'];
