import { useContext } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import Button from 'uikit/Button/Button';
import i18nContext from 'components/i18n-context';
import { parseDate } from 'components/utils';
import { amountFormattedValue } from 'services/utils';

import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import { FileDownloadButton } from 'uikit/FileDownloadButton/FileDownloadButton';
import { ACCOUNT_PERMISSIONS, TRANSACTION_TYPES } from 'components/common/constants';

import './PopUpScheme.scss';
import Loader from '../Loader';

const getTransactionDetailsFields = (i18n, transaction) => {
  const createdAt = parseDate(transaction.created_at);
  const updatedAt = parseDate(transaction.updated_at);
  const isOutgoingTransaction = transaction.type === TRANSACTION_TYPES.OUTGOING;
  const amount = isOutgoingTransaction ? transaction.amount : transaction.source_amount;

  return [
    {
      label: i18n.getMessage('transaction.info.label.transactionNumber'), 
      value: transaction.transaction_number
    },
    {
      label: i18n.getMessage('transaction.info.label.createdDate'),
      value: `${createdAt.date} ${createdAt.time}`
    },
    {
      label: i18n.getMessage('transaction.info.label.completedDate'),
      value: `${updatedAt.date} ${updatedAt.time}`
    },
    {
      label: i18n.getMessage('transaction.info.label.transactionStatus'),
      value: i18n.getMessage(`transaction.status.${transaction.status}`)
    },
    {
      label: i18n.getMessage('transaction.info.label.amount'),
      value: `${amountFormattedValue(amount)} ${transaction.currency_code}`
    },
    {
      label: i18n.getMessage('transaction.info.label.commission'),
      value: `${amountFormattedValue(transaction.total_commissions)} ${transaction.currency_code}`
    },
    {
      label: i18n.getMessage('transaction.info.label.totalAmount'),
      value: `${amountFormattedValue(isOutgoingTransaction ? transaction.source_amount : transaction.target_amount)} 
      ${transaction.currency_code}`
    },
    {
      label: i18n.getMessage('transaction.info.label.typeOfTransfer'),
      value: transaction.type
    },
    {
      label: i18n.getMessage('transaction.info.label.paymentMethod'),
      value: transaction.payment_method && i18n.getMessage(`transfer.paymentMethod.${transaction.payment_method}`)
    },
    {
      label: i18n.getMessage('transaction.info.label.recipientName'),
      value: transaction.recipient_name
    },
    {
      label: i18n.getMessage('transaction.info.label.recipientAccount'),
      value: isOutgoingTransaction ? transaction.counterparty.iban : transaction.iban
    },
    {
      label: i18n.getMessage('transaction.info.label.bicSwift'),
      value: transaction.bank_swift_code
    },
    {
      label: i18n.getMessage('transaction.info.label.sendersName'),
      value: transaction.sender_name
    },
    {
      label: i18n.getMessage('transaction.info.label.sendersAccount'),
      value: isOutgoingTransaction ? transaction.iban : transaction.counterparty.iban
    },
    {
      label: i18n.getMessage('transaction.info.label.files'),
      value: transaction.documents?.map(({ name }) => name).join(',\n ')
    },
    {
      label: i18n.getMessage('transaction.info.label.commentToTransfer'),
      value: transaction.comment
    }
  ].filter(field => field.value || field.value === 0);
};


const PopUpTransactionScheme = ({
  selectedTransaction,
  onClose,
  userWallets,
  paymentStore,
  transactionStore,
  accountPermissions
}) => {
  const navigate = useNavigate();
  const i18n = useContext(i18nContext);
  const transactionDetailsFields = getTransactionDetailsFields(i18n, selectedTransaction);
  const isReportDisabled = accountPermissions && !accountPermissions.includes(ACCOUNT_PERMISSIONS.CREATE_REPORT);

  const goToPayment = () => {
    const wallet = userWallets.find( userWallet => userWallet.wallet_number === selectedTransaction.wallet_number );
    paymentStore.resetPaymentStore();
    paymentStore.setSelectedWallet(wallet);
    paymentStore.setPreviousTransactionInfo( selectedTransaction );
    navigate('/new-payment');
  };
  
  const onPDFClick = (e) => {
    e.preventDefault();
    if( selectedTransaction?.is_pdf_available ){
      transactionStore.downloadCurrentPDF(
        selectedTransaction.account_number,
        selectedTransaction.transaction_number,
        selectedTransaction.type
      );
    }
  };

  const renderExportPDFButton = () => {
    if (selectedTransaction.transaction_type === 'COMMISSION') return null;

    return transactionStore.isFileLoading ?
      <Loader className={ 'button-file' } />
      :
      <FileDownloadButton isDisabled={ isReportDisabled } onClick={ onPDFClick } type="PDF" /> ;
  };

  const schemeLine = (label, value) => {
    return <div key={ label } className="scheme-line">
      <p className="left">{ label }:</p>
      <p className="center">{ value }</p>
    </div>;
  };

  return (
    <div className="scheme-wrapper">
      <div className="scheme-header">
        <p className="account">{ i18n.getMessage('popUp.paymentDetails') }</p>
        <CloseIcon className="scheme-close-icon" onClick={ onClose } />
      </div>
      {
        transactionDetailsFields.map(field => schemeLine(field.label, field.value))
      }
      { /* eslint-disable-next-line max-len */ }
      <div className={ `buttons-wrapper ${selectedTransaction.transaction_type === 'COMMISSION' ? 'transaction-commission' : ''}` }>
        { renderExportPDFButton() }
        <Button
          type="outline"
          size="large"
          onClick={ goToPayment }
        >
          { i18n.getMessage('transaction.label.repeatPayment') }
        </Button>
      </div>
    </div>
  );
};

PopUpTransactionScheme.propTypes = {
  selectedTransaction: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  userWallets: PropTypes.array,
  paymentStore: MobXPropTypes.observableObject,
  transactionStore: MobXPropTypes.observableObject,
  accountPermissions: PropTypes.array
};

export default inject(stores => ({
  userWallets: stores.userStore?.userWallets,
  accountPermissions: stores.userStore?.currentAccountPermission,
  paymentStore: stores.paymentStore,
  transactionStore: stores.transactionsStore
}))(observer(PopUpTransactionScheme));
