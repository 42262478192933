import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from '../../i18n-context';
import { PREDEFINED_FILTERS, getFromDate } from './utils';


const TransactionRangeFilter = ({ onChange, value }) => {
  const i18n = useContext(i18nContext);

  return (
    <div className="tabs-wrapper">
      { Object.values(PREDEFINED_FILTERS).map(filterName => <button
        type="button"
        className={ `filter-tab ${ value === filterName ? 'active' : ''}` }
        onClick={ () => onChange(filterName, getFromDate(filterName)) }
        key={ filterName }>
        { i18n.getMessage(`transaction.rangeFilter.${filterName}`) }
      </button>) }
    </div>
  );
};

TransactionRangeFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default TransactionRangeFilter;
