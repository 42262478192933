import request from 'superagent';
import noCache from 'superagent-no-cache';

import {
  getRefreshToken,
  getAccessToken,
  saveTokenInfo,
  logoutActiveSessionWithoutToken,
  setConfirmationActionType,
  setSecureUserPhone,
  setLastUserActivityDateTime,
  resetLastUserActivityDateTime
} from './authUtils';
import { errorMapper } from './Error';

const requestAgent = request.agent().use(noCache);

export const errorState = {
  code: null,
  message: null
};

const {
  REACT_APP_API_URL_PATH_PREFIX,
  REACT_APP_API_HOST_WITH_PORT,
  REACT_APP_OAUTH_PATH,
  REACT_APP_PAYMENT_API_URL_PATH_PREFIX
} = process.env;

// Common method! We need to use it for all API-calls
export const api = async(args) => {
  const {
    path: urlPathPostfix,
    method = 'post',
    query = {},
    data = {},
    pathHost = REACT_APP_API_HOST_WITH_PORT,
    pathPrefix = REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth = true,
    isRequestWithBasicAuth = false,
    securityCode,
    returnFullResponse = false,
    responseType = null
  } = args;

  // eslint-disable-next-line no-console
  console.debug(
    '### API', method.toUpperCase(), 'REQUEST', urlPathPostfix,
    ...(
      (method === 'post' && ['\n', data]) ||
			(method === 'get' && query && Object.keys(query).length && ['\n', query]) ||
			[]
    )
  );

  const req = requestAgent[method](pathHost + pathPrefix + urlPathPostfix);

  if (isRequestWithAuth) {
    req.auth(getAccessToken(), { type: 'bearer' });
  }

  if (isRequestWithBasicAuth) {
    req.auth('web', '');
  }

  if (responseType) {
    req.responseType(responseType);
  }

  if (query && Object.keys(query).length !== 0) {
    req.query(query);
  }

  if (method === 'post') {
    req.send(data);
  }

  if (securityCode) {
    req.set('Confirmation-Code', securityCode);
  }

  setLastUserActivityDateTime();

  try {
    const res = await req; // XXX: `body` is `null` for 204 response.
    // eslint-disable-next-line no-console
    console.debug('### API', method.toUpperCase(), 'RESPONSE', res?.body);
    return  returnFullResponse ? res : res.body;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.debug('### API', method.toUpperCase(), 'ERROR', error);
    return errorMapper(error);
  }
};

/* █████████████████████ *\
 * ███ OAUTH-related ███ *
\* █████████████████████ */

const TRANSPORT_DELAY = 20; // max seconds client waits for server response.

let tokenTimeoutId;

const tokenize = data => async () => {
  try{
    const { body: {
      access_token: accessToken,
      refresh_token: refreshToken,
      token_type: tokenType,
      expires_in: expiresIn, // access_token life-span in seconds.
      confirmation_action_type: confirmationActionType,
      phone
    } } = await requestAgent.post(REACT_APP_OAUTH_PATH + '/token').type('form').auth('web', '').send(data);
    saveTokenInfo({ accessToken, refreshToken, tokenType, accessExpiresOn: Date.now() + expiresIn * 1000 });
    setTokenRefresh(expiresIn);
    if(confirmationActionType) {
      setConfirmationActionType(confirmationActionType);
    }
    if(phone) {
      setSecureUserPhone(phone);
    }
    return { confirmationActionType, phone };
  }
  catch (error) {
    return errorMapper(error);
  }
};

/**
 * @param {number} expiresIn - number of seconds after which access_token expires.
 */
export const setTokenRefresh = expiresIn => {
  clearTimeout(tokenTimeoutId);
  tokenTimeoutId = setTimeout(
    tokenize({
      grant_type: 'refresh_token',
      refresh_token: getRefreshToken()
    }),
    (expiresIn - TRANSPORT_DELAY) * 1000
  );
};

export const loginUser = ({ username, password }) => tokenize({
  username,
  password,
  grant_type: 'password'
})();

export const loginPin = (code) =>  tokenize({
  code,
  grant_type: 'confirmation_code',
  access_token: getAccessToken()
})();

export const invalidateToken = () =>
  api({
    path: '/revoke',
    pathHost: REACT_APP_OAUTH_PATH,
    pathPrefix: '',
    isRequestWithAuth: false,
    isRequestWithBasicAuth: true,
    query: { token: getAccessToken() }
  });

export const runLogout = async () => {
  await invalidateToken();
  resetLastUserActivityDateTime();
  clearTimeout(tokenTimeoutId);
  logoutActiveSessionWithoutToken();
};

/* ████████████████████████████ *\
 * ███ REGISTRATION-related ███ *
\* ████████████████████████████ */

let registrationResponseId = null;

const register = async(urlPathPostfix, data) => {
  try {
    const { body: {
      response_id: responseId,
      status
    } } = await requestAgent.
      post(REACT_APP_API_HOST_WITH_PORT + REACT_APP_API_URL_PATH_PREFIX + urlPathPostfix).
      send(data);

    registrationResponseId = responseId;
    return status;
  }
  catch (error) {
    return errorMapper(error);
  }

};

export const createUser = data => register('/registration/new/user', data);

export const setPhoneNumber = phone => register('/registration/new/user/phone/setup', {
  phone,
  response_id: registrationResponseId
});

export const confirmSmsCode = code => register('/registration/new/user/phone/confirm', {
  code,
  response_id: registrationResponseId
});

export const resendPhoneRegistration = () => register('/registration/new/user/phone/resend_code', {
  response_id: registrationResponseId
});


export const registerAccount = (data) => register('/registration/new/account',{
  ...data,
  response_id: registrationResponseId
});

export const confirmEmail = code => register('/registration/new/account/email/confirm', {
  code: code,
  response_id: registrationResponseId
});

export const resendEmailRegistration = () => register('/registration/new/account/email/resend', {
  response_id: registrationResponseId
});


export const createAccount = async (data) => {
  return api( {
    path: '/account/create',
    data
  });
};

export const confirmAccountEmail = (code, id) => {
  return api({
    path: '/account/create/email_confirmation/code',
    data : {
      response_id: id,
      code: code
    }
  });
};

export const resendCodeAccountEmail = (id) => {
  return api( {
    path: '/api/account/create/email_confirmation/resend_code',
    data: { response_id: id }
  });
};

/* ████████████████ *\
 * ███ USER-API ███ *
\* ████████████████ */

export const getUserInformation = async () => {
  return api({
    path: '/user/new',
    method: 'get'
  });
};

export const getUserInfoByAccNumber = async (accountNumber) => {
  return api({
    path: `/account/${accountNumber}`,
    method: 'get'
  });
};

/* ███████████████████ *\
 * ███ WALLETS-API ███ *
\* ███████████████████ */

export const getWallets = async accountNumber => {
  return api({
    path: `/account/${accountNumber}/wallet/new`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });
};

/* █████████████████████████ *\
 * ███ VERIFICATION CODE ███ *
\* █████████████████████████ */

export const generateOAuthSecurityCode = () =>
  api({
    path: '/confirmation/generate',
    method: 'get',
    pathHost: REACT_APP_OAUTH_PATH,
    pathPrefix: ''
  });

export const resendAuthorizationSecurityCode = () => {
  api( {
    path: '/confirmation/resend',
    method: 'get',
    pathHost: REACT_APP_OAUTH_PATH,
    pathPrefix: ''
  });
};

export const generateSecurityCode = () =>
  api({
    path: '/confirmation/generate',
    method: 'get'
  });

export const resendSecurityCode = () =>
  api({
    path: '/confirmation/resend',
    method: 'get'
  });

/* ███████████████████████████*\
 * ███ RESET USER PASSWORD ███ *
\* ███████████████████████████ */

export const passwordReset = data => api({
  path: '/user/change_password',
  data
});

/* █████████████████████████ *\
 * ███ PASSWORD RECOVERY ███ *
\* █████████████████████████ */

export const passwordRecoveryRequest = (data) =>
  api({
    path: '/password_recovery',
    isRequestWithAuth: false,
    data
  });

export const changePasswordUser = data =>
  api({
    path: '/password_recovery/set_password',
    isRequestWithAuth: false,
    data
  });

export const generatePasswordRecoverySecurityCode = (responseId) =>
  api({
    path: '/password_recovery/request_confirmation',
    isRequestWithAuth: false,
    data: { response_id: responseId }
  });


export const fetchRecoveryLink = (tokenId) =>
  api({
    path: `/password_recovery/email_confirmation/${tokenId}`,
    method: 'get',
    isRequestWithAuth: false
  });

/* ████████████████████████ *\
 * ███ TRANSACTIONS-API ███ *
\* ████████████████████████ */

export const getTransactionList = (accountNumber, filter = {}) =>
  api({
    path: `/account/${accountNumber}/transaction`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    query: filter
  });

const downloadFile = (response, filename) => {
  if (!filename && response.headers['content-disposition']) {
    const parts = response.headers['content-disposition'].split('filename=');
    if (parts.length === 2) {
      // eslint-disable-next-line no-param-reassign
      filename = parts[1].replace(/"/g, '');
    }
  }

  const url = URL.createObjectURL(response.body);
  const downloadLink = document.createElement('a');
  downloadLink.style.display = 'none';
  downloadLink.setAttribute('download', filename || 'downloadedFile');
  downloadLink.href = url;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const exportCurrentPDF = async (accountNumber, transactionNumber, type) => {
  const response = await api({
    path: `/account/${accountNumber}/transaction/${type.toLowerCase()}/${transactionNumber}/report`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    method: 'get',
    responseType: 'blob',
    returnFullResponse: true
  });

  downloadFile(response);
};

export const exportPDFList = async(accountNumber, query) => {
  const response = await api({
    path: `/account/${accountNumber}/wallet/review`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    method: 'get',
    query,
    responseType: 'blob',
    returnFullResponse: true
  });

  downloadFile(response);
};

export const exportTransactionListCSV = async (accountNumber, query) => {
  const response = await api({
    path: `/account/${accountNumber}/transaction/export/csv`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    method: 'get',
    query,
    responseType: 'blob',
    returnFullResponse: true
  });

  downloadFile(response);
};

/* ██████████████████████████ *\
 * ███ CONSTANTS_FROM-API ███ *
\* ██████████████████████████ */

export const getConstants = () => api({
  path: '/application/constants',
  method: 'get'
});

/* ███████████████████ *\
 * ███ PAYMENT-API ███ *
\* ███████████████████ */

export const transactionPaymentCheck = async(accountNumber, data, formType) => {
  const res = await api({
    path: `/account/${accountNumber}/transaction/${formType}/check`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data
  });

  return {
    ...res,
    comment: data.comment
  };
};

export const transactionPaymentConfirm = async(account_number, data, formType) => {
  const res = await api({
    path: `/account/${account_number}/transaction/${formType}/create`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data,
    securityCode: data.securityCode
  });

  return {
    ...res,
    comment: data.comment
  };
};

export const uploadDocuments = (accountNumber, file) => {
  return api({
    path: `/account/${accountNumber}/document`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data: file
  });
};

export const internalIbanCheck = (accountNumber, iban) => {
  return api( {
    path: `/account/${accountNumber}/wallet/registered?iban=${iban}`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    method: 'get'
  });
};

/* ██████████████████ *\
 * ███ SUMSUB-API ███ *
\* ██████████████████ */

export const getSumsubAccessToken = (accountNumber, levelName) => {
  return api({
    path: '/account/sumsub/token/generate',
    data : {
      levelName: levelName,
      accountNumber: accountNumber
    }
  });
};

/* ████████████████ *\
 * ███ FEES-API ███ *
\* ████████████████ */

export const getTariffs = async (accountNumber, walletNumber, transactional = '') => {
  return api({
    // eslint-disable-next-line max-len
    path: `${transactional ? `/account/${accountNumber}/tariffs?wallet_number=${walletNumber}&transactional=${transactional}` : `/account/${accountNumber}/tariffs?&transactional=${transactional}`}`,
    method: 'get'
  });
};

export const setUnderReviewStatus = (accountNumber) => {
  return api({ path: `/account/${accountNumber}/kyc/under_review` });
};

/* █████████████████████████████ *\
 * ███ CURRENCY-EXCHANGE-API ███ *
\* █████████████████████████████ */


export const getCurrencyExchangeRate = async({ from, to }) => {
  return api({
    path: `/currency_rate/${from}/${to}`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });
};

export const exchange = (accountNumber, data) => api({
  path: `/account/${accountNumber}/transaction/exchange/check`,
  data,
  pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
});

export const exchangeConfirm = (accountNumber, { securityCode, ...data }) => api({
  path: `/account/${accountNumber}/transaction/exchange/create`,
  data,
  pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
  securityCode: securityCode
});

/* ███████████████████████████ *\
 * ███ REPRESENTATIVES-API ███ *
\* ███████████████████████████ */

export const getCandidateInfo = (email) => api({
  path: '/account/info',
  data: { email }
});

export const addRepresentative = ({ accountNumber, email, permissions, securityCode }) => api({
  path: `/account/representative/${accountNumber}/add`,
  data: {
    representative_email: email,
    permissions
  },
  securityCode
});

export const removeRepresentative = ({ accountNumber, id, securityCode }) => api({
  path: `/account/representative/${accountNumber}/remove/${id}`,
  securityCode: securityCode
});

export const updateRepresentative = ({ accountNumber, id, permissions, securityCode }) => api({
  path: `/account/representative/${accountNumber}/update/${id}`,
  data: permissions,
  securityCode: securityCode
});

/* █████████████████████████████ *\
 * ███ ACTIVITY-LOG-API ███ *
\* █████████████████████████████ */

export const getLoginAttempts = async (filter) => api( {
  path: '/login_attempt',
  method: 'get',
  pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
  query: filter
});
